import style from './Example.module.css';

export function Example() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      {/* <img alt="example" className={style.example} src="examples/intro2.png" /> */}
      {/* <img alt="example" className={style.example} src="examples/dragging_copy_02.png" /> */}
      {/* <img alt="example" className={style.example} src="examples/growing_copy_2.png" /> */}
      {/* <img alt="example" className={style.example} src="examples/holding_copy_04.png" /> */}
      {/* <img alt="example" className={style.example} src="examples/scrolling_copy.png" /> */}
      {/* <img alt="example" className={style.example} src="examples/tapping_copy.png" /> */}
      {/* <img alt="example" className={style.example} src="examples/Manifesto_01.png" /> */}
      {/* <img alt="example" className={style.example} src="examples/manifesto-3d visual_01.png" /> */}

      {/* <img alt="example" className={style.example} src="examples/Screenshot 2023-12-01 at 10.59.49.jpg" /> */}

      {/* INTRO COPY */}
      {/* <img alt="example" className={style.example} src="examples/intro_copy_dragging.jpg" /> */}
      {/* <img alt="example" className={style.example} src="examples/intro_copy_scrolling.jpg" /> */}
      {/* <img alt="example" className={style.example} src="examples/intro_copy_growing.jpg" /> */}
      {/* <img alt="example" className={style.example} src="examples/intro_copy_tapping.jpg" /> */}
      {/* <img alt="example" className={style.example} src="examples/intro_copy_holding.jpg" /> */}
    </>
  );
}
