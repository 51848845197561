import * as THREE from 'three';

import { useEffect, useRef, useState } from 'react';

export function Test() {
  /*
   * properties
   */

  /*
   * hooks
   */

  useEffect(() => {}, []);

  /*
   * visuals
   */

  return <></>;
}
