import { useEffect, useState } from 'react';
import { useSection } from '../../stores/useSection';
import { RoundButton } from '../components/RoundButton';
import { ButtonClose } from '../components/buttons/ButtonClose';
import style from './Reset.module.css';
import { usePieces } from '../../stores/usePieces';
import { useTimer } from '../../stores/useTimer';

export function Reset({ isCentered, amount }) {
  /*
   * properies
   */

  const [shown, setShown] = useState(false);
  const resetPieces = usePieces((state) => state.reset);
  const resetTimer = useTimer((state) => state.reset);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('isCentered', isCentered);
  }, [isCentered]);

  const closeHandler = () => {
    setShown(!shown);
  };

  const yesHandler = () => {
    resetPieces();
    resetTimer();
    setShown(false);
  };

  const noHandler = () => {
    setShown(false);
  };

  /*
   * visuals
   */

  return (
    <>
      {amount === 5 && (
        <div className={`${style.reset} ${isCentered ? style.isCentered : ''}`}>
          <div className={style.buttonContainer}>
            <ButtonClose onClick={closeHandler} small={true} />
          </div>
          {/* {shown && ( */}
          <div className={`${style.banner} ${shown ? style.shown : ''}`}>
            Reset your progress?
            <button onClick={noHandler} className={style.button}>
              No
            </button>
            <button onClick={yesHandler} className={`${style.button} ${style.highlight}`}>
              Yes
            </button>
          </div>
          {/* )} */}
        </div>
      )}
    </>
  );
}
