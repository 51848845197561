import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useSize } from '../../stores/useSize';

export function Line({ props, position, hide = false, size = 1.5 }) {
  /*
   * properties
   */

  const mesh = useRef();
  const group = useRef();
  const isMobile = useSize((state) => state.isMobile);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    resizeHandler();
    show();
  }, [isMobile]);

  const resizeHandler = () => {
    if (isMobile) {
      // mobile
      mesh.current.scale.set(1.4, 1, 1);
    } else {
      // desktop
      mesh.current.scale.set(1, 1, 1);
    }
  };

  const show = () => {
    const delay = props.delay + 1 + Math.random() * 1;
    const duration = 2.2;

    gsap.killTweensOf(group.current.scale);
    gsap.set(group.current.scale, { x: 0 });
    gsap.to(group.current.scale, { delay: delay, duration, x: 1, ease: 'power1.inOut' });
  };

  useEffect(() => {
    if (hide) {
      gsap.killTweensOf(group.current.scale);
      gsap.to(group.current.scale, { duration: 0.4, x: 0, ease: 'power1.out' });
    }
  }, [hide]);

  /*
   * visuals
   */

  return (
    <>
      <group ref={group} scale={[0, 1, 1]} position={position}>
        <mesh ref={mesh}>
          <planeGeometry args={[size, 0.01]} />
          <meshBasicMaterial color="#FCFF52" />
        </mesh>
      </group>
    </>
  );
}
