import { useEffect, useMemo, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import gsap from 'gsap';
import Bender from './Bender';
import * as THREE from 'three';
import { useTexture } from '@react-three/drei';
import { useTap } from '../../../stores/useTap';

const x = -0.5;
const y = 0;

const heartShape = new THREE.Shape();

heartShape.moveTo(x + 0.5, y + 0);
heartShape.quadraticCurveTo(x + 0.2, y + 0, x + 0, y + 0.6);
heartShape.quadraticCurveTo(x + 0, y + 0.9, x + 0.5, y + 1.0);
heartShape.quadraticCurveTo(x + 1.0, y + 0.9, x + 1.0, y + 0.6);
heartShape.quadraticCurveTo(x + 0.8, y + 0, x + 0.5, y + 0);

export function Petal({ i }) {
  /*
   * properties.current
   */

  useTexture.preload('./textures/petal-min.png');
  const texture = useTexture('./textures/petal-min.png');
  texture.offset.set(0.5, 0);

  const wrapper = useRef();
  const petal = useRef();
  const container = useRef();
  const index = useRef();
  // const percentage = useRef(0);

  const [isShown, setShown] = useState(true);
  const [startPosition, setStartPosition] = useState();

  const { petalsTapped, petalsInHead, petalsToTap } = useTap();
  // const properties.current = useMemo(() => {
  //   return {
  //     blend: { pct: 0 },
  //     position: { x: 0, y: 0, z: 0.03 },
  //     scale: { x: 0, y: 0, z: 0 },
  //     rotation: { x: -1, y: 0, z: 0 },
  //   };
  // }, []);

  const properties = useRef();

  // const materialProps = useControls('petal material', {
  //   roughness: { value: 0.6, min: 0, max: 1, step: 0.01 },
  //   metalness: { value: 0.7, min: 0, max: 1, step: 0.01 },
  // });

  const materialProps = {
    roughness: 0.6,
    metalness: 0.7,
  };

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('JAJA');
    if (petal.current.geometry && !startPosition) {
      // console.log('set startPosition');
      resetProps();
      index.current = i;
      // console.log(index.current)

      // percentage.current = (index + 1 - petalsToTap) / petalsInHead;
      // console.log(percentage.current);
      setStartPosition(petal.current.geometry.getAttribute('position').clone().array);
      wrapper.current.rotation.y = index.current * 1.7;
    }
  }, []);

  const resetProps = () => {
    // console.log('resetProps');
    properties.current = {
      blend: { pct: 0 },
      position: { x: 0, y: 0, z: 0.03 },
      scale: { x: 0, y: 0, z: 0 },
      rotation: { x: -1, y: 0, z: 0 },
    };
  };

  useEffect(() => {
    // let pct = (index + 1 - petalsToTap + petalsTapped) / petalsInHead;
    // percentage.current += 1 / petalsInHead;
    // let pct = percentage.current;
    // console.log(old, pct);

    let pct = (index.current + 1 - petalsToTap + petalsTapped) / petalsInHead;
    // console.log('index:', index.current, 'pct', pct);

    // wrapper.current.visible = pct >= 0;

    // console.log(properties.current)

    // const properties.current = properties.current;

    if (pct > 1) {
      if (isShown) {
        setShown(false);

        gsap.killTweensOf(properties.current.scale);
        gsap.killTweensOf(properties.current.rotation);
        gsap.killTweensOf(properties.current.position);

        const scale = 0;
        gsap.to(properties.current.scale, {
          duration: 3,
          x: scale,
          y: scale,
          z: scale,
          ease: 'sine.inOut',
        });
        gsap.to(properties.current.rotation, { duration: 3, x: 4, y: 1, ease: 'power1.out' });
        gsap.to(properties.current.position, { duration: 3, onComplete: reset, y: -3, z: 3, ease: 'power1.out' });
        gsap.to(properties.current.blend, { duration: 3, pct: 0.5, ease: 'power1.out' });

        // reset();
      }
    } else if (pct >= 0) {
      gsap.killTweensOf(properties.current.scale);
      gsap.killTweensOf(properties.current.rotation);

      const scale = Math.pow(pct, 0.3) * 0.8;
      gsap.to(properties.current.scale, { duration: 1, x: scale, y: scale, z: scale, ease: 'power4.out' });
      gsap.to(properties.current.rotation, {
        duration: 1 + Math.random() * 1,
        x: -0.8 + Math.pow(pct, 0.6) * 2.6,
        ease: 'elastic.out',
      });
      gsap.to(properties.current.blend, { duration: 1, pct: pct, ease: 'elastic.out' });
    }
  }, [petalsTapped]);

  const reset = () => {
    // console.log('reset');

    setShown(true);

    gsap.killTweensOf(properties.current.scale);
    gsap.killTweensOf(properties.current.rotation);
    gsap.killTweensOf(properties.current.position);
    gsap.killTweensOf(properties.current.blend);

    // console.log('index PRE', index.current);
    index.current = index.current - petalsInHead - petalsToTap;
    // console.log('index POST', index.current);

    wrapper.current.rotation.y = index.current * 1.7;

    // console.log('percentage.current PRE',  percentage.current);
    // percentage.current = (index + 1 - petalsToTap - petalsTapped) / petalsInHead;
    // console.log('percentage.current POSTR', percentage.current);
    resetProps();
  };

  useFrame((state) => {
    // const properties.current = properties.current;

    container.current.scale.set(properties.current.scale.x, properties.current.scale.y, properties.current.scale.z);
    container.current.rotation.set(
      properties.current.rotation.x,
      properties.current.rotation.y,
      properties.current.rotation.z
    );
    container.current.position.set(
      properties.current.position.x,
      properties.current.position.y,
      properties.current.position.z
    );

    if (startPosition) {
      // console.log('startPosition');
      const geometry = petal.current.geometry;

      Bender.bend(geometry, startPosition, 'y', 4.2 - properties.current.blend.pct * 6);

      geometry.attributes.position.needsUpdate = true;
      geometry.computeVertexNormals();
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper}>
        <group ref={container}>
          <mesh ref={petal}>
            <shapeGeometry args={[heartShape, 10]} />

            {/* <planeGeometry args={[1, 1, 8, 12]} /> */}

            {/* <meshBasicMaterial color="red" wireframe /> */}

            {/* <meshStandardMaterial
              alphaMap={textureOpacity}
              transparent={true}
              side={THREE.DoubleSide}
              depthWrite={false}
              depthTest={true}
            /> */}

            <meshStandardMaterial
              side={THREE.DoubleSide}
              map={texture}
              color={materialProps.color}
              metalness={materialProps.metalness}
              roughness={materialProps.roughness}
            />
          </mesh>

          {/* <mesh>
            <sphereGeometry args={[0.05]} />
          </mesh> */}
        </group>
      </group>
    </>
  );
}
