import style from './Test.module.css';

import { useRive, useStateMachineInput } from 'rive-react';

export function Test() {
  const STATE_MACHINE_NAME = 'audio';
  const INPUT_NAME = 'on';

  const { rive, RiveComponent } = useRive({
    src: '/riv/manifesto.riv',
    artboard: STATE_MACHINE_NAME,
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const audioOn = useStateMachineInput(rive, STATE_MACHINE_NAME, INPUT_NAME);

  return (
    <>
      <div className={style.container}>
        <RiveComponent onClick={() => (audioOn.value = true)} />
      </div>
    </>
  );
}
