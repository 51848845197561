// import { Route, Routes } from 'react-router-dom';

import { Staging } from './core/Staging';
import { CameraManager } from './core/CameraManager';
import { PostProcessing } from './core/Postprocessing';
import { Sections } from './Sections';
import { useTexture } from '@react-three/drei';
import { useEffect } from 'react';
import { useSounds } from '../stores/useSounds';

// const ktx2Loader = new KTX2Loader().setTranscoderPath('loader/basis/');
// const dracoLoader = new DRACOLoader();
// dracoLoader.setDecoderPath('loader/draco/gltf/');

export function App3D() {
  // useEffect(() => {
  //   console.log('App 3D :: set section');
  //   console.log('section: ', section);
  // }, [section]);

  useTexture.preload('/textures/logo-min.png');
  useTexture.preload('./textures/petal-min.png');
  useTexture.preload('/textures/blurredPoint-min.png');
  useTexture.preload('/textures/spikesBump-min.png');

  useTexture.preload(`./textures/piece_dragging-min.png`);
  useTexture.preload(`./textures/piece_growing-min.png`);
  useTexture.preload(`./textures/piece_holding-min.png`);
  useTexture.preload(`./textures/piece_scrolling-min.png`);
  useTexture.preload(`./textures/piece_tapping-min.png`);

  const playSound = useSounds((state) => state.playSound);

  useEffect(() => {
    // console.log('App3D :: onMount');
    playSound('MainAmbienceLoop');
  }, []);

  return (
    <>
      {/* general content and set-up */}
      <CameraManager />
      <PostProcessing />
      <Staging />

      {/* <Routes>
        <Route path="/" element={<Sections />} />
      </Routes> */}

      <Sections />
    </>
  );
}
