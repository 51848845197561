import { Instances } from '@react-three/drei';
import { Block } from './Block';

export function Blocks() {
  // console.log('Blocks :: render');

  /*
   * properties
   */

  const total = 400;

  // const props = useControls('block material', {
  //   roughness: { value: 0.55, min: 0, max: 1, step: 0.01 },
  //   metalness: { value: 0.77, min: 0, max: 1, step: 0.01 },
  //   color: '#aaaaaa',
  // });

  const props = {
    roughness: 0.55,
    metalness: 0.77,
    color: '#aaaaaa',
  };

  /*
   * visuals
   */

  return (
    <>
      <group>
        <Instances limit={total}>
          <boxGeometry args={[1, 0.1, 0.2]} />
          <meshStandardMaterial color={props.color} metalness={props.metalness} roughness={props.roughness} />

          {Array.from({ length: total }, (_, i) => (
            <Block key={i} index={i} total={total} />
          ))}
        </Instances>
      </group>
    </>
  );
}
