import { Pieces } from './Pieces';

export function Part2() {
  /*
   * properties
   */

  // const container = useRef();

  // const part2Finished = useHold((state) => state.part2Finished);

  /*
   * hooks
   */

  // useEffect(() => {
  //   if (part2Finished) {
      
  //   }
  // }, [part2Finished]);

  /*
   * visuals
   */

  return (
    <>
      <Pieces />
    </>
  );
}
