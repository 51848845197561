import { useEffect, useRef, useState } from 'react';
import { SmallButton } from '../components/SmallButton';
import { Howler } from 'howler';
import style from './Footer.module.css';
import gsap from 'gsap';
import { RivAudioIcon } from '../components/RivAudioIcon';
import { useSize } from '../../stores/useSize';
import { useManifesto } from '../../stores/useManifesto';
import { Timer } from './Timer';

export function Footer() {
  /*
   * properties
   */

  const footer = useRef();
  let [soundOn, setSoundOn] = useState(true);
  let switchedOff = false;

  let volume = useRef(0);

  const isMobile = useSize((state) => state.isMobile);
  const mobileMenuShown = useManifesto((state) => state.mobileMenuShown);

  /*
   * hooks
   */

  useEffect(() => {
    gsap.killTweensOf(footer.current);
    gsap.set(footer.current, { opacity: 0 });
    gsap.to(footer.current, { delay: 5, duration: 0.5, opacity: 1, ease: 'power2.in' });
    // }, [footer.current]);

    Howler.volume(0);

    const intervalId = setInterval(() => {
      if (Howler._audioUnlocked) {
        if (!switchedOff) {
          gsap.to(volume, { current: 1, duration: 3, onUpdate: setVolume, ease: 'sine.out' });
        }

        clearInterval(intervalId);
      }
    }, 250);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const soundHandler = () => {
    // console.log('soundHandler');

    gsap.killTweensOf(volume);

    //not async
    switchedOff = soundOn;

    if (soundOn) {
      gsap.to(volume, { current: 0, duration: 0.5, onUpdate: setVolume });
    } else {
      gsap.to(volume, { current: 1, duration: 0.5, onUpdate: setVolume });
    }

    //async!
    setSoundOn(!soundOn);
  };

  const setVolume = () => {
    Howler.volume(volume.current);
  };

  const whoHandler = () => {
    window.open('https://www.itsoffbrand.com/', '_blank');
  };

  /*
   * visuals
   */

  return (
    <>
      {(!isMobile || (isMobile && !mobileMenuShown)) && (
        <footer ref={footer} className={style.footer}>
          <SmallButton onClick={soundHandler} ariaLabel={soundOn ? 'switch sound off' : 'switch sound on'}>
            sound
          </SmallButton>
          <div className={style.audio} onClick={soundHandler}>
            <RivAudioIcon on={soundOn} />
          </div>
          <SmallButton onClick={whoHandler}>who we are</SmallButton>
          <Timer />
        </footer>
      )}
    </>
  );
}
