import { useEffect, useRef } from 'react';
import { useCameras } from '../../../stores/useCameras';
import gsap from 'gsap';
import { useScrolling } from '../../../stores/useScrolling';

export function CameraController() {
  /*
   * properties
   */
  const camera = useCameras((state) => state.camera);
  const scrollPct = useScrolling((state) => state.scrollPct);
  const timeline = useRef();

  /*
   * hooks
   */
  useEffect(() => {
    const tl = gsap.timeline({ paused: true });

    if (camera) {
      gsap.set(camera.position, { duration: 0, x: 0, y: 0, z: -20 }, 0);

      tl.to(camera.position, { duration: 1, z: 0, ease: 'sine.out' }, 0);
      timeline.current = tl;
    }

    return () => {
      tl.kill();
    };
  }, [camera]);

  useEffect(() => {
    if (camera) {
      // console.log(Math.pow(scrollPct, .5), scrollPct)
      timeline.current.progress(Math.pow(scrollPct, 1.75));
    }
  }, [scrollPct]);
}
