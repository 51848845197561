import { Howl, Howler } from 'howler';
import { create } from 'zustand';
import { gsap } from 'gsap';

const sounds = {
  // test: new Howl({ src: ['/sounds/0A_handle_squeak.mp3'] }),

  ClickToContinue: new Howl({ src: ['/sounds/Click_To_Continue.mp3'] }),
  GeneralUIClick: new Howl({ src: ['/sounds/General_UI_Click.mp3'] }),
  HoldingImpact: new Howl({ src: ['/sounds/Holding_Impact.mp3'] }),
  HoldingInteraction: new Howl({ src: ['/sounds/Holding_Interaction.mp3'] }),
  InteractionComplete: new Howl({ src: ['/sounds/Interaction_Complete.mp3'] }),
  // IntroReveals: new Howl({ src: ['/sounds/Intro_Reveals.mp3'] }),
  IntroReveals: new Howl({ src: ['/sounds/Intro_Reveals_v02.mp3'] }),
  ScrollReveal: new Howl({ src: ['/sounds/Scroll_Reveal.mp3'] }),
  Tapping01: new Howl({ src: ['/sounds/Tapping_01.mp3'] }),
  Tapping02: new Howl({ src: ['/sounds/Tapping_02.mp3'] }),
  Tapping03: new Howl({ src: ['/sounds/Tapping_03.mp3'] }),
  Tapping04: new Howl({ src: ['/sounds/Tapping_04.mp3'] }),

  DraggingLoop: new Howl({ loop: true, src: ['/sounds/Dragging_Loop.mp3'] }),
  FinalScreenLoop: new Howl({ loop: true, src: ['/sounds/Final_Screen_Loop.mp3'] }),
  GrowingLoop: new Howl({ loop: true, src: ['/sounds/Growing_Loop.mp3'] }),
  InteractHoverLoop: new Howl({ loop: true, src: ['/sounds/Interact_Hover_Loop.mp3'] }),
  MainAmbienceLoop: new Howl({ loop: true, src: ['/sounds/Main_Ambience_Loop.mp3'] }),
  ScrollingLoop: new Howl({ loop: true, src: ['/sounds/Scrolling_Loop.mp3'] }),
};

export const useSounds = create((set, get) => ({
  // muted: false,
  // active: true,

  playSound: (name) => {
    const sound = sounds[name];
    sound.play();

    return sound;
  },

  getSound: (name) => {
    return sounds[name];
  },

  // mute: (muted) => {
  //   set({ muted });
  //   get().setVolume();
  // },

  // activate: (active) => {
  //   set({ active });
  //   get().setVolume();
  // },

  // setVolume: () => {

  //   if (!get().muted && get().active) {
  //     Howler.volume(1);
  //   } else {
  //     Howler.volume(0);
  //   }
  // },
}));
