import { BlendFunction, Effect } from 'postprocessing';
import { Uniform } from 'three';
import fragmentShader from './pinch.glsl?raw';


export default class PinchEffect extends Effect {
  constructor({ strength, blendFunction = BlendFunction.NORMAL }) {
    super('PinchEffect', fragmentShader, {
      blendFunction,
      uniforms: new Map([
        ['uStrength', new Uniform(strength)],
        ['uTime', new Uniform(0)],
      ]),
    });
  }

  update(renderer, inputBuffer, deltaTime) {
    // this.uniforms.get('uTime').value += deltaTime;
  }
}
