import { useEffect, useRef } from 'react';
import style from './IntroTextContent.module.css';
import gsap from 'gsap';
import { useSize } from '../../stores/useSize';

export function IntroTextContent({ section, shown }) {
  /*
   * properties
   */

  const container = useRef();
  const isMobile = useSize((state) => state.isMobile);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('IntroTextContent :: change');
    // console.log(section, shown);

    if (shown) {
      show();
    } else {
      hide();
    }
  }, [shown, section]);

  const show = () => {
    const items = Array.from(container.current.querySelectorAll('span'));

    gsap.killTweensOf(container.current);
    gsap.set(container.current, { opacity: 1 });

    for (const item of items) {
      const delay = 0.5 + Math.random() * 0.4;
      // const delay = Math.random() * 0.4;
      const duration = 0.75;

      gsap.killTweensOf(item);
      gsap.to(item, { delay, duration, opacity: 1, ease: 'power1.out' });
      gsap.set(item, { y: 0 });
      gsap.from(item, { delay, duration, y: 14, ease: 'power1.out' });
    }
  };

  const hide = () => {
    gsap.killTweensOf(container.current);
    gsap.to(container.current, { duration: 0.4, opacity: 0, ease: 'sine.out' });
  };

  /*
   * visuals
   */

  return (
    <section ref={container} className={`${style.section} ${style[section]}`}>
      {section === 'dragging' && (
        <>
          <p>
            <span>drag steadily</span>
          </p>
          <p>
            <span>in an</span>
            <span className={style.right}>easterly</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>direction</span>
          </p>
          <p>
            <span>spiral into</span>
          </p>
          <p>
            <span>another</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>dimension</span>
          </p>
        </>
      )}
      {section === 'scrolling' && (
        <>
          <p>
            <span>cast your fingers</span>
          </p>
          <p className={style.gapFront}>
            <span>as if</span>
            <span className={style.right}>to</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>scroll</span>
          </p>
          <p>
            <span>to take a</span>
          </p>
          <p className={style.gapFront}>
            <span>journey into</span>
          </p>
          <p className={style.gapFront}>
            <span>our</span>
            <span>soul</span>
          </p>
        </>
      )}
      {section === 'growing' && isMobile && (
        <>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>imagine a map</span>
          </p>
          <p>
            <span>a</span>
            <span className={style.right}>trusty guide</span>
          </p>
          <p>
            <span>if fingers zoom in</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>it may proof</span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>divine</span>
          </p>
        </>
      )}
      {section === 'growing' && !isMobile && (
        <>
          <p className={style.gapFront}>
            <span>under the nose</span>
          </p>
          <p>
            <span>a row</span>
            <span className={style.right}>from 1 to 9,</span>
          </p>
          <p>
            <span>draw fingertip</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>along</span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>to multiply</span>
          </p>
        </>
      )}
      {section === 'tapping' && (
        <>
          <p>
            <span>tap tap tap</span>
          </p>
          <p>
            <span>as quick</span>
            <span className={style.right}>as you</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>can</span>
          </p>
          <p>
            <span>petals</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>may reveal</span>
          </p>
          <p>
            <span>a cryptic</span>
            <span className={style.right}>psalm</span>
          </p>
        </>
      )}
      {section === 'holding' && (
        <>
          <p>
            <span>if&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the finger</span>
          </p>
          <p className={style.gapFront}>
            <span>falls</span>
            <span className={style.right}>into place</span>
          </p>
          <p>
            <span>keep held</span>
            <span className={style.right}>down</span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <p className={style.gapFront}>
            <span>for</span>
            <span>an explosive</span>
          </p>
          <p>
            <span>&nbsp;</span>
            <span className={style.right}>release</span>
          </p>
        </>
      )}
    </section>
  );
}
