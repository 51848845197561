import { Instances } from '@react-three/drei';
import { Piece } from './Piece';
import gsap from 'gsap';
import { useEffect, useRef } from 'react';
import { useHold } from '../../../../stores/useHold';
import { useFrame } from '@react-three/fiber';

export function Pieces() {
  /*
   * properties
   */

  const part2Finished = useHold((state) => state.part2Finished);

  const group = useRef();
  const total = 200;

  const props = { speed: 0.2 };

  // const materialProps = useControls('piece material', {
  //   roughness: { value: 0.0, min: 0, max: 1, step: 0.01 },
  //   metalness: { value: 0.5, min: 0, max: 1, step: 0.01 },
  //   color: '#909090',
  // });

  const materialProps = {
    roughness: 0.0,
    metalness: 0.5,
    color: '#909090',
  };

  /*
   * hooks
   */

  useEffect(() => {
    if (part2Finished) {
      props.speed = 5;
      gsap.to(props, { duration: 4.2, speed: 0.05, ease: 'sine.out' });
    }
  }, [part2Finished]);

  useFrame((state, delta) => {
    group.current.rotation.x += delta * props.speed * .1;
    group.current.rotation.y += delta * props.speed;
  }, []);

  /*
   * visuals
   */

  return (
    <>
      <group ref={group}>
        <Instances limit={total}>
          <icosahedronGeometry args={[0.3, 0]} />
          <meshStandardMaterial
            color={materialProps.color}
            metalness={materialProps.metalness}
            roughness={materialProps.roughness}
          />

          {Array.from({ length: total }, (_, i) => (
            <Piece key={i} index={i} />
          ))}
        </Instances>
      </group>
    </>
  );
}
