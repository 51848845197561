import { create } from 'zustand';

export const useManifesto = create((set, get) => ({
  section: 'intro',
  num: 0,
  mobileMenuShown: false,

  setSection: (section, num) => set({ section, num }),
  reset: () => set({ section: 'intro' }),

  showMobileMenu: () => set({ mobileMenuShown: true }),
  hideMobileMenu: () => set({ mobileMenuShown: false }),
}));
