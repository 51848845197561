import { Instance } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { useHold } from '../../../../stores/useHold';

export function Piece({ index }) {
  // console.log('Block :: render');

  /*
   * properties
   */

  let keep = false;
  const instance = useRef();
  let [shown, setShown] = useState(true);
  const part2Finished = useHold((state) => state.part2Finished);

  /*
   * hooks
   */

  useEffect(() => {
    if (index <= 20) keep = true;
  });

  useEffect(() => {
    if (part2Finished) {
      // SET START
      let scale = 0.5 + Math.random() * 1;
      let dist = 0.1 + Math.random() * 0.2;

      gsap.set(instance.current.scale, {
        x: scale,
        y: scale,
        z: scale,
      });

      gsap.set(instance.current.position, {
        x: (-0.5 + Math.random()) * dist,
        y: (-0.5 + Math.random()) * dist,
        z: (-0.5 + Math.random()) * dist,
      });

      gsap.set(instance.current.rotation, {
        x: Math.random() * 6,
        y: Math.random() * 6,
        z: Math.random() * 6,
      });

      //ANIMATE

      const duration = 0.5 + Math.random() * 0.75;
      dist = 4 + Math.random() * 10;
      scale = keep ? 0.2 + Math.random() * 0.3 : 0;

      gsap.to(instance.current.position, {
        duration,
        x: (-0.5 + Math.random()) * dist,
        y: (-0.5 + Math.random()) * dist,
        z: (-0.5 + Math.random()) * dist,
        ease: 'sine.out',
      });
      gsap.to(instance.current.scale, {
        duration,
        x: scale,
        y: scale,
        z: scale,
        ease: 'sine.inOut',
        onComplete: endTweens,
      });
    }
  }, [part2Finished]);

  const endTweens = () => {
    if (!keep) {
      setShown(false);
    }
  };

  useFrame((state) => {
    if (instance && instance.current) {
      const time = state.clock.elapsedTime;
      instance.current.rotation.x = time * 1;
      instance.current.rotation.y = time * -0.5;
    }
  }, []);

  return <>{shown && <Instance ref={instance} />}</>;
}
