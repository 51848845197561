import { create } from 'zustand';

export const useDrag = create((set) => ({
  dragPct: 0,
  slowDragPct: 0,
  started: false,
  finished: false,

  reset: () => set({ dragPct: 0, slowDragPct: 0, started: false, finished: false }),

  start: () => set({ started: true }),
  finish: () => set({ finished: true }),

  setDrag: (dragPct) => set({ dragPct }),
  setSlowDrag: (slowDragPct) => set({ slowDragPct }),
}));
