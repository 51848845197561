import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';

import { useTexture } from '@react-three/drei';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { useHold } from '../../../../stores/useHold';

export function Inner() {
  /*
   * properties
   */

  const mesh = useRef();
  const material = useRef();

  const texture = useTexture('/textures/spikesBump-min.png');
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(3, 3);

  // const materialProps = useControls('inner material', {
  //   roughness: { value: 0, min: 0, max: 1, step: 0.01 },
  //   metalness: { value: 0.5, min: 0, max: 1, step: 0.01 },
  //   scale: { value: 0.2, min: 0, max: 4, step: 0.01 },
  //   bias: { value: 0, min: -1, max: 1, step: 0.01 },
  //   rotationSpeed: { value: 0.85, min: 0, max: 1, step: 0.01 },
  //   color: '#dddddd',
  // });

  const materialProps = {
    roughness: 0,
    metalness: 0.5,
    scale: 0.2,
    bias: 0,
    rotationSpeed: 0.85,
    color: '#bbbbbb',
  };

  /*
   * hooks
   */

  useFrame((state, delta) => {
    const time = state.clock.elapsedTime;

    // texture.offset.set(time * 0.01, time * 0.02);

    texture.offset.set(time * 0.2 * materialProps.rotationSpeed, time * 0.1 * materialProps.rotationSpeed);

    mesh.current.rotation.y = time * -0.2;
    mesh.current.rotation.x = time * -0.062;

    const pct = useHold.getState().holdPct;

    material.current.displacementScale = 0.2 + pct * 2.2;
    material.current.displacementBias = 0 - pct * 0.4;
    material.current.metalness = 0.9 - pct * 0.1;
  });

  /*
   * visuals
   */

  return (
    <>
      <mesh ref={mesh}>
        <icosahedronGeometry args={[2, 8]} />
        <meshStandardMaterial
          ref={material}
          displacementMap={texture}
          displacementScale={materialProps.scale}
          displacementBias={materialProps.bias}
          // flatShading
          color={materialProps.color}
          metalness={materialProps.metalness}
          roughness={materialProps.roughness}
        />
      </mesh>
    </>
  );
}
