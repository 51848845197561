import { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useHold } from '../../../stores/useHold';
import { useSection } from '../../../stores/useSection';
import { usePost } from '../../../stores/usePost';
import { useSounds } from '../../../stores/useSounds';

let isHolding = false;
let autoScrollSpeed = 0;

export function HoldController() {
  /*
   * properties
   */

  const start = useHold((state) => state.start);
  const reset = useHold((state) => state.reset);
  const finishPart1 = useHold((state) => state.finishPart1);
  const setPct = useHold((state) => state.setPct);
  const concealSection = useSection((state) => state.concealSection);
  const setPost = usePost((state) => state.setPost);
  const finishPost = usePost((state) => state.setPost);

  const finished = useRef(false);

  const getSound = useSounds((state) => state.getSound);
  const playSound = useSounds((state) => state.playSound);
  const sound = getSound('HoldingInteraction');

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('DragController :: onMount');

    isHolding = false;

    const timeoutId = setTimeout(start, 2500);

    window.addEventListener('mousedown', startHold);
    window.addEventListener('mouseup', endHold);

    window.addEventListener('touchstart', startHold);
    window.addEventListener('touchend', endHold);

    return () => {
      // console.log('DragController :: onDestroy');

      window.removeEventListener('mousedown', startHold);
      window.removeEventListener('mouseup', endHold);

      window.removeEventListener('touchstart', startHold);
      window.removeEventListener('touchend', endHold);

      reset();
      clearTimeout(timeoutId);
    };
  }, []);

  const startHold = () => {
    // console.log('startHold');
    // console.log('useHold.getState().started')
    // console.log(useHold.getState().started)
    if (useHold.getState().started && !finished.current) {
      // console.log('YO')
      isHolding = true;
      sound.stop();
      sound.play();
      sound.fade(sound.volume(), 1, 500);
    }
  };

  const endHold = () => {
    // console.log('endHold');
    if (isHolding) {
      // console.log('really endHold');
      sound.fade(sound.volume(), 0, 300);
    }
    isHolding = false;
    // sound.stop();
  };

  useFrame((state, delta) => {
    let pct = useHold.getState().holdPct;
    const extra = delta * 0.2;

    // console.log(isHolding)

    if (pct < 1) {
      if (isHolding) {
        // console.log('ja');
        pct = Math.min(1, pct + extra);
        setPct(pct);
        setPost(pct);

        autoScrollSpeed = 0.1;

        if (pct === 1) {
          // console.log('FINISH');

          finished.current = true;

          finishPart1();
          concealSection();
          finishPost();
          // sound.stop();
          // sound.fade(1, 0, 300);
          endHold();
          playSound('HoldingImpact');
        }
      } else {
        autoScrollSpeed *= 1.2;
        autoScrollSpeed = Math.min(autoScrollSpeed, 100);

        // console.log('nee');
        // pct = Math.max(0, pct - extra * 1.2);
        pct = Math.max(0, pct - extra * 0.01 * autoScrollSpeed);
        setPct(pct);
        setPost(pct);
      }

      //   console.log(pct);
    }
  });
}
