import style from './MobileButtons.module.css';
import { useSize } from '../../../stores/useSize';
import { useEffect, useState } from 'react';
import { useSection } from '../../../stores/useSection';
import { ButtonSvg } from '../../components/buttons/ButtonSvg';
import { useManifesto } from '../../../stores/useManifesto';

export function MobileButtons() {
  /*
   * properies
   */

  const [isActive, setIsActive] = useState(true);

  const isMobile = useSize((state) => state.isMobile);
  const transition = useSection((state) => state.transition);

  const mobileMenuShown = useManifesto((state) => state.mobileMenuShown);
  const showMobileMenu = useManifesto((state) => state.showMobileMenu);
  const hideMobileMenu = useManifesto((state) => state.hideMobileMenu);

  /*
   * hooks
   */

  useEffect(() => {
    // title.current.style.visibility = 'hidden';
    if (transition === 'hide') {
      setIsActive(false);
    }
  }, [transition]);

  /*
   * visuals
   */

  return (
    <>
      {isMobile && (
        <div className={`${style.container} ${isActive ? style.isActive : ''}`}>
          {mobileMenuShown && <ButtonSvg svg="arrow" onClick={hideMobileMenu} ariaLabel="hide menu" />}
          {!mobileMenuShown && <ButtonSvg svg="pieces" onClick={showMobileMenu} ariaLabel="show menu" />}
        </div>
      )}
    </>
  );
}
