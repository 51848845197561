import { create } from 'zustand';

export const useHold = create((set) => ({
  holdPct: 0,
  slowHoldPct: 0,
  started: false,
  part1Finished: false,
  part2Finished: false,

  reset: () => set({ holdPct: 0, slowHoldPct: 0, started: false, part1Finished: false, part2Finished: false }),

  finishPart1: () => set({ part1Finished: true }),
  finishPart2: () => set({ part2Finished: true }),

  start: () => set({ started: true }),

  setPct: (holdPct) => set({ holdPct }),
  setSlowPct: (slowHoldPct) => set({ slowHoldPct }),
}));
