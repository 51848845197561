import { useEffect, useRef, useState } from 'react';
import style from './Menu.module.css';
import gsap from 'gsap';
import { usePieces } from '../../../stores/usePieces';
import { RoundButton } from '../../components/RoundButton';
import { useManifesto } from '../../../stores/useManifesto';
import { useSection } from '../../../stores/useSection';
import { TransparentButtonIcon } from '../../components/buttons/TransparentButtonIcon';
import { useSize } from '../../../stores/useSize';
import { useSounds } from '../../../stores/useSounds';

export function Menu({ onClick }) {
  /*
   * properies
   */

  const container = useRef();
  const pieces = usePieces((state) => state.pieces);
  const setSection = useManifesto((state) => state.setSection);
  const transition = useSection((state) => state.transition);

  const [show, setShow] = useState(false);
  const isMobile = useSize((state) => state.isMobile);
  const mobileMenuShown = useManifesto((state) => state.mobileMenuShown);
  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  useEffect(() => {
    if (transition === 'show') {
      // console.log('**show**');
      // gsap.killTweensOf(container.current);
      // gsap.to(container.current, { opacity: 1, duration: 0.2, ease: 'power1.in' });

      setShow(true);
    } else if (transition === 'hide') {
      // console.log('**hide**');
      // gsap.killTweensOf(container.current);
      // gsap.to(container.current, { opacity: 0, duration: 0.2, ease: 'power1.in' });

      setShow(false);
    }
  }, [transition]);

  const clickHandler = (num, piece) => {
    console.log(num, piece.section);
    setSection(piece.section, num);
    playSound('GeneralUIClick');
  };

  /*
   * visuals
   */

  return (
    <>
      {(!isMobile || (isMobile && mobileMenuShown)) && (
        <div ref={container} className={`${style.container} ${show ? style.isShown : ''}`}>
          {pieces.map((piece, i) => (
            // <RoundButton
            //   ariaLabel={'clue ' + (i + 1)}
            //   key={piece.section}
            //   strikethrough={!piece.found}
            //   svg={i + 1}
            //   onClick={() => clickHandler(i + 1, piece)}
            //   background={false}
            // />
            <TransparentButtonIcon
              ariaLabel={'clue ' + (i + 1)}
              key={piece.section}
              strikethrough={!piece.found}
              enabled={!piece.found}
              text={i}
              onClick={() => clickHandler(i + 1, piece)}
            />
          ))}
        </div>
      )}
    </>
  );
}
