import { useEffect, useRef, useState } from 'react';
import style from './CluesMenu.module.css';
import gsap from 'gsap';
import { usePieces } from '../../../stores/usePieces';
import { RoundButton } from '../../components/RoundButton';
import { IntroTextContent } from '../../../app3D/components/IntroTextContent';

import { RivCluesBackground } from './RivCluesBackground';
import { ButtonIcon } from '../../components/buttons/ButtonIcon';
import { DelayedContainer } from '../../components/buttons/DelayedContainer';

export function CluesMenu({ show, onClick }) {
  /*
   * properies
   */

  const [active, setActive] = useState(false);
  const container = useRef();
  const image = useRef();
  const title = useRef();

  const [index, setIndex] = useState(0);
  const [section, setSection] = useState('');

  const pieces = usePieces((state) => state.pieces);

  /*
   * hooks
   */

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setActive(true);
      }, 100);
    } else if (container.current) {
      container.current.style.pointerEvents = 'none';
      gsap.killTweensOf(container.current);
      gsap.to(container.current, { opacity: 0, onComplete: hideHandler, duration: 0.2, ease: 'power1.in' });
    }
  }, [show]);

  const hideHandler = () => {
    setActive(false);
  };

  useEffect(() => {
    window.addEventListener('click', windowHandler);
    return () => {
      window.removeEventListener('click', windowHandler);
    };
  }, []);

  const windowHandler = (e) => {
    // console.log('windowHandler');
    if (container.current && !container.current.contains(e.target)) {
      onClick();
    }
  };

  const clueHandler = (index, piece) => {
    if (section != piece.section) {
      // setIndex(index);
      setSection(piece.section);

      gsap.killTweensOf(image.current);
      gsap.to(image.current, {
        duration: 0.23,
        onComplete: showImage,
        onCompleteParams: [index],
        opacity: 0,
        ease: 'power1.in',
      });

      gsap.killTweensOf(title.current);
      gsap.set(title.current, { opacity: 0, y: 15 });
      gsap.to(title.current, { delay: 0.3, duration: 1, opacity: 1, y: 0, ease: 'power1.out' });
    }
  };

  const showImage = (index) => {
    setIndex(index);
    gsap.to(image.current, { duration: 0.3, opacity: 1, y: 0, ease: 'power1.out' });
  };

  /*
   * visuals
   */

  return (
    <>
      {active && (
        <div ref={container} className={style.container}>
          <div className={style.sections}>
            {pieces.map((piece, i) => (
              <DelayedContainer key={piece.section} delay={i * 300}>
                <ButtonIcon
                  ariaLabel={'clue ' + (i + 1)}
                  strikethrough={piece.found}
                  text={i}
                  onHover={() => clueHandler(i + 1, piece)}
                />
              </DelayedContainer>
            ))}
          </div>

          <div className={style.side}>
            <div ref={image} className={style.image}>
              {index === 1 && <RivCluesBackground name={'clue_holding'} />}
              {index === 2 && <RivCluesBackground name={'clue_scroll'} />}
              {index === 3 && <RivCluesBackground name={'clue_dragging'} />}
              {index === 4 && <RivCluesBackground name={'clue_tapping'} />}
              {index === 5 && <RivCluesBackground name={'clue_growing'} />}
            </div>

            <div className={style.content}>
              <p ref={title} className={style.title}>
                CLUE EXTRACT {index}
              </p>
              <IntroTextContent shown={true} section={section} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
