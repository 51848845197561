import { useSection } from '../stores/useSection';

import { Home } from './sections/home/Home';
import { Dragging } from './sections/dragging/Dragging';
import { Test } from './sections/test/Test';
import { UI } from './sections/ui/UI';
import { Scrolling } from './sections/scrolling/Scrolling';
import { Tapping } from './sections/tapping/Tapping';
import { Holding } from './sections/holding/Holding';
import { Growing } from './sections/growing/Growing';
import { Intro } from './sections/intro/Intro';
import { Manifesto } from './sections/manifesto/Manifesto';

export function Sections() {
  /*
   * properties
   */

  const section = useSection((state) => state.section);

  /*
   * visuals
   */

  return (
    <>
      {/* SECTIONS */}
      {section === 'intro' && <Intro />}
      {section === 'home' && <Home />}
      {section === 'dragging' && <Dragging />}
      {section === 'scrolling' && <Scrolling />}
      {section === 'tapping' && <Tapping />}
      {section === 'holding' && <Holding />}
      {section === 'growing' && <Growing />}
      {section === 'manifesto' && <Manifesto />}
      {section === 'test' && <Test />}
      {section === 'ui' && <UI />}
    </>
  );
}
