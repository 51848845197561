import { SpotLight, useHelper } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useTap } from '../../../stores/useTap';

export function Lighting() {
  /*
   * properties
   */

  const spotlight = useRef();
  const petalsTapped = useTap((state) => state.petalsTapped);

  useHelper(spotlight);

  // const ambientLightProps = useControls('ambient light', {
  //   intensity: { value: 0.11, min: 0, max: 1, step: 0.001 },
  // });

  // const spotLightProps = useControls('spotlight', {
  //   intensity: { value: 1.2, min: 0, max: 2, step: 0.001 },
  //   position: { value: { x: 1, y: 3.5, z: 1 }, min: -10, max: 10, step: 0.01 },
  //   angle: { value: 0.5, min: 0, max: 3, step: 0.01 },
  //   penumbra: { value: 1.7, min: 0, max: 3, step: 0.01 },
  // });

  const ambientLightProps = {
    intensity: 0.11,
  };

  const spotLightProps = {
    intensity: 1.2,
    position: { x: 1, y: 3.5, z: 1 },
    angle: 0.5,
    penumbra: 1.7,
  };

  useEffect(() => {
    // console.log(petalsTapped);

    if (petalsTapped === 1) {
      // console.log('JAJA');
      // console.log(spotlight.current.angle);
      // spotlight.current.angle = .5;
      // console.log(spotlight.current.angle);
      gsap.to(spotlight.current, { duration: 1.5, angle: 0.49, ease: 'power4.out' });
    }
  }, [petalsTapped]);

  /*
   * visuals
   */

  return (
    <>
      {/* <hemisphereLight position={[1, 0, 0]} args={['#ff2164', '#4affbb']} /> */}
      {/* <hemisphereLight position={[1, 2, 0]} args={['#000000', '#aaaaaa']} /> */}

      <ambientLight intensity={ambientLightProps.intensity} />

      {/* <directionalLight
        intensity={dirLightProps.intensity}
        position={[dirLightProps.position.x, dirLightProps.position.y, dirLightProps.position.z]}
      /> */}

      <spotLight
        ref={spotlight}
        intensity={spotLightProps.intensity}
        // angle={spotLightProps.angle}
        angle={0}
        penumbra={spotLightProps.penumbra}
        position={[spotLightProps.position.x, spotLightProps.position.y, spotLightProps.position.z]}
      />
    </>
  );
}
