import { useEffect } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';

export function RivButtonBackground({ over, strikethrough = false, inverted = false, clue = false, close = false }) {
  const STATE_MACHINE_NAME = clue || close ? 'btn_generic' : 'clues_btn';

  const { rive, RiveComponent } = useRive({
    src: '/riv/manifesto.riv',
    artboard: STATE_MACHINE_NAME,
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const animIn = useStateMachineInput(rive, STATE_MACHINE_NAME, 'in');
  const animHover = useStateMachineInput(rive, STATE_MACHINE_NAME, 'hover');
  const animStroke = useStateMachineInput(rive, STATE_MACHINE_NAME, 'out');

  const animClues = useStateMachineInput(rive, STATE_MACHINE_NAME, 'icon_clues');
  const animClose = useStateMachineInput(rive, STATE_MACHINE_NAME, 'icon_close');

  if (animIn) {
    animIn.value = true;
  }

  if (animHover && inverted) {
    animHover.value = true;
  }

  if (animStroke && strikethrough) {
    animStroke.value = true;
  }

  if (animClues && clue) {
    animClues.value = true;
  }

  if (animClose && close) {
    animClose.value = true;
  }

  useEffect(() => {
    if (animHover) {
      if (over) {
        animHover.value = !inverted;
      } else {
        animHover.value = inverted;
      }
    }
  }, [animHover, over]);

  return (
    <>
      <RiveComponent />
    </>
  );
}
