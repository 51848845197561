import { useEffect } from 'react';
import { useSection } from '../stores/useSection';

export function SectionController() {
  /*
   * properties
   */

  const loadSection = useSection((state) => state.loadSection);
  const endTransition = useSection((state) => state.endTransition);
  const transition = useSection((state) => state.transition);
  //   const section = useSection((state) => state.section);

  /*
   * hooks
   */

  //   useEffect(() => {
  //     console.log('SectionController :: onMount');
  //   }, []);

  useEffect(() => {
    // console.log('SectionController :: change transition');
    // console.log('transition: ' + transition);
    if (transition === 'hide') {
      // setTimeout(() => {
      //   loadSection();
      // }, 1000);
    } else if (transition === 'show') {
      // setTimeout(() => {
      //   endTransition();
      // }, 2000);
    }
  }, [transition]);
}
