import { useEffect, useRef } from 'react';
import style from './Logo.module.css';
import gsap from 'gsap';
import { useSection } from '../../stores/useSection';
import { useSounds } from '../../stores/useSounds';
import { useClues } from '../../stores/useClues';

export function Logo() {
  /*
   * properties
   */

  const header = useRef();
  const setSection = useSection((state) => state.setSection);
  const playSound = useSounds((state) => state.playSound);
  const cluesShown = useClues((state) => state.shown);

  /*
   * hooks
   */

  // useEffect(() => {
  //   gsap.killTweensOf(header.current);
  //   gsap.set(header.current, { opacity: 0 });
  //   gsap.to(header.current, { delay: 1, duration: 1.2, opacity: 1, ease: 'power2.in' });
  // }, []);

  useEffect(() => {
    gsap.killTweensOf(header.current);
    // gsap.set(header.current, { opacity: 0 });

    if (cluesShown) {
      gsap.to(header.current, { delay: 0, duration: 0.6, opacity: 0, ease: 'power2.out' });
    } else {
      gsap.to(header.current, { delay: 1, duration: 1.2, opacity: 1, ease: 'power2.in' });
    }
  }, [cluesShown]);

  const clickHandler = () => {
    // console.log('clickHandler');

    const section = useSection.getState().section;
    const transition = useSection.getState().transition;

    // playClick();
    playSound('GeneralUIClick');

    // console.log('transition', transition);
    // console.log('section', section);

    if (transition === 'show' && section !== 'home') {
      // console.log('CHANGE SECTION');
      setSection('home');
    }
  };

  /*
   * visuals
   */

  return (
    <header ref={header} className={style.logo}>
      <button onClick={clickHandler} className={style.button} aria-label={'logo'}>
        {/* <p className={style.offbrand}>off brand.</p> */}
        <img src="/images/logo.svg" className={style.image} alt="logo" aria-disabled />
        <p className={style.manifesto}>MANIFESTO</p>
      </button>
    </header>
  );
}
