import { useEffect, useMemo, useRef, useState } from 'react';

import { useFrame, useThree } from '@react-three/fiber';
import { Petal } from './Petal';
import { Stem } from './Stem';
import { useTap } from '../../../stores/useTap';

export function Flower({ petalsTapped }) {
  /*
   * properties
   */

  const wrapper = useRef();
  const petalsInHead = useTap((state) => state.petalsInHead);
  const petalsToTap = useTap((state) => state.petalsToTap);
  const tapPct = useTap((state) => state.tapPct);

  /*
   * hooks
   */

  useFrame((state) => {
    wrapper.current.rotation.y = state.clock.elapsedTime * 0.8;
  });

  useEffect(() => {
    const scale = 3 + Math.pow(tapPct * 1.2, 2);
    wrapper.current.scale.set(scale, scale, scale);
  }, [tapPct]);

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper} rotation={[0.6, 0, 0.1]} scale={3}>
        {[...Array(petalsInHead + petalsToTap)].map((value, index) => (
          <Petal key={index} i={index} />
        ))}
        <mesh>
          <sphereGeometry args={[0.03]} />
          <meshBasicMaterial color={'#000000'} />
        </mesh>
        <Stem />
      </group>
    </>
  );
}
