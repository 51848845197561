import { useDrag } from '../../../stores/useDrag';
import { OutroText } from '../../components/OutroText';

export function Text({ finished }) {
  // console.log('Dragging :: render');

  /*
   * visuals
   */

  return (
    <>
      <OutroText
        show={finished}
        section="tapping"
        // text="blossom creativity LINE and nurture environments where one can LINE find themselves through our LINE ventures."
      />
    </>
  );
}
