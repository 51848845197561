import { useEffect, useRef } from 'react';
import { useCameras } from '../../../stores/useCameras';
import { useSection } from '../../../stores/useSection';
import gsap from 'gsap';
import { useSounds } from '../../../stores/useSounds';
import { useFrame } from '@react-three/fiber';
import { useMouse } from '../../../stores/useMouse';

export function Home() {
  /*
   * properties
   */

  const transition = useSection((state) => state.transition);
  const showSection = useSection((state) => state.showSection);
  const camera = useCameras((state) => state.camera);

  const basevolume = useRef(0);

  const playSound = useSounds((state) => state.playSound);
  const sound = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('Home :: onMount');
    showSection();
    sound.current = playSound('InteractHoverLoop');

    return () => {
      // console.log('Home :: onDestroy');
      sound.current.volume(0);
      sound.current.stop();
    };
  }, []);

  // show/hide
  useEffect(() => {
    if (transition === 'show') {
      gsap.set(camera.position, { duration: 0, x: 0, y: 0, z: 10 });
      gsap.set(camera.rotation, { duration: 0, x: 0, y: 0, z: 0 });

      gsap.to(basevolume, { duration: .5, current: 1 });
    } else if (transition === 'hide') {
      gsap.to(basevolume, { duration: .3, current: 0 });
    }
  }, [transition]);

  useFrame(() => {
    const x = useMouse.getState().slowPctX;
    const y = useMouse.getState().slowPctY;
    const dist = Math.hypot(x, y);
    const volume = Math.max(0, 1 - dist * 1) * basevolume.current;
    sound.current.volume(volume);
    // console.log(volume);
  });

  return <></>;
}
