import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useTimer = create(
  persist(
    (set, get) => ({
      time: 0,

      addTime: (time) =>
        set({
          time: get().time + time,
        }),

      reset: () =>
        set({
          time: 0,
        }),
    }),
    {
      name: 'kakapipi',
    }
  )
);
