import { Text, shaderMaterial } from '@react-three/drei';
import { extend } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import vertexShader from './shaders/text/vertex.glsl?raw';
import fragmentShader from './shaders/text/fragment.glsl?raw';

const WordMaterial = shaderMaterial(
  {
    uTime: 0,
    uAmount: 0,
  },
  vertexShader,
  fragmentShader
);

extend({ WordMaterial });

export function Words({ props, position, text, hide = false }) {
  /*
   * properties
   */
  const material = useRef();
  const mesh = useRef();

  // console.log('Words :: render');
  // console.log('hide: ' + hide);

  /*
   * hooks
   */

  useEffect(() => {
    show();
  }, []);

  const show = () => {
    // console.log('Words :: show')
    let delay = props.delay;

    if (!props.static) {
      delay += Math.random() * 1;
    }

    gsap.killTweensOf(material.current.uniforms.uAmount);
    gsap.killTweensOf(mesh.current.position);

    let duration = 2.2;
    gsap.set(mesh.current.position, { x: position[0], y: position[1], z: position[2] });

    gsap.to(material.current.uniforms.uAmount, { delay: delay, duration, value: 1, ease: 'power1.inOut' });
    gsap.from(mesh.current.position, {
      delay: delay,
      duration,
      x: position[0] + Math.random() * 1 - 0.5,
      y: position[1] + Math.random() * 1 - 0.5,
      z: position[2] + Math.random() * 1 - 0.5,
      ease: 'sine.out',
    });

    if (props.hideDelay) {
      delay = props.hideDelay;
      gsap.to(material.current.uniforms.uAmount, {
        delay: props.hideDelay,
        onComplete: hideHandler,
        duration,
        value: 0,
        ease: 'power1.inOut',
      });
    }
  };

  const hideHandler = () => {
    mesh.current.visible = false;
  };

  useEffect(() => {
    if (hide) {
      // console.log('Words :: hide')
      gsap.killTweensOf(material.current.uniforms.uAmount);
      gsap.to(material.current.uniforms.uAmount, { duration: 0.9, value: 0, ease: 'power1.out' });
    }
  }, [hide]);

  /*
   * visuals
   */

  return (
    <>
      <Text {...props} position={position} ref={mesh}>
        {text}
        <wordMaterial ref={material} />
      </Text>
    </>
  );
}
