import { useEffect, useRef, useState } from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { MathUtils } from 'three';

import { useFrame } from '@react-three/fiber';
import { useCameras } from '../../stores/useCameras';
import { useMouse } from '../../stores/useMouse';

export function CameraManager() {
  /*
   * properties
   */

  const setCamera = useCameras((state) => state.setCamera);

  const camera = useRef();
  const wrapper = useRef();
  const ratio = 1 / 1;

  // const config = useControls('camera', {
  //   positionX: { value: 0, min: -10, max: 10, step: 0.1 },
  //   positionY: { value: 7, min: -10, max: 10, step: 0.1 },
  //   positionZ: { value: 0, min: -10, max: 10, step: 0.1 },
  //   rotationX: { value: 4.712, min: -10, max: 10, step: 0.1 },
  //   rotationY: { value: 0, min: -4, max: 4, step: 0.1 },
  //   rotationZ: { value: 6.283, min: -4, max: 4, step: 0.1 },
  // });

  // state properties

  /*
   * hooks
   */

  // set camera FOV after resizing
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, []);

  const resizeHandler = (e) => {
    const fov = 50;
    const cam = camera.current;

    if (cam.aspect > ratio) {
      //window too wide
      cam.fov = fov;
    } else {
      // window too narrow
      const cameraHeight = Math.tan(MathUtils.degToRad(fov * 0.5));
      const rat = cam.aspect / ratio;
      cam.fov = MathUtils.radToDeg(Math.atan(cameraHeight / rat)) * 2;
    }
  };

  useEffect(() => {
    // console.log('useEffect - wrapper.current');
    // console.log('wrapper.current');
    // console.log(wrapper.current);

    setCamera(wrapper.current);
  }, [wrapper.current]);

  //move camera on mouse move
  useFrame(() => {
    const { slowPctX, slowPctY, inited } = useMouse.getState();
    if (inited) {
      // camera.current.position.x = slowPctX * -0.7;
      // camera.current.position.y = slowPctY * 0.7;
      // camera.current.rotation.y = slowPctX * -0.06;
      // camera.current.rotation.x = slowPctY * -0.06;
    }
    // console.log(slowPctX);
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper} position={[0, 0, 10]}>
        <PerspectiveCamera ref={camera} makeDefault near={0.1} far={100} />
      </group>

      {/* <PerspectiveCamera ref={camera} position={[0, 0, 10]} makeDefault near={0.1} far={100} />
      <OrbitControls makeDefault dampingFactor={1} /> */}
    </>
  );
}
