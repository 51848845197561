import { useRef } from 'react';

export function Lighting() {
  /*
   * properties
   */

  const pointLight = useRef();

  // const pointLightProps = useControls('point light', {
  //   intensity: { value: 2.0, min: 0, max: 3, step: 0.001 },
  //   distance: { value: 7.0, min: 0, max: 50, step: 0.01 },
  //   decay: { value: 2.5, min: 0, max: 3, step: 0.01 },
  //   color: { value: '#ffffff' },
  // });

  // const hemiLightProps = useControls('ambient light', {
  //   intensity: { value: 0.05, min: 0, max: 1, step: 0.001 },
  //   color1: { value: '#ffffff', label: 'color 1' },
  //   color2: { value: '#000000', label: 'color 2' },
  // });

  const pointLightProps = {
    intensity: 2.0,
    distance: 7.0,
    decay: 2.5,
    color: '#ffffff',
  };

  const hemiLightProps = {
    intensity: 0.05,
    color1: '#ffffff',
    color2: '#000000',
  };

  /*
   * visuals
   */

  return (
    <>
      <pointLight
        ref={pointLight}
        intensity={pointLightProps.intensity}
        color={pointLightProps.color}
        distance={pointLightProps.distance}
        decay={pointLightProps.decay}
      />

      <hemisphereLight
        position={[0, -1, 0]}
        intensity={hemiLightProps.intensity}
        args={[hemiLightProps.color1, hemiLightProps.color2]}
      />
    </>
  );
}
