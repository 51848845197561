import style from './Intro.module.css';
import { SoundText } from './SoundText';
import { Spiral } from './Spiral';
import { Text } from './Text';
import { EndText } from './EndText';
import { RivSpiral } from './RivSpiral';
import { useEffect } from 'react';
import { useSounds } from '../../../stores/useSounds';

export function Intro() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);

  /*
   * hookd
   */

  useEffect(() => {
    // console.log('Intro :: onMount');
    playSound('IntroReveals');
  }, []);

  /*
   * visuals
   */

  return (
    <>
      <div className={style.intro}>
        <RivSpiral />
        {/* <Text /> */}
        <SoundText />
        <EndText />
      </div>
    </>
  );
}
