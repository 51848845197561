import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../../stores/useSection';
import gsap from 'gsap';
import { ScrollPiece } from '../../components/ScrollPiece';
import { CameraController } from './CameraController';
import { PiecesItem } from './PiecesItem';
import { useManifesto } from '../../../stores/useManifesto';
import { useCursor } from '@react-three/drei';
import { OutroCopy } from '../../components/OutroCopy';
import { useSounds } from '../../../stores/useSounds';

export function Manifesto() {
  /*
   * properties
   */

  const container = useRef();
  const setSection = useManifesto((state) => state.setSection);
  const section = useManifesto((state) => state.section);
  const reset = useManifesto((state) => state.reset);
  const showSection = useSection((state) => state.showSection);
  const transition = useSection((state) => state.transition);
  const [textSection, setTextSection] = useState('intro');
  const [showText, setShowText] = useState(false);

  const timeoutId = useRef();

  const [hovered, set] = useState();
  useCursor(hovered /*'pointer', 'auto', document.body*/);

  const playSound = useSounds((state) => state.playSound);
  const getSound = useSounds((state) => state.getSound);
  const sound = getSound('FinalScreenLoop');

  /*
   * hooks
   */

  useEffect(() => {
    showSection();
    sound.play();
    sound.fade(0, 1, 1000);

    return () => {
      sound.stop();
      reset();
    };
  }, []);

  useEffect(() => {
    if (transition === 'show') {
      const scale = 0.75;
      gsap.set(container.current.scale, { x: 0, y: 0, z: scale });

      gsap.to(container.current.scale, { x: scale, y: scale, duration: 1, ease: 'powe1.in' });
    } else if (transition === 'hide') {
      playSound('ClickToContinue');
      sound.fade(1, 0, 300);
      gsap.to(container.current.scale, { x: 0, y: 0, z: 1, duration: 0.45, ease: 'sine.in' });
    }
  }, [transition]);

  const onClick = (newSection, num) => {
    // console.log('section: ' + section);
    setSection(newSection, num);
    playSound('GeneralUIClick');
  };

  useEffect(() => {
    // console.log('HIDE TEXT');

    setShowText(false);

    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      // console.log('SHOW TEXT');

      setTextSection(section);
      setShowText(true);
    }, 1000);
  }, [section]);

  /*
   * visuals
   */

  return (
    <>
      <CameraController />

      <group ref={container} onPointerOver={() => set(true)} onPointerOut={() => set(false)}>
        <PiecesItem section="holding" num={1} pos={1.2} pct={0.8} onClick={onClick} />
        <PiecesItem section="scrolling" num={2} pos={0.6} pct={0.4} onClick={onClick} />
        <PiecesItem section="dragging" num={3} pos={0} pct={0.2} onClick={onClick} />
        <PiecesItem section="tapping" num={4} pos={-0.6} pct={0.6} onClick={onClick} />
        <PiecesItem section="growing" num={5} pos={-1.2} pct={1} onClick={onClick} />
      </group>

      <group position={[0, 0, 0.7]}>
        <OutroCopy show={showText} delay={0} section={textSection} isManifesto={true} />
      </group>
    </>
  );
}
