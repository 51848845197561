import { useSounds } from '../../stores/useSounds';
import style from './BigLink.module.css';

export function BigLink({ children, href, footer }) {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  const clickHandler = () => {
    playSound('GeneralUIClick');
  };

  /*
   * visuals
   */

  return (
    <a onClick={clickHandler} href={href} className={`${style.cta} ${footer ? style.footer : ''}`} target="_blank">
      {children}
    </a>
  );
}
