import { useScrolling } from '../../../stores/useScrolling';
import { ProgressBar } from '../../components/ProgressBar';
import { CloseSection } from '../../main/CloseSection';
import { Text } from './Text';

export function Scrolling() {
  /*
   * properties
   */
  const scrollPct = useScrolling((state) => state.scrollPct);
  const started = useScrolling((state) => state.started);
  const finished = useScrolling((state) => state.finished);

  /*
   * visuals
   */

  return (
    <>
      <ProgressBar progress={scrollPct} started={started} finished={finished} />
      <CloseSection show={finished} />
      <Text finished={finished} />
    </>
  );
}
