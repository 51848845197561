import { useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';

import { useFrame } from '@react-three/fiber';

export function Lights() {
  /*
   * properties
   */

  // const pointLightProps = useControls('point light', {
  //   intensity: { value: 0.12, min: 0, max: 1, step: 0.001 },
  //   distance: { value: 4.0, min: 0, max: 5, step: 0.01 },
  //   decay: { value: 1.0, min: 0, max: 3, step: 0.01 },
  // });

  // const dirLightProps = useControls('directional light', {
  //   intensity: { value: 0.1, min: 0, max: 1, step: 0.001 },
  //   position: { value: { x: 0, y: 1, z: -2 }, min: -10, max: 10, step: 0.01 },
  // });

  const pointLightProps = {
    intensity: 0.12,
    distance: 4.0,
    decay: 1.0,
  };

  const dirLightProps = {
    intensity: 0.1,
    position: { x: 0, y: 1, z: -2 },
  };

  const dirLight = useRef();
  const pointLight1 = useRef();
  const pointLight2 = useRef();

  /*
   * hooks
   */

  useFrame((state) => {
    const time = state.clock.elapsedTime;
    const radius = 0.8;
    const zDist = 5;

    pointLight1.current.position.x = Math.cos(time * 2) * radius;
    pointLight1.current.position.y = Math.sin(time) * radius;
    pointLight1.current.position.z = -18 + Math.sin(time) * zDist;

    pointLight2.current.position.x = Math.cos(time + 1 * 2) * radius;
    pointLight2.current.position.y = Math.sin(time + 1) * radius;
    pointLight2.current.position.z = -8 + Math.sin(time + 1) * zDist;

    dirLight.current.position.x = Math.cos(time * 0.5) * 1;
    dirLight.current.position.y = Math.sin(time * 0.5) * 1;
    dirLight.current.position.z = -1 + Math.cos(time * 2) * -1;
  });

  /*
   * visuals
   */

  return (
    <>
      <pointLight
        ref={pointLight1}
        // position={[0, 0, -5]}
        intensity={pointLightProps.intensity}
        distance={pointLightProps.distance}
        decay={pointLightProps.decay}
      />

      <pointLight
        ref={pointLight2}
        // position={[0, 0, -5]}
        intensity={pointLightProps.intensity}
        distance={pointLightProps.distance}
        decay={pointLightProps.decay}
      />

      <directionalLight
        ref={dirLight}
        intensity={dirLightProps.intensity}
        position={[dirLightProps.position.x, dirLightProps.position.y, dirLightProps.position.z]}
      />
    </>
  );
}
