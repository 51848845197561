import { useEffect, useRef } from 'react';
import style from './SoundText.module.css';
import gsap from 'gsap';
import { RivAudioIcon } from '../../components/RivAudioIcon';

export function SoundText() {
  /*
   * properties
   */

  const footer = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    gsap.killTweensOf(footer.current);
    gsap.set(footer.current, { opacity: 0 });
    gsap.to(footer.current, { delay: 0.5, duration: 2, opacity: 1, onComplete: step2, ease: 'sine.out' });
  }, []);

  const step2 = () => {
    gsap.killTweensOf(footer.current);
    gsap.to(footer.current, { delay: 0.75, duration: 1.75, opacity: 0, ease: 'sine.in' });
  };

  /*
   * visuals
   */

  return (
    <>
      <footer ref={footer} className={style.text}>
        this experience is enhanced by sound
        <div className={style.audio}>
          <RivAudioIcon on={false} />
        </div>
      </footer>
    </>
  );
}
