import { shaderMaterial } from '@react-three/drei';
import { extend } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import vertexShader from './shaders/text/vertex.glsl?raw';
import fragmentShader from './shaders/text/fragment.glsl?raw';
import { Words } from './Words';
import { useSize } from '../../stores/useSize';
import { Line } from './Line';
import { useSection } from '../../stores/useSection';
import { usePieces } from '../../stores/usePieces';
import { useSounds } from '../../stores/useSounds';

const TextMaterial = shaderMaterial(
  {
    uTime: 0,
    uAmount: 0,
  },
  vertexShader,
  fragmentShader
);

extend({ TextMaterial });

export function OutroCopy({ section, show, delay = 4, isManifesto = false, num = 1 }) {
  /*
   * properties
   */
  const group = useRef();

  const isMobile = useSize((state) => state.isMobile);
  const transition = useSection((state) => state.transition);
  const foundPiece = usePieces((state) => state.foundPiece);
  const foundAllPieces = usePieces((state) => state.foundAllPieces);

  const playSound = useSounds((state) => state.playSound);

  const [showText, setShowText] = useState(false);
  const [hideText, setHideText] = useState(false);
  const [piecesAmount, setPiecesAmount] = useState();
  const [props, setProps] = useState({ font: 'fonts/ReciaSerifDisplay-Bold.woff', delay: delay });

  // const section = 'tapping';

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    resizeHandler();
  }, [isMobile]);

  const resizeHandler = () => {
    // console.log('resizeHandler')
    const ratio = window.innerWidth / window.innerHeight;
    const isMobile = useSize.getState().isMobile;
    let scale;
    // console.log('isMobile', isMobile);

    if (isMobile) {
      // mobile
      scale = 0.15;
      // scale = 0.25;
      setProps({ ...props, fontSize: '1.07' });
    } else {
      // desktop

      scale = Math.min(0.2, 0.1 + ratio * 0.06);

      // scale = Math.min(0.25, 0.1 + ratio * 0.13);

      setProps({ ...props, fontSize: '.87' });
      // console.log('ratio', ratio);
      // console.log('scale', scale);
    }

    // console.log(group.current.children);
    if (group.current.children.length > 0) {
      props.delay = 0;
    }

    group.current.scale.set(scale, scale, scale);
  };

  useEffect(() => {
    // show
    if (show) {
      // console.log('OutroCopy 3D :: show');
      // set and get pieces data
      foundPiece(useSection.getState().section);
      const pieces = usePieces.getState().pieces;

      let amount = 0;
      for (const piece of pieces) {
        if (piece.found) {
          amount++;
        }
      }
      setPiecesAmount(amount);

      setHideText(false);

      const section = useSection.getState().section;
      if (section != 'holding' && !isManifesto) {
        // console.log('badaboem');
        playSound('InteractionComplete');
      }
      setTimeout(() => {
        // console.log('play ScrollReveal');
        playSound('ScrollReveal');
      }, isManifesto ? 1000 : 4400);

      setTimeout(() => {
        setShowText(true);
      }, 100);
    } else {
      // console.log('hide');
      hide();
    }
  }, [show]);

  useEffect(() => {
    // hide
    if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const hide = () => {
    // console.log('OutroCopy 3D :: hide');
    // setShowText(false);
    setHideText(true);

    setTimeout(() => {
      setShowText(false);
    }, 1000);
  };

  /*
   * visuals
   */

  return (
    <>
      <group scale={1} ref={group} position={[0, 0, 1]}>
        {showText && (
          <>
            {/* DESKTOP */}
            {/* ======= */}

            {!isMobile && (
              <>
                {/* INTRO */}
                {section === 'intro' && !foundAllPieces() && (
                  <>
                    <Words props={props} position={[-2, 0.88, 0]} hide={hideText} text={piecesAmount + ' EXTRACTS'} />
                    <Words props={props} position={[-3.4, 0.12, 0]} hide={hideText} text="OUT OF" />
                    <Words props={props} position={[-1, 0.12, 0]} hide={hideText} text="5" />
                    <Words props={props} position={[1.6, -0.6, 0]} hide={hideText} text="DISCOVERED" />
                  </>
                )}
                {/* INTRO FINAL */}
                {section === 'intro' && foundAllPieces() && (
                  <>
                    <Words props={props} position={[-2, 0.86, 0]} hide={hideText} text="YOU HAVE" />
                    <Words props={props} position={[0.65, 0.13, 0]} hide={hideText} text="DISCOVERED" />
                    <Words props={props} position={[-1.7, -0.6, 0]} hide={hideText} text="THE OFF" />
                    <Words props={props} position={[1.6, -0.6, 0]} hide={hideText} text="BRAND" />
                    <Words props={props} position={[2.33, -1.33, 0]} hide={hideText} text="MANIFESTO." />
                  </>
                )}
                {/* DRAGGING */}
                {section === 'dragging' && (
                  <>
                    <Words props={props} position={[-2, 0.84, 0]} hide={hideText} text="TWIST AND BEND" />
                    <Words props={props} position={[3.5, 0.84, 0]} hide={hideText} text="EACH &" />
                    <Line props={props} hide={hideText} position={[1.45, 0.92, 0]} />
                    <Words props={props} position={[-3.4, 0.12, 0]} hide={hideText} text="EVERY PIXEL" />
                    <Line props={props} hide={hideText} position={[-0.67, 0.2, 0]} size={1.7} />
                    <Words props={props} position={[2.75, 0.12, 0]} hide={hideText} text="INTO PERFECTLY" />
                    <Words props={props} position={[-0.42, -0.6, 0]} hide={hideText} text="FORMED VISUAL FESTIVALS" />
                    <Words props={props} position={[-2.6, -1.33, 0]} hide={hideText} text="EYEBALLS DREAM" />
                    <Line props={props} hide={hideText} position={[1.06, -1.23, 0]} size={1.8} />
                    <Words props={props} position={[3.7, -1.33, 0]} hide={hideText} text="TO SOAK IN." />
                  </>
                )}

                {/* GROWING */}
                {section === 'growing' && (
                  <>
                    <Words props={props} position={[-1.5, 0.84, 0]} hide={hideText} text="TRANSFORM PROJECTS" />
                    <Line props={props} hide={hideText} position={[2.85, 0.92, 0]} size={1.6} />
                    <Words props={props} position={[4.5, 0.84, 0]} hide={hideText} text="INTO" />
                    <Words props={props} position={[-4.4, 0.12, 0]} hide={hideText} text="LIVING" />
                    <Line props={props} hide={hideText} position={[-2.5, 0.2, 0]} />
                    <Words props={props} position={[1.5, 0.12, 0]} hide={hideText} text="BREATHING ENTITIES" />
                    <Words props={props} position={[-2.6, -0.6, 0]} hide={hideText} text="WHICH THRIVE" />
                    <Line props={props} hide={hideText} position={[0.52, -0.51, 0]} size={1.6} />
                    <Words props={props} position={[3.3, -0.6, 0]} hide={hideText} text="AND SPREAD" />
                    <Words props={props} position={[1.2, -1.33, 0]} hide={hideText} text="ACROSS THE GLOBE." />
                  </>
                )}

                {/* HOLDING */}
                {section === 'holding' && (
                  <>
                    <Words props={props} position={[-3.75, 0.84, 0]} hide={hideText} text="BLOW UP" />
                    <Line props={props} hide={hideText} position={[-1.5, 0.92, 0]} size={1.6} />
                    <Words props={props} position={[1.75, 0.84, 0]} hide={hideText} text="THE STATUS QUO" />
                    <Words props={props} position={[-1.4, 0.12, 0]} hide={hideText} text="OF BEHAVIOURAL NORMS" />
                    <Line props={props} hide={hideText} position={[3.2, 0.2, 0]} />
                    <Words props={props} position={[4.4, 0.12, 0]} hide={hideText} text="TO" />
                    <Words
                      props={props}
                      position={[-0.2, -0.6, 0]}
                      hide={hideText}
                      text="DEVISE EYE-OPENING INTERACTIONS"
                    />
                    <Words props={props} position={[-3, -1.32, 0]} hide={hideText} text="FINGERS" />
                    <Line props={props} hide={hideText} position={[-0.85, -1.23, 0]} />
                    <Words props={props} position={[2.5, -1.32, 0]} hide={hideText} text="FORGET TIME IN." />
                  </>
                )}

                {/* SCROLLING */}
                {section === 'scrolling' && (
                  <>
                    <Words props={props} position={[-2.3, 0.84, 0]} hide={hideText} text="TAKE PEOPLE TO" />
                    <Line props={props} hide={hideText} position={[0.95, 0.92, 0]} />
                    <Words props={props} position={[3.2, 0.84, 0]} hide={hideText} text="ANOTHER" />
                    <Words props={props} position={[-4.7, 0.12, 0]} hide={hideText} text="PLACE" />
                    <Line props={props} hide={hideText} position={[-2.9, 0.2, 0]} />
                    <Words props={props} position={[1.8, 0.12, 0]} hide={hideText} text="IN RAUCOUS EXPERIENCES" />
                    <Words props={props} position={[-3, -0.6, 0]} hide={hideText} text="WHICH AIM" />
                    <Line props={props} hide={hideText} position={[-0.42, -0.5, 0]} />
                    <Words props={props} position={[2.5, -0.6, 0]} hide={hideText} text="TO STIMULATE," />
                    <Words props={props} position={[1.4, -1.33, 0]} hide={hideText} text="INSPIRE & THRILL." />
                  </>
                )}

                {/* TAPPING */}
                {section === 'tapping' && (
                  <>
                    <Words props={props} position={[-2.15, 0.84, 0]} hide={hideText} text="BLOSSOM CREATIVITY" />
                    <Line props={props} hide={hideText} position={[2.09, 0.92, 0]} size={1.95} />
                    <Words props={props} position={[3.8, 0.84, 0]} hide={hideText} text="AND" />
                    <Words props={props} position={[0.2, 0.12, 0]} hide={hideText} text="NURTURE ENVIRONMENTS WHERE" />
                    <Words props={props} position={[-4.1, -0.6, 0]} hide={hideText} text="ONE CAN" />
                    <Line props={props} hide={hideText} position={[-1.95, -0.51, 0]} />
                    <Words props={props} position={[1.65, -0.6, 0]} hide={hideText} text="FIND THEMSELVES" />
                    <Words props={props} position={[-2.7, -1.33, 0]} hide={hideText} text="THROUGH OUR" />
                    <Line props={props} hide={hideText} position={[0.45, -1.23, 0]} size={1.9} />
                    <Words props={props} position={[3.2, -1.33, 0]} hide={hideText} text="VENTURES." />
                  </>
                )}
              </>
            )}

            {/* MOBILE */}
            {/* ====== */}

            {isMobile && (
              <>
                {/* INTRO */}
                {section === 'intro' && !foundAllPieces() && (
                  <>
                    <Words props={props} position={[-2, 1.7, 0]} hide={hideText} text={piecesAmount + ' EXTRACTS'} />
                    <Words props={props} position={[-3.6, 0.75, 0]} hide={hideText} text="OUT OF" />
                    <Words props={props} position={[-1, 0.75, 0]} hide={hideText} text="5" />
                    <Words props={props} position={[1.05, -0.25, 0]} hide={hideText} text="DISCOVERED" />
                  </>
                )}
                {/* INTRO FINAL */}
                {section === 'intro' && foundAllPieces() && (
                  <>
                    <Words props={props} position={[-2.1, 1.7, 0]} hide={hideText} text="YOU HAVE" />
                    <Words props={props} position={[0.3, 0.73, 0]} hide={hideText} text="DISCOVERED" />
                    <Words props={props} position={[-3, -0.25, 0]} hide={hideText} text="THE OFF" />
                    <Words props={props} position={[1, -0.25, 0]} hide={hideText} text="BRAND" />
                    <Words props={props} position={[1.9, -1.2, 0]} hide={hideText} text="MANIFESTO." />
                  </>
                )}
                {/* DRAGGING */}
                {section === 'dragging' && (
                  <>
                    <Words props={props} position={[-0.9, 2.7, 0]} hide={hideText} text="TWIST" />
                    <Words props={props} position={[-0.7, 1.7, 0]} hide={hideText} text="AND BEND" />
                    <Words props={props} position={[-2.9, 0.73, 0]} hide={hideText} text="EACH &" />
                    <Line props={props} hide={hideText} position={[-0.3, 0.81, 0]} />
                    <Words props={props} position={[2.1, 0.73, 0]} hide={hideText} text="EVERY" />
                    <Words props={props} position={[-0.8, -0.22, 0]} hide={hideText} text="PIXEL INTO PERFECTLY" />
                    <Words props={props} position={[-2.1, -1.2, 0]} hide={hideText} text="FORMED" />
                    <Line props={props} hide={hideText} position={[0.7, -1.12, 0]} />
                    <Words props={props} position={[3.2, -1.2, 0]} hide={hideText} text="VISUAL" />
                    <Words props={props} position={[-0.4, -2.2, 0]} hide={hideText} text="FESTIVALS EYEBALLS" />
                    <Words props={props} position={[0.2, -3.2, 0]} hide={hideText} text="DREAM TO SOAK IN." />
                  </>
                )}

                {/* GROWING */}
                {section === 'growing' && (
                  <>
                    <Words props={props} position={[-0.9, 2.7, 0]} hide={hideText} text="TRANSFORM" />
                    <Words props={props} position={[-0.7, 1.7, 0]} hide={hideText} text="PROJECTS" />
                    <Words props={props} position={[-2.9, 0.73, 0]} hide={hideText} text="INTO" />
                    <Line props={props} hide={hideText} position={[-0.78, 0.81, 0]} />
                    <Words props={props} position={[1.7, 0.73, 0]} hide={hideText} text="LIVING" />
                    <Words props={props} position={[-0.8, -0.22, 0]} hide={hideText} text="BREATHING ENTITIES" />
                    <Words props={props} position={[-2.1, -1.2, 0]} hide={hideText} text="WHICH" />
                    <Line props={props} hide={hideText} position={[0.4, -1.12, 0]} />
                    <Words props={props} position={[2.9, -1.2, 0]} hide={hideText} text="THRIVE" />
                    <Words props={props} position={[-0.4, -2.2, 0]} hide={hideText} text="AND SPREAD" />
                    <Words props={props} position={[0.4, -3.2, 0]} hide={hideText} text="ACROSS THE GLOBE." />
                  </>
                )}

                {/* HOLDING */}
                {section === 'holding' && (
                  <>
                    <Words props={props} position={[-0.9, 2.7, 0]} hide={hideText} text="BLOW UP" />
                    <Words props={props} position={[-0.7, 1.7, 0]} hide={hideText} text="THE STATUS QUO" />
                    <Words props={props} position={[-3.35, 0.73, 0]} hide={hideText} text="OF" />
                    <Line props={props} hide={hideText} position={[-1.7, 0.81, 0]} />
                    <Words props={props} position={[2.1, 0.73, 0]} hide={hideText} text="BEHAVIOURAL" />
                    <Words props={props} position={[-0.8, -0.22, 0]} hide={hideText} text="NORMS TO DEVISE" />
                    <Words props={props} position={[0, -1.2, 0]} hide={hideText} text="EYE-OPENING INTERACTIONS" />
                    <Words props={props} position={[-2.4, -2.2, 0]} hide={hideText} text="FINGERS" />
                    <Line props={props} hide={hideText} position={[0.4, -2.12, 0]} />
                    <Words props={props} position={[3, -2.2, 0]} hide={hideText} text="FORGET" />
                    <Words props={props} position={[1.8, -3.2, 0]} hide={hideText} text="TIME IN." />
                  </>
                )}

                {/* SCROLLING */}
                {section === 'scrolling' && (
                  <>
                    <Words props={props} position={[-0.9, 2.7, 0]} hide={hideText} text="TAKE PEOPLE" />
                    <Words props={props} position={[-0.7, 1.7, 0]} hide={hideText} text="TO ANOTHER PLACE" />
                    <Words props={props} position={[-2.9, 0.73, 0]} hide={hideText} text="IN" />
                    <Line props={props} hide={hideText} position={[-1.3, 0.81, 0]} />
                    <Words props={props} position={[1.7, 0.73, 0]} hide={hideText} text="RAUCOUS" />
                    <Words props={props} position={[-0.8, -0.22, 0]} hide={hideText} text="EXPERIENCES" />
                    <Words props={props} position={[-2.1, -1.2, 0]} hide={hideText} text="WHICH AIM" />
                    <Line props={props} hide={hideText} position={[1.25, -1.12, 0]} />
                    <Words props={props} position={[2.9, -1.2, 0]} hide={hideText} text="TO" />
                    <Words props={props} position={[-0.4, -2.2, 0]} hide={hideText} text="STIMULATE, INSPIRE" />
                    <Words props={props} position={[1.8, -3.2, 0]} hide={hideText} text="& THRILL." />
                  </>
                )}

                {/* TAPPING */}
                {section === 'tapping' && (
                  <>
                    <Words props={props} position={[-0.9, 2.7, 0]} hide={hideText} text="BLOSSOM" />
                    <Words props={props} position={[-0.7, 1.7, 0]} hide={hideText} text="CREATIVITY" />
                    <Words props={props} position={[-3.3, 0.73, 0]} hide={hideText} text="AND" />
                    <Line props={props} hide={hideText} position={[-1.25, 0.81, 0]} />
                    <Words props={props} position={[1.7, 0.73, 0]} hide={hideText} text="NURTURE" />
                    <Words props={props} position={[-0.8, -0.22, 0]} hide={hideText} text="ENVIRONMENTS WHERE" />
                    <Words props={props} position={[-1.7, -1.2, 0]} hide={hideText} text="ONE" />
                    <Line props={props} hide={hideText} position={[0.3, -1.12, 0]} />
                    <Words props={props} position={[2.4, -1.2, 0]} hide={hideText} text="CAN" />
                    <Words props={props} position={[-0.4, -2.2, 0]} hide={hideText} text="FIND THEMSELVES" />
                    <Words props={props} position={[0, -3.2, 0]} hide={hideText} text="THROUGH OUR VENTURES." />
                  </>
                )}
              </>
            )}
          </>
        )}
      </group>
    </>
  );
}
