import { BranchItem } from './BranchItem';
import { Instances } from '@react-three/drei';
import { Branch } from './Branch';

export function Branches() {
  /*
   * properties
   */

  // const total = 6;

  // const props = useControls('branch material', {
  //   roughness: { value: 0, min: 0, max: 1, step: 0.01 },
  //   metalness: { value: 0.58, min: 0, max: 1, step: 0.01 },
  //   color: '#898989',
  // });

  const props = {
    roughness: 0,
    metalness: 0.58,
    color: '#898989',
  };

  /*
   * hooks
   */

  //   useEffect(() => {
  //     // console.log('Branches :: onMount');
  //   }, []);

  /*
   * visuals
   */

  return (
    <>
      <group scale={[1.5, 1.5, 1.5]}>
        <Instances>
          <icosahedronGeometry args={[0.4, 4]} />
          <meshStandardMaterial color={props.color} metalness={props.metalness} roughness={props.roughness} />

          {/* <Instance /> */}

          <BranchItem pct={0} branchTimeExtra={0} />

          <group>
            <Branch rotY={0.3} rotZ={0.3}>
              <Branch rotY={-0.4} rotZ={-0.3}>
                <Branch rotY={-0.2} rotZ={0.5}>
                  <Branch rotZ={0.75} />
                  <Branch rotX={1} rotZ={-0.9} />
                </Branch>
              </Branch>

              <Branch rotX={0.5} rotZ={1.5}>
                <Branch rotY={-0.2} rotZ={0.5}>
                  <Branch rotZ={0.75} />
                  <Branch rotX={1} rotZ={-0.9} />
                </Branch>
              </Branch>
            </Branch>

            <Branch rotX={1} rotY={2} rotZ={1.3}>
              <Branch rotY={-0.4} rotZ={-0.3}>
                <Branch rotY={-0.2} rotZ={0.5}>
                  <Branch rotZ={0.75} />
                  <Branch rotX={1} rotZ={-0.9} />
                </Branch>
              </Branch>

              <Branch rotX={0.5} rotZ={1.5}>
                <Branch rotY={-0.2} rotZ={0.5}>
                  <Branch rotZ={0.75} />
                  <Branch rotX={1} rotZ={-0.9} />
                </Branch>
              </Branch>
            </Branch>

            <Branch rotY={3.3} rotZ={-1}>
              <Branch rotY={-0.4} rotZ={0.5}>
                <Branch rotY={-0.6} rotZ={0.5}>
                  <Branch rotZ={0.75} />
                  <Branch rotX={1} rotZ={-0.9} />
                </Branch>
              </Branch>

              <Branch rotX={-2.5} rotZ={1}>
                <Branch rotX={1} rotY={-0.5} rotZ={0.5}>
                  <Branch rotZ={0.75} />
                  <Branch rotX={1} rotZ={-0.9} />
                </Branch>
              </Branch>
            </Branch>
          </group>
        </Instances>
      </group>
    </>
  );
}
