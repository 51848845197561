import { Outlines, shaderMaterial, useTexture } from '@react-three/drei';
import { extend, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import style from './IntroText.module.css';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import vertexShader from './shaders/scroll/vertex.glsl?raw';
import fragmentShader from './shaders/scroll/fragment.glsl?raw';
import { useSection } from '../../stores/useSection';

const ScrollMaterial = shaderMaterial(
  {
    uActive: true,
    uTime: 0,
    uShow: 0,
    uRune: 0,
    uGlow: 0,
    uOver: 0,
    uElevationFactor: 1,
    uGlowColor: new THREE.Color(1, 1, 1),
    uFrequencyX: 1,
    uFrequencyY: 1,
    uWavelength: 0.2,
    uTexture: null,
  },
  vertexShader,
  fragmentShader
);

extend({ ScrollMaterial });

export function ScrollPiece({ show, textureName, clickHandler, isActive = true, hideRunes = true }) {
  /*
   * properties
   */

  const texture = useTexture(`./textures/piece_${textureName}.png`);
  const section = useSection((state) => state.section);

  // const material = useRef();

  // const materialProps = useControls('scroll piece material', {
  //   uGlow: { value: 1, min: 0, max: 1, step: 0.001 },
  //   uGlowColor: { value: '#cccccc' },
  //   uShow: { value: 0, min: 0, max: 1, step: 0.001 },
  //   uRune: { value: 1, min: 0, max: 1, step: 0.001 },
  //   uFrequencyX: { value: 3.9, min: 0, max: 5, step: 0.001 },
  //   uFrequencyY: { value: 3.1, min: 0, max: 5, step: 0.001 },
  //   uWavelength: { value: 0.12, min: 0, max: 1, step: 0.001 },
  // });

  const materialProps = {
    uGlow: 1,
    uGlowColor: '#cccccc',
    uShow: 0,
    uRune: 1,
    uFrequencyX: 3.9,
    uFrequencyY: 3.1,
    uWavelength: 0.12,
    uOver: 0.0,
  };

  const material = useRef();
  const mesh = useRef();

  const [inited, setInited] = useState(false);

  /*
   * hooks
   */

  // show/hide
  useEffect(() => {
    if (show) {
      setInited(true);

      const startScale = 0.6;
      const duration = 4;

      gsap.set(mesh.current.scale, { x: startScale, y: startScale, z: startScale });
      gsap.to(mesh.current.scale, { duration, x: 1, y: 1, z: 1, ease: 'power1.out' });

      gsap.set(mesh.current.rotation, { x: 0.8, y: 0.1, z: -0.2 });
      gsap.to(mesh.current.rotation, { duration, x: 0, y: 0, z: 0.12, ease: 'sine.out' });

      gsap.set(material.current.uniforms.uFrequencyX, { value: 15 });
      gsap.to(material.current.uniforms.uFrequencyX, { duration, value: 1.6, ease: 'power1.out' });

      gsap.set(material.current.uniforms.uFrequencyY, { value: 7 });
      gsap.to(material.current.uniforms.uFrequencyY, { duration, value: 2.4, ease: 'power1.out' });

      gsap.set(material.current.uniforms.uWavelength, { value: 0.5 });
      gsap.to(material.current.uniforms.uWavelength, { duration, value: 0.2, ease: 'power1.out' });

      gsap.to(material.current.uniforms.uShow, { duration, value: 1, ease: 'sine.out' });
      gsap.to(material.current.uniforms.uGlow, { duration, value: 0, ease: 'power4.inOut' });

      // hide glyphs
      if (hideRunes) {
        gsap.to(material.current.uniforms.uRune, { delay: 4, duration: 0.5, value: 0.2, ease: 'power1.out' });
      }
    }
  }, [show]);

  useFrame((state, delta) => {
    material.current.uniforms.uTime.value += delta;
  });

  const overHandler = () => {
    // console.log('overHandler');
    if (section === 'manifesto') {
      gsap.to(material.current.uniforms.uOver, { duration: 0.2, value: 0.08, ease: 'sine.out' });
    }
  };

  const outHandler = () => {
    // console.log('outHandler');
    if (section === 'manifesto') {
      gsap.to(material.current.uniforms.uOver, { duration: 0.4, value: 0, ease: 'sine.out' });
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <mesh ref={mesh} visible={inited} onClick={clickHandler} onPointerOver={overHandler} onPointerOut={outHandler}>
        <planeGeometry args={[3, 0.6, 40, 10]} />
        <scrollMaterial
          ref={material}
          uActive={isActive}
          uTexture={texture}
          uRune={materialProps.uRune}
          uShow={materialProps.uShow}
          uFrequencyX={materialProps.uFrequencyX}
          uFrequencyY={materialProps.uFrequencyY}
          uWavelength={materialProps.uWavelength}
          uGlow={materialProps.uGlow}
          uGlowColor={materialProps.uGlowColor}
          uOver={materialProps.uOver}
          uElevationFactor={section === 'dragging' ? .2 : 1}
          // depthTest={false}
          transparent={true}
        />
      </mesh>
    </>
  );
}
