import { create } from 'zustand';

export const useTap = create((set, get) => ({
  petalsTapped: 0,
  petalsInHead: 14,
  petalsToTap: 30,

  tapPct: 0,
  started: false,
  finished: false,

  reset: () => set({ petalsTapped: 0, tapPct: 0, started: false, finished: false }),

  start: () => set({ started: true }),
  finish: () => set({ finished: true }),

  tap: () => set({ petalsTapped: get().petalsTapped + 1, tapPct: Math.min(1, get().tapPct + .07) }),
  setTapPct: (tapPct) => set({ tapPct }),
}));
