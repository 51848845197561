export default class Bender {
  static bend(geometry, source, axis, angle) {
    let theta = 0;

    const v = geometry.getAttribute('position').array;

    for (let i = 0; i < v.length; i += 3) {
      let x = source[i];
      let y = source[i + 1];
      let z = source[i + 2];

      theta = axis === 'x' ? y * angle : x * angle;

      let sinTheta = Math.sin(theta);
      let cosTheta = Math.cos(theta);

      switch (axis) {
        case 'x':
          v[i] = x;
          v[i + 1] = -(z - 1.0 / angle) * sinTheta; // shrinking
          v[i + 2] = (z - 1.0 / angle) * cosTheta + 1.0 / angle; //bending
          break;
        case 'y':
          v[i] = -(z - 1.0 / angle) * sinTheta;
          v[i + 1] = y;
          v[i + 2] = (z - 1.0 / angle) * cosTheta + 1.0 / angle;
          break;
      }
    }
  }
}
