import { useEffect, useState } from 'react';
import style from './CookieBanner.module.css';
import Cookies from 'js-cookie';

export function CookieBanner() {
  /*
   * properties
   */

  const [isCookieSet, setCookie] = useState(Cookies.get('cookieConsent'));

  // console.log(isCookieSet);

  /*
   * hooks
   */

  const clickHandler = () => {
    Cookies.set('cookieConsent', true);
    setCookie(true);
  };

  /*
   * visuals
   */

  return (
    <>
      {!isCookieSet && (
        <div className={style.banner}>
          This website uses
          <a className={style.link} href="https://www.itsoffbrand.com/privacy" target="_blank">
            cookies
          </a>
          .
          <button onClick={clickHandler} className={style.button}>
            Accept
          </button>
        </div>
      )}
    </>
  );
}
