import { useDrag } from '../../../stores/useDrag';
import { OutroText } from '../../components/OutroText';

export function Text({ finished }) {
  // console.log('Dragging :: render');

  /*
   * visuals
   */

  return (
    <>
      <OutroText
        show={finished}
        section="dragging"
        // text="twist and bend LINE each & every pixel LINE into perfectly formed visual festivals eyeballs dream LINE to soak in."
      />
    </>
  );
}
