import { useEffect, useRef } from 'react';
import { useCameras } from '../../../stores/useCameras';
import gsap from 'gsap';

export function CameraController() {
  /*
   * properties
   */
  const camera = useCameras((state) => state.camera);

  /*
   * hooks
   */
  useEffect(() => {
    if (camera) {
      gsap.set(camera.position, { duration: 0, x: 0, y: 0, z: 4 }, 0);
    }
  }, [camera]);
}
