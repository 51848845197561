import { useEffect } from 'react';
import { useGrow } from '../../../stores/useGrow';
import { ProgressBar } from '../../components/ProgressBar';
import { Text } from './Text';
import style from './Growing.module.css';
import { CloseSection } from '../../main/CloseSection';

export function Growing() {
  /*
   * properties
   */
  const growPct = useGrow((state) => state.growPct);
  const started = useGrow((state) => state.started);
  const finished = useGrow((state) => state.finished);

  useEffect(() => {
    // console.log('Growing :: 2D');
  }, []);

  /*
   * visuals
   */

  return (
    <>
      <div id="growOverlay" className={style.growOverlay} />
      <ProgressBar progress={growPct} started={started} finished={finished} />
      <CloseSection show={finished} />
      <Text finished={finished} />
    </>
  );
}
