import { useRive } from 'rive-react';

export function RivCluesBackground({ name }) {
  const STATE_MACHINE_NAME = '';

  const { RiveComponent } = useRive({
    src: '/riv/manifesto.riv',
    artboard: name,
    stateMachines: name,
    autoplay: true,
  });

  return (
    <>
      <RiveComponent />
    </>
  );
}
