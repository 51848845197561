import { useDrag } from '../../../stores/useDrag';
import { ProgressBar } from '../../components/ProgressBar';
import { CloseSection } from '../../main/CloseSection';
import { Text } from './Text';

export function Dragging() {
  /*
   * properties
   */
  const dragPct = useDrag((state) => state.dragPct);
  const started = useDrag((state) => state.started);
  const finished = useDrag((state) => state.finished);

  /*
   * visuals
   */

  return (
    <>
      <ProgressBar progress={dragPct} started={started} finished={finished} />
      <CloseSection show={finished} />
      <Text finished={finished} />
    </>
  );
}
