import { useState } from 'react';
import style from './Button.module.css';
import { RivButtonBackground } from './RivButtonBackground';
import { Icon } from '../Icon';
import { useSounds } from '../../../stores/useSounds';

export function ButtonSvg({ svg, small = false, strikethrough = false, onClick, onHover, ariaLabel }) {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const [over, setOver] = useState(false);

  const numeral = ['I', 'II', 'III', 'IV', 'V'];

  /*
   * hooks
   */

  const clickHandler = () => {
    onClick && onClick();
    playSound('GeneralUIClick');
  };

  /*
  * visuals
  */

  return (
    <button
      aria-label={ariaLabel}
      className={`${style.button} ${small ? style.small : ''}`}
      onClick={clickHandler}
      onMouseEnter={() => {
        setOver(true);
        onHover && onHover();
      }}
      onMouseLeave={() => {
        setOver(false);
      }}
    >
      <div className={style.riv}>
        <RivButtonBackground over={over} strikethrough={strikethrough} />
      </div>

      <div className={`${style.icon} ${style.under}`}>
        <Icon svg={svg} size="responsive" />
      </div>

      <div className={`${style.icon} ${style.over}`}>
        <Icon svg={svg} size="responsive" />
      </div>

      {/* <div className={`${style.text} ${style.under}`}>{numeral[text]}</div>
      <div className={`${style.text} ${style.over}`}>{numeral[text]}</div> */}
    </button>
  );
}
