import { useEffect } from 'react';
import { useMouse } from '../stores/useMouse';

export function MouseController() {
  /*
   * properties
   */
  const { setMousePosition, calculateSlowMousePosition, setMouseMaximums, initMousePosition } = useMouse();

  /*
   * hooks
   */

  // start
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    window.addEventListener('mousemove', handleMouseMove);

    const intervalId = setInterval(loop, 1000 / 60);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // resize
  const resizeHandler = () => {
    const xMax = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const yMax = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    setMouseMaximums(xMax, yMax);
  };

  // mouse move
  const handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;

    if (!useMouse.getState().inited) {
      initMousePosition(window.innerWidth * 0.5, window.innerHeight * 0.5);
    } else {
      setMousePosition(x, y);
    }
  };

  const loop = () => {
    if (useMouse.getState().inited) {
      calculateSlowMousePosition();
    }
  };
}
