import { useTap } from '../../../stores/useTap';
import { ProgressBar } from '../../components/ProgressBar';
import { CloseSection } from '../../main/CloseSection';
import { Text } from './Text';

export function Tapping() {
  /*
   * properties
   */
  const tapPct = useTap((state) => state.tapPct);
  const started = useTap((state) => state.started);
  const finished = useTap((state) => state.finished);

  /*
   * visuals
   */

  return (
    <>
      <ProgressBar progress={tapPct} started={started} finished={finished} />
      <CloseSection show={finished} />
      <Text finished={finished} />
    </>
  );
}
