// import { Route, Routes } from 'react-router-dom';

import { Home } from './sections/home/Home';
import { Dragging } from './sections/dragging/Dragging';
import { Test } from './sections/test/Test';
import { UI } from './sections/ui/UI';
import { useSection } from '../stores/useSection';
import { Logo } from './main/Logo';
import { Footer } from './main/Footer';
import { Pieces } from './main/Pieces';
import { Mouse } from './main/Mouse';
import { MouseController } from '../controllers/MouseController';
import { SizeController } from '../controllers/SizeController';
import { SectionController } from '../controllers/SectionController';
import { Clues } from './main/Clues';
import { ScrollController } from '../controllers/ScrollController';
import { Scrolling } from './sections/scrolling/Scrolling';
import { Tapping } from './sections/tapping/Tapping';
import { Holding } from './sections/holding/Holding';
import { Growing } from './sections/growing/Growing';
import { Intro } from './sections/intro/Intro';
import { useEffect, useState } from 'react';
import { Manifesto } from './sections/manifesto/Manifesto';
import { Interaction } from './main/Interaction';
import { Sections } from './Sections';
import { NotFound } from './sections/notfound/NotFound';
import { Example } from './Example';
import { CookieBanner } from './main/CookieBanner';

export function App2D() {
  /*
   * properties
   */

  const section = useSection((state) => state.section);
  const [showUI, setShowUI] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    if (!showUI && section !== 'intro') {
      setShowUI(true);
    }
  }, [section]);

  /*
   * visuals
   */

  return (
    <>
      {/* CONTROLLERS */}
      <MouseController />
      <SizeController />
      <SectionController />
      {/* <ScrollController /> */}

      <CookieBanner />
      <Footer />

      {/* MAIN UI */}
      {showUI && (
        <>
          <Logo />
          <Pieces />
          <Clues />
          <Mouse />
        </>
      )}

      {/* <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Sections />} />
      </Routes> */}

      <Sections />

      <Example />
    </>
  );
}
