import { useEffect, useState } from 'react';
import { useSection } from '../../stores/useSection';
import { RoundButton } from '../components/RoundButton';
import { ButtonClose } from '../components/buttons/ButtonClose';
import style from './CloseSection.module.css';

export function CloseSection({ onClick, show = true, inverted = false }) {
  /*
   * properies
   */

  const [isActive, setIsActive] = useState(true);

  const setSection = useSection((state) => state.setSection);
  const transition = useSection((state) => state.transition);

  /*
   * hooks
   */

  useEffect(() => {
    // title.current.style.visibility = 'hidden';
    if (transition === 'hide') {
      setIsActive(false);
    }
  }, [transition]);

  const clickHandler = () => {
    setSection('home');
    onClick && onClick;
  };

  /*
   * visuals
   */

  return (
    <>
      {show && (
        <div className={`${style.sectionClose} ${isActive ? style.isActive : ''}`}>
          {/* <RoundButton svg="close" inverted={inverted} onClick={clickHandler} ariaLabel="home" /> */}
          <ButtonClose onClick={clickHandler} inverted={inverted} ariaLabel="home" />
        </div>
      )}
    </>
  );
}
