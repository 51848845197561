import { useSounds } from '../../stores/useSounds';
import style from './SmallButton.module.css';

export function SmallButton({ children, onClick, ariaLabel }) {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  const clickHandler = () => {
    onClick && onClick();
    playSound('GeneralUIClick');
  };

  /*
  * visuals
  */

  return (
    <button aria-label={ariaLabel} className={style.cta} onClick={clickHandler}>
      {children}
    </button>
  );
}
