import { useDrag } from '../../../stores/useDrag';
import { OutroText } from '../../components/OutroText';

export function Text({ finished }) {
  // console.log('Dragging :: render');

  /*
   * visuals
   */

  return (
    <>
      <OutroText
        show={finished}
        section="holding"
        // text="blow up LINE the status quo of behavioural norms LINE to devise eye-opening interactions fingers LINE forget time in."
      />
    </>
  );
}
