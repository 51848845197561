import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../../stores/useSection';
import gsap from 'gsap';
import { ScrollPiece } from '../../components/ScrollPiece';
import { CameraController } from './CameraController';
import { usePieces } from '../../../stores/usePieces';

export function PiecesItem({ section, pos, pct, onClick, num }) {
  /*
   * properties
   */

  const container = useRef();
  const pieces = usePieces((state) => state.pieces);
  const isActive = useRef(false);

  const [showPiece, setShowPiece] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('***');
    // console.log(pieces);
    isActive.current = pieces.find((element) => element.section === section).found;

    gsap.killTweensOf(container.current.position);
    gsap.set(container.current.position, { x: pos * 1.5, y: pos * 0.5 });
    gsap.to(container.current.position, {
      onStart: () => {
        setShowPiece(true);
      },
      x: 0,
      y: pos,
      delay: pct * 2,
      duration: 3,
      ease: 'back.out',
    });
  }, []);

  const clickHandler = () => {
    if (isActive.current) {
      // console.log('clickHandler');
      // console.log('section', section);
      // console.log('num', num);
      onClick(section, num);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <group ref={container} position={[0, pos, 0]}>
        <ScrollPiece
          show={showPiece}
          textureName={section}
          isActive={isActive.current}
          hideRunes={true}
          clickHandler={clickHandler}
        />
      </group>
    </>
  );
}
