import { useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';

import { useFrame } from '@react-three/fiber';
import { usePositions } from './usePositions';
import { Ring } from './Ring';

/*
 * global propeerties
 */

/*
 * Tunnel
 */

export function Tunnel() {
  // console.log('RENDER TUNNEL');

  const ringAmount = 14;
  const size = 36;
  const pointAmount = 10;

  var points = [];
  for (var i = 0; i < pointAmount; i++) {
    const pct = i / (pointAmount - 1);
    points.push(new THREE.Vector3(0, 0, -size * pct));
  }
  const curve = new THREE.CatmullRomCurve3(points);

  /*
   * properties
   */

  // const props = useControls('tunnel material', {
  //   roughness: { value: 0.6, min: 0, max: 1, step: 0.01 },
  //   metalness: { value: 0.9, min: 0, max: 1, step: 0.01 },
  //   color: '#000000',
  // });

  const props = {
    roughness: 0.6,
    metalness: 0.9,
    color: '#000000',
  };

  const setPositions = usePositions((state) => state.setPositions);

  const [startPosition, setStartPosition] = useState();
  const mesh = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('SET START POSITION');

    setStartPosition(mesh.current.geometry.getAttribute('position').clone());
  }, [mesh.current]);

  useFrame((state) => {
    if (startPosition && mesh.current) {
      setCurve(state.clock);
      setPoints(state.clock);
      setTunnel();
    }
  });

  const setCurve = (clock) => {
    for (var i = 0; i < pointAmount; i++) {
      const angle = clock.elapsedTime + i * 4.2;
      const slideSize = 0.3;
      curve.points[i].x = Math.sin(angle) * slideSize;
      curve.points[i].y = Math.cos(angle) * slideSize;

      if (i === pointAmount - 1) {
        curve.points[i].y -= 1.5;
      } else if (i === pointAmount - 2) {
        curve.points[i].y += 2;
      }
    }
  };

  const setPoints = (clock) => {
    const elapsedTime = clock.elapsedTime;

    const points = [];
    for (let i = 0; i < ringAmount; i++) {
      let pct = i / ringAmount + elapsedTime * 0.02;
      pct = 1 - (pct % 1);

      const pointOnCurve = curve.getPoint(pct);
      points.push(pointOnCurve);
    }
    setPositions(points);
  };

  const setTunnel = () => {
    const { geometry } = mesh.current;
    const { position } = geometry.attributes;

    for (let i = 0; i < position.count; i++) {
      const z = position.array[i * 3 + 2];
      const pct = z / -size;

      const pointOnCurve = curve.getPoint(pct);
      position.array[i * 3 + 0] = startPosition.array[i * 3 + 0] + pointOnCurve.x;
      position.array[i * 3 + 1] = startPosition.array[i * 3 + 1] + pointOnCurve.y;
    }

    position.needsUpdate = true;
  };

  /*
   * visuals
   */

  return (
    <>
      <mesh ref={mesh}>
        <tubeGeometry args={[curve, 100, 1.6, size, false]} />

        {/* <meshNormalMaterial side={THREE.DoubleSide} wireframe /> */}

        <meshStandardMaterial
          color={props.color}
          metalness={props.metalness}
          roughness={props.roughness}
          side={THREE.BackSide}
        />
      </mesh>

      {[...Array(ringAmount)].map((value, index) => (
        <Ring key={index} index={index} />
      ))}
    </>
  );
}
