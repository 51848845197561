import { useEffect } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';

export function RivAudioIcon({ on }) {
  const STATE_MACHINE_NAME = 'audio';
  const INPUT_NAME = 'on';

  const { rive, RiveComponent } = useRive({
    src: '/riv/manifesto.riv',
    artboard: STATE_MACHINE_NAME,
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const audioOn = useStateMachineInput(rive, STATE_MACHINE_NAME, INPUT_NAME);
  if (audioOn) {
    audioOn.value = on;
  }

  useEffect(() => {
    if (audioOn) {
      audioOn.value = on;
    }
  }, [on]);

  return (
    <>
      <RiveComponent />
    </>
  );
}
