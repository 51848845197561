import { create } from 'zustand';

export const useGrow = create((set, get) => ({
  growPct: 0,
  started: false,
  finished: false,

  reset: () => set({ growPct: 0, started: false, finished: false }),

  start: () => set({ started: true }),
  finish: () => set({ finished: true, growPct: 1 }),

  addKey: () => set({ growPct: (get().growPct += 1 / 27) }),
  addKonami: () => set({ growPct: (get().growPct += 1 / 10) }),
  setGrowPct: (pct) => set({ growPct: pct }),
}));
