import { Lighting } from './Lighting';
import { HoldController } from './HoldController';
import { Part2 } from './part2/Part2';
import { Content } from './Content';
import { CameraController } from './CameraController';
import { Part1 } from './part1/Part1';
import { SectionContainer } from '../../components/SectionContainer';

export function Holding() {
  /*
   * visuals
   */

  return (
    <>
      {/* <PerspectiveCamera position={[0, 0, 10]} makeDefault near={0.1} far={100} />
      <OrbitControls makeDefault dampingFactor={1} /> */}

      <HoldController />
      <CameraController />

      <Lighting />

      <SectionContainer>
        <Part1 />
        <Part2 />

        <Content />
      </SectionContainer>
    </>
  );
}
