import { Perf } from 'r3f-perf';
import { useEffect, useRef } from 'react';
import { useSection } from '../../stores/useSection';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import gsap from 'gsap';

export function Staging() {
  /*
   * properties
   */

  const { scene } = useThree();

  const transition = useSection((state) => state.transition);

  const colorRest = new THREE.Color('#222222');
  const colorTransition = new THREE.Color('#000000');

  const lerp = useRef(1);
  const isLerping = useRef(false);

  // const config = useControls('staging', {
  //   showPerf: { value: false, label: 'Perf panel' },
  // });

  const config = { showPerf: false };

  /*
   * hooks
   */

  // useEffect(() => {
  //   if (transition === 'hide') {
  //     isLerping.current = true;
  //     gsap.killTweensOf(lerp);
  //     gsap.to(lerp, { current: 1, duration: 0.75, ease: 'power1.out', onComplete: stopLerping });
  //   } else if (transition === 'show') {
  //     isLerping.current = true;
  //     gsap.killTweensOf(lerp);
  //     gsap.to(lerp, { current: 0, duration: 1.75, ease: 'sine.inOut', onComplete: stopLerping });
  //   }
  // }, [transition]);

  // const stopLerping = () => {
  //   isLerping.current = false;
  //   scene.background.lerpColors(colorRest, colorTransition, lerp.current);
  // };

  // useFrame((state) => {
  //   if (isLerping.current) {
  //     state.scene.background.lerpColors(colorRest, colorTransition, lerp.current);
  //   }
  // });

  // const showPerf = false;

  /*
   * visuals
   */

  return (
    <>
      <color attach="background" args={[colorRest]} />
      {config.showPerf && <Perf position="top-left" />}
    </>
  );
}
