import { useEffect, useRef } from 'react';
import { Blocks } from './Blocks';
import { CameraController } from './CameraController';
import { DragController } from './DragController';
import { Lighting } from './Lighting';

import gsap from 'gsap';
import { Content } from './Content';
import { useSection } from '../../../stores/useSection';
import { SectionContainer } from '../../components/SectionContainer';

export function Dragging() {
  // console.log('Dragging :: render');

  /*
   * properties
   */

  // const container = useRef();
  // const showSection = useSection((state) => state.showSection);
  // const transition = useSection((state) => state.transition);

  /*
   * hooks
   */

  // useEffect(() => {
  //   showSection();
  // }, []);

  // useEffect(() => {
  //   if (transition === 'show') {
  //     gsap.set(container.current.scale, { x: 0, y: 0, z: 0 });
  //     gsap.set(container.current.rotation, { x: -2, y: 0, z: 2 });

  //     gsap.to(container.current.scale, { x: 1, y: 1, z: 1, duration: 2, ease: 'sine.inOut' });
  //     gsap.to(container.current.rotation, { x: 0, y: 0, z: 0, delay: 0.5, duration: 2, ease: 'power2.out' });
  //   } else if (transition === 'hide') {
  //     gsap.to(container.current.scale, { x: 0, y: 0, z: 0, duration: 0.75, ease: 'sine.in' });
  //   }
  // }, [transition]);

  /*
   * visuals
   */

  return (
    <>
      <CameraController />
      <DragController />
      <Lighting />

      {/* <group ref={container}> */}
      <SectionContainer>
        <Content />
        <Blocks />
        {/* </group> */}
      </SectionContainer>
    </>
  );
}
