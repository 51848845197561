import { useDrag } from '../../../stores/useDrag';
import { OutroText } from '../../components/OutroText';

export function Text({ finished }) {
  // console.log('Dragging :: render');

  /*
   * visuals
   */

  return (
    <>
      <OutroText
        show={finished}
        section="growing"
        // text="transform projects LINE into living LINE breathing entities which thrive LINE and spread across the globe."
      />
    </>
  );
}
