import { useSection } from '../../../stores/useSection';
import { Interaction } from '../../main/Interaction';
import style from './Home.module.css';

export function Home() {
  const setSection = useSection((state) => state.setSection);

  const clickHandler = (section) => {
    // console.log('clickHandler', section);
    setSection(section);
  };
  return (
    <>
      <Interaction />
      {/* <div className={style.menu}>
        <a href="#" onClick={() => clickHandler('dragging')}>
          Dragging
        </a>
        <a href="#" onClick={() => clickHandler('scrolling')}>
          Scrolling
        </a>
        <a href="#" onClick={() => clickHandler('tapping')}>
          Tapping
        </a>
        <a href="#" onClick={() => clickHandler('holding')}>
          Holding
        </a>
        <a href="#" onClick={() => clickHandler('growing')}>
          Growing
        </a>
        <a href="#" onClick={() => clickHandler('manifesto')}>
          Manifesto
        </a>
        <a href="#" onClick={() => clickHandler('intro')}>
          Intro
        </a>
      </div> */}
    </>
  );
}
