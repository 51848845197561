import { useHold } from '../../../stores/useHold';
import { ProgressBar } from '../../components/ProgressBar';
import { CloseSection } from '../../main/CloseSection';
import { Text } from './Text';

export function Holding() {
  /*
   * properties
   */
  const holdPct = useHold((state) => state.holdPct);
  const started = useHold((state) => state.started);
  const part2Finished = useHold((state) => state.part2Finished);

  /*
   * visuals
   */

  return (
    <>
      <ProgressBar progress={holdPct} started={started} finished={part2Finished} />
      <CloseSection show={part2Finished} />
      <Text finished={part2Finished} />
    </>
  );
}
