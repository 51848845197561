import { useFrame } from '@react-three/fiber';
import { useScrolling } from '../../../stores/useScrolling';
import { useEffect, useRef } from 'react';
import { usePositions } from './usePositions';
import { useScroll } from '../../../stores/useScroll';
import { useSection } from '../../../stores/useSection';
import { usePost } from '../../../stores/usePost';
import { useSounds } from '../../../stores/useSounds';

export function ScrollController() {
  /*
   * properties
   */

  const reset = useScrolling((state) => state.reset);
  const start = useScrolling((state) => state.start);
  const finish = useScrolling((state) => state.finish);
  const setScroll = useScrolling((state) => state.setScroll);
  const setPulse = usePositions((state) => state.setPulse);
  const concealSection = useSection((state) => state.concealSection);
  const transition = useSection((state) => state.transition);
  const setPost = usePost((state) => state.setPost);

  const getSound = useSounds((state) => state.getSound);
  const playSound = useSounds((state) => state.playSound);
  const sound = getSound('ScrollingLoop');

  const enableScroll = useScroll((state) => state.enableScroll);
  const disableScroll = useScroll((state) => state.disableScroll);
  const pct = useScroll((state) => state.pct);
  const oldPct = useRef(0);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('ScrollController :: onMount');

    sound.play();
    sound.volume(0);

    const timeoutId = setTimeout(() => {
      start();
      enableScroll();
    }, 3000);

    return () => {
      // console.log('ScrollController :: onDestroy');
      sound.stop();
      reset();
      clearTimeout(timeoutId);
    };
  }, []);

  useFrame((state, delta) => {
    // console.log('ScrollController :: change offset');
    // console.log(data.offset)

    if (useScrolling.getState().started) {
      calculatePulse(delta);

      if (!useScrolling.getState().finished) {
        calculateScroll();
      }
    }
  });

  const calculatePulse = (delta) => {
    const pulsePct = usePositions.getState().pulsePct;
    // console.log(pulsePct);

    if (pulsePct < 1.3) {
      setPulse(pulsePct + delta * 0.35);
    } else {
      setPulse(-0.3);
    }
  };

  const calculateScroll = () => {
    // console.log('calculateScroll, setScroll', pct)
    setScroll(Math.min(1, pct));
    setPost(Math.min(1, pct));

    const delta = pct - oldPct.current;
    oldPct.current = pct;

    if (delta != 0) {
      const volume = Math.max(0, Math.min(1, delta * 150));
      sound.volume(volume);
    }

    if (pct >= 1) {
      // console.log('FINISH');

      sound.fade(1, 0, 300);

      disableScroll();
      finish();
      concealSection();
    }
  };

  useEffect(() => {
    if (transition === 'hide') {
      disableScroll();
    }
  }, [transition]);

  return <></>;
}
