import { useEffect, useState } from 'react';
import style from './DelayedContainer.module.css';

export function DelayedContainer({ delay, children }) {
  /*
   * properies
   */

  const [show, setShow] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('delay: ' + delay);
    const timeoutId = setTimeout(() => {
      // console.log('delayed');
      setShow(true);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay]);

  /*
   * visuals
   */

  return (
    <>
      <div className={style.container}>{show && children}</div>
    </>
  );
}
