import { useEffect, useRef, useState } from 'react';
import style from './Manifesto.module.css';
import { RoundButton } from '../../components/RoundButton';
import { useSection } from '../../../stores/useSection';
import gsap from 'gsap';
import { OutroText } from '../../components/OutroText';
import { BigLink } from '../../components/BigLink';
import { useManifesto } from '../../../stores/useManifesto';
import { Menu } from './Menu';
import { CloseSection } from '../../main/CloseSection';
import { ButtonClose } from '../../components/buttons/ButtonClose';
import { useSize } from '../../../stores/useSize';
import { MobileButtons } from './MobileButtons';

export function Manifesto() {
  /*
   * properies
   */

  // const [showCloseSection, setShowCloseSection] = useState(true);
  const [showText, setShowText] = useState(false);
  const [textSection, setTextSection] = useState('intro');
  const [textNum, setTextNum] = useState(0);
  const isMobile = useSize((state) => state.isMobile);
  const timeoutId = useRef();
  const footer = useRef();
  const title = useRef();

  const section = useManifesto((state) => state.section);
  const setManifestoSection = useManifesto((state) => state.setSection);
  // const transition = useSection((state) => state.transition);

  const mobileMenuShown = useManifesto((state) => state.mobileMenuShown);

  /*
   * hooks
   */

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  useEffect(() => {
    gsap.killTweensOf(footer.current);
    gsap.set(footer.current, { opacity: 0 });
    gsap.to(footer.current, { delay: 0.5, duration: 0.5, opacity: 1, ease: 'power2.in' });
  }, [footer.current]);

  useEffect(() => {
    // console.log('HIDE TEXT');

    setShowText(false);
    setTextNum(useManifesto.getState().num);

    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      // console.log('SHOW TEXT');

      setTextSection(section);
      setShowText(true);
    }, 500);
  }, [section]);

  const closeSection = () => {
    setShowText(false);
    // setSection('home');
  };

  const closeText = () => {
    setManifestoSection('intro', 0);
  };

  /*
   * visuals
   */

  return (
    <>
      <CloseSection show={true} onClick={closeSection} inverted={true} />

      <div className={`${style.textClose} ${textNum != 0 && !isMobile ? style.isActive : ''}`}>
        {textNum != 0 && <ButtonClose small onClick={closeText} ariaLabel="close" />}
      </div>

      <OutroText show={showText} section={textSection} delay={0} isManifesto={true} num={textNum} />

      <MobileButtons />

      <Menu />

      {/* {!isMobile ||
        (isMobile && !mobileMenuShown && ( */}
      <footer className={style.footer} ref={footer}>
        <p ref={title} className={style.title}>
          we are <img className={style.logo} src="/images/logo.svg" alt="logo" />
        </p>
        <div className={style.menu}>
          <BigLink footer href="https://www.itsoffbrand.com/contact">
            EMAIL US
          </BigLink>
          <BigLink footer href="https://www.itsoffbrand.com/">
            VISIT WEBSITE
          </BigLink>
          <BigLink footer href="https://x.com/itsoffbrand">
            FOLLOW US
          </BigLink>
        </div>
      </footer>
      {/* ))} */}
    </>
  );
}
