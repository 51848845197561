import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import gsap from 'gsap';

import { Outer } from './Outer';
import { Inner } from './Inner';
import { useHold } from '../../../../stores/useHold';

export function Part1() {
  /*
   * properties
   */

  const container = useRef();

  const part1Finished = useHold((state) => state.part1Finished);
  const finishPart2 = useHold((state) => state.finishPart2);

  /*
   * hooks
   */

  useEffect(() => {
    if (part1Finished) {
      const scale = 1.5;

      const tl = gsap.timeline();
      tl.to(container.current.scale, { x: scale, y: scale, z: scale, duration: 0.9, ease: 'power4.inOut' });
      tl.to(container.current.scale, { x: 0, y: 0, z: 0, duration: 0.6, ease: 'power4.inOut' });

      gsap.to(container.current.rotation, { duration: 1.2, z: 2, y: 6, onComplete: finishPart2, ease: 'sine.in' });
    }
  }, [part1Finished]);

  /*
   * visuals
   */

  return (
    <>
      <group ref={container}>
        <Inner />
        <Outer />
      </group>
    </>
  );
}
