import { create } from 'zustand';

export const usePositions = create((set) => ({
  positions: [],
  pulsePct: 0,

  reset: () => set({ pulsePct: 0 }),
  setPositions: (positions) => set({ positions }),
  setPulse: (pulsePct) => set({ pulsePct }),
}));
