import { useEffect, useRef } from 'react';
import { Blocks } from './Blocks';
import { CameraController } from './CameraController';
import { DragController } from './DragController';
import { Lighting } from './Lighting';

import gsap from 'gsap';
import { Text } from './Text';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';

export function Show() {
  // console.log('Dragging :: render');

  /*
   * properties
   */

  const container = useRef();

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <CameraController />
      {/* <DragController /> */}
      <Lighting />
      {/* <Text /> */}
      <group ref={container}>
        <Blocks />
      </group>

      {/* <PerspectiveCamera makeDefault position={[0, 0, 10]} near={0.1} far={100} />
      <OrbitControls makeDefault dampingFactor={1} /> */}
    </>
  );
}
