import { useEffect } from 'react';
import style from './RivSpiral.module.css';

import { useRive, useStateMachineInput } from 'rive-react';

export function RivSpiral() {
  const STATE_MACHINE_NAME = 'spiral';
  const INPUT_NAME = 'end';

  const { rive, RiveComponent } = useRive({
    src: '/riv/spiral-wild.riv',
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const toEnd = useStateMachineInput(rive, STATE_MACHINE_NAME, INPUT_NAME);

  let timeoutId;

  useEffect(() => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      toEnd.fire();
    }, 7200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [toEnd]);

  return (
    <>
      <div className={style.container}>
        <RiveComponent />
      </div>
    </>
  );
}
