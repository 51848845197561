import { useEffect } from 'react';
import { useSize } from '../stores/useSize';

export function SizeController() {
  /*
   * properties
   */

  const { setMobile } = useSize();

  /*
   * resize functionality
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, []);

  const resizeHandler = () => {
    document.documentElement.style.setProperty('--vw-scale', Math.max(1, window.innerWidth / 1512));
    setMobile(window.innerWidth < 700);
  };
}
