import { Tunnel } from './Tunnel';
import { Lights } from './Lights';
import { ScrollController } from './ScrollController';
import { CameraController } from './CameraController';
import { Content } from './Content';
import { SectionContainer } from '../../components/SectionContainer';

export function Scrolling() {
  /*
   * properties
   */

  /*
   * visuals
   */

  return (
    <>
      <ScrollController />
      <CameraController />

      <SectionContainer>
        <Content />
        <Lights />
        <Tunnel />
      </SectionContainer>
    </>
  );
}
