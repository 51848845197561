import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSection } from '../../stores/useSection';
import { useSize } from '../../stores/useSize';
import { RoundButton } from '../components/RoundButton';
import style from './Mouse.module.css';
import { usePieces } from '../../stores/usePieces';
import { IntroTextContent } from '../../app3D/components/IntroTextContent';
import gsap from 'gsap';
import { useFrame } from '@react-three/fiber';
import { useMouse } from '../../stores/useMouse';
import { useClues } from '../../stores/useClues';
import { useInteraction } from '../../stores/useInteraction';

export function Mouse() {
  /*
   * properies
   */

  const [shown, setShown] = useState(false);

  const container = useRef();
  const hasInteracted = useRef(false);
  const inner = useRef();
  const section = useSection((state) => state.section);
  const transition = useSection((state) => state.transition);
  const interacting = useInteraction((state) => state.interacting);

  const cluesHovered = useClues((state) => state.hovered);
  const cluesShown = useClues((state) => state.shown);

  /*
   * hooks
   */

  useEffect(() => {
    loop();
  }, []);

  const loop = () => {
    if (isMobile) {
      gsap.set(container.current, { x: window.innerWidth * 0.5, y: window.innerHeight * 0.5 });
    } else if (useMouse.getState().inited) {
      const x = useMouse.getState().slowX;
      const y = useMouse.getState().slowY;

      gsap.set(container.current, { x: x + 2, y: y + 7 });
    }

    requestAnimationFrame(loop);
  };

  useEffect(() => {
    // console.log('interacting');
    // console.log(interacting);
    if (interacting) {
      hasInteracted.current = true;
    }
  }, [interacting]);

  useEffect(() => {
    if (section === 'home' && transition === 'show') {
      if (isMobile && !hasInteracted.current) {
        show();
      } else if (!isMobile) {
        show();
      } else {
        hide();
      }
    } else if (section === 'home' && transition === 'hide') {
      hide();
    }
  }, [section, transition]);

  const show = () => {
    // gsap.to(container.current, { duration: 0.3, scale: 1, ease: 'power1.out' });
    setShown(true);
  };

  const hide = () => {
    // gsap.to(container.current, { duration: 0.3, scale: .9, ease: 'power1.out' });
    setShown(false);
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={`${style.mouse} ${shown && !cluesShown && !cluesHovered ? style.shown : ''}`}>
        <div ref={inner} className={`${style.inner} ${interacting || !shown ? style.isActive : ''}`}>
          <img className={style.image} alt="interact" src="./images/mouse.webp" />
        </div>
      </div>
    </>
  );
}
