import { create } from 'zustand';

export const useClues = create((set, get) => ({
  shown: false,
  hovered: false,

  show: () => set({ shown: true }),
  hide: () => set({ shown: false }),

  over: () => set({ hovered: true }),
  out: () => set({ hovered: false }),
}));
