import { create } from 'zustand';

export const useScroll = create((set, get) => ({
  pct: 0,
  // slowPct: 0,
  enabled: false,

  enableScroll: () => set({ pct: 0, enabled: true }),
  disableScroll: () => set({ pct: 0, enabled: false }),

  setPct: (pct) => set({ pct }),
  // setSlowPct: (slowPct) => set({ slowPct }),
}));
