import { useEffect, useRef, useState } from 'react';
import style from './ProgressBar.module.css';
import gsap from 'gsap';
import { useSection } from '../../stores/useSection';
import { usePieces } from '../../stores/usePieces';
import { useSounds } from '../../stores/useSounds';

export function ProgressBar({ progress, started, finished }) {
  /*
   * properties
   */

  const container = useRef();
  const button = useRef();
  const label = useRef('');
  const [ended, setEnded] = useState(false);

  const playSound = useSounds((state) => state.playSound);

  const setSection = useSection((state) => state.setSection);
  const transition = useSection((state) => state.transition);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('PogressBar :: onMount');
    if (usePieces.getState().foundAllPieces()) {
      label.current = 'VIEW MANIFESTO';
    } else {
      label.current = 'CONTINUE SEARCH';
    }

    gsap.set(container.current, { opacity: 0 });
  }, []);

  useEffect(() => {
    if (progress > 0 && finished) {
      // console.log('PogressBar :: show continue button');

      gsap.killTweensOf(button.current.children);
      gsap.to(button.current.children, {
        delay: 6,
        onStart: () => {
          setEnded(true);
        },
        opacity: 1,
        stagger: 0.1,
        duration: 1.2,
        ease: 'power1.inOut',
      });
    }
  }, [finished]);

  useEffect(() => {
    if (started) {
      // console.log('PogressBar :: start');
      gsap.killTweensOf(button.current.children);
      gsap.set(button.current.children, { opacity: 0 });
      gsap.to(container.current, { opacity: 1, duration: 0.5, ease: 'power1.inOut' });
    }
  }, [started]);

  useEffect(() => {
    if (transition === 'hide') {
      gsap.to(container.current, { alpha: 0, duration: 0.4, ease: 'power4.in' });
    }
  }, [transition]);

  const clickHandler = () => {
    // console.log('ProgressBar :: clickHandler');

    playSound('ClickToContinue');

    if (usePieces.getState().foundAllPieces()) {
      setSection('manifesto');
    } else {
      setSection('home');
    }
  };

  /*
   * visuals
   */

  return (
    <>
      {
        <div ref={container} className={`${style.container} ${ended ? style.finished : ''}`}>
          <div className={style.bar}>
            <div className={style.fill} style={{ width: (finished ? 100 : progress * 100) + '%' }} />
          </div>
          <img alt="arrow head" className={style.arrowHead} src="/images/arrowHead.svg" />
          <button ref={button} className={style.cta} onClick={clickHandler}>
            {/* {'CONTINUE SEARCH'.split('').map((value, index) => (
              <span key={index}>{value}</span>
            ))} */}

            {label.current.split('').map((value, index) => (
              <span key={index}>{value}</span>
            ))}
          </button>
        </div>
      }
    </>
  );
}
