import { Instance } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { useGrow } from '../../../stores/useGrow';

export function BranchItem({ pct, branchTimeExtra }) {
  /*
   * properties
   */

  const instance = useRef();

  const yBase = useRef(0);
  const scaleBase = useRef(0);
  const showScale = useRef(0);
  const distance = useRef(10);
  const localTimeExtra = useRef(Math.random() * 6);
  const lerpSpeed = useRef(0.005 + Math.random() * 0.05);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('Branch :: onMount');

    const pctPow = Math.pow(pct, 0.8);

    const x = -0.12 + pctPow * 2;
    instance.current.position.x = x;

    yBase.current = Math.sin(pct * 3.14) * 0.4;
    scaleBase.current = 0.5 + (1 - pctPow) * 0.5;

    setTimeout(calculateDistance, 100);
  }, []);

  const calculateDistance = () => {
    const worldPosition = new Vector3();
    instance.current.getWorldPosition(worldPosition);
    distance.current = worldPosition.distanceTo(new Vector3());
    // console.log(distance.current);
    // 3.6515152611672095
  };

  useFrame((state, delta) => {
    const time = state.clock.elapsedTime;

    const y = yBase.current * Math.sin(time * 1.2 + branchTimeExtra);
    instance.current.position.y = y;

    let scale = scaleBase.current + Math.sin(time * 1.5 + localTimeExtra.current) * 0.1;
    instance.current.scale.set(scale, scale, scale);

    const pct = useGrow.getState().growPct;
   
    const endScale = Math.pow(pct, 1.2) > distance.current || pct == 1 ? 1 : 0;
    showScale.current += (endScale - showScale.current) * lerpSpeed.current;

    scale *= showScale.current;
    instance.current.scale.set(scale, scale, scale);

    // console.log(useGrow.getState().tapPct);

    // console.log(distance.current);
  });

  /*
   * visuals
   */

  return <Instance ref={instance} />;
}
