import { useEffect, useRef } from 'react';
import { Logo } from './Logo';
import { useSection } from '../../../stores/useSection';
import gsap from 'gsap';
import { Copy } from './Copy';

export function Intro() {
  /*
   * properties
   */

  const group = useRef();
  const showSection = useSection((state) => state.showSection);
  const transition = useSection((state) => state.transition);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('Home :: onMount');
    showSection();
  }, []);

  //show/hide
  useEffect(() => {
    if (transition === 'show') {
      // gsap.set(group.current.scale, { duration: 0, x: 0, y: 0, z: 0 });
      // gsap.to(group.current.scale, { duration: 2, x: 1, y: 1, z: 1, ease: 'sine.out' });
    } else if (transition === 'hide') {
      gsap.set(group.current.scale, { x: 1, y: 1, z: 1 });
      gsap.to(group.current.scale, { duration: 0.4, x: 0, y: 0, z: 0, ease: 'power4.in' });
    }
  }, [transition]);

  return (
    <>
      <group ref={group}>
        <Copy />
        <Logo />
      </group>
    </>
  );
}
