import { useEffect, useRef, useState } from 'react';
import { Flower } from './Flower';
import { TapController } from './TapController';
import { Lighting } from './Lighting';
import { useSection } from '../../../stores/useSection';
import gsap from 'gsap';
import { Content } from './Content';
import { CameraController } from './CameraController';
import { SectionContainer } from '../../components/SectionContainer';

export function Tapping() {
  /*
   * visuals
   */

  return (
    <>
      {/* <PerspectiveCamera position={[0, 0, 10]} makeDefault near={0.1} far={100} />
      <OrbitControls makeDefault dampingFactor={1} /> */}

      <TapController />
      <CameraController />

      <SectionContainer>
        <Content />
        <Lighting />
        <Flower />
      </SectionContainer>
    </>
  );
}
