import { useEffect, useRef } from 'react';
import * as THREE from 'three';

import { Instance, Instances, PerspectiveCamera, shaderMaterial, useGLTF } from '@react-three/drei';
import { extend, useFrame, useThree } from '@react-three/fiber';
import vertexShader from './shaders/dot/vertex.glsl?raw';
import fragmentShader from './shaders/dot/fragment.glsl?raw';
import { useMouse } from '../../../stores/useMouse';
import gsap from 'gsap';
import { useSection } from '../../../stores/useSection';

const DotMaterial = shaderMaterial(
  {
    uTime: 0,
  },
  vertexShader,
  fragmentShader
);

extend({ DotMaterial });

let mouse = { x: useMouse.getState().x, y: useMouse.getState().y };
let distortion = 0;

export function Dot() {
  /*
   * properties
   */

  const material = useRef();
  const group = useRef();
  const mesh = useRef();

  const transition = useSection((state) => state.transition);
  const section = useSection((state) => state.section);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    const ratio = window.innerWidth / window.innerHeight;
    // console.log(ratio);

    let extra = 1 - ratio - 0.3;
    const scale = 1 + Math.max(0, extra * 10);

    if (mesh) {
      mesh.current.scale.set(scale, scale, scale);
    }
  };

  useFrame((state, delta) => {
    if (section === 'home' || section === 'intro') {
      const x = useMouse.getState().x;
      const y = useMouse.getState().y;

      const dist = Math.sqrt((x - mouse.x) ** 2 + (y - mouse.y) ** 2);

      if (dist > distortion) {
        distortion = dist;
      } else {
        distortion *= 0.98;
      }

      const distortionPct = Math.min(1, distortion * 0.05);

      material.current.uniforms.uTime.value += delta + distortionPct * 0.05;

      mouse.x = x;
      mouse.y = y;
    }
  });

  useEffect(() => {
    // const section = useSection.getState().section;

    // console.log(transition, ' - ', section);

    if (transition === 'show') {
      if (section === 'intro') {
        // console.log('show - intro');
        // gsap.set(group.current.scale, { duration: 0, x: 0, y: 0, z: 0 });
        gsap.to(group.current.scale, { duration: 3.5, delay: 8.5, x: 0.5, y: 0.5, z: 0.5, ease: 'power4.inOut' });
      } else if (section === 'home') {
        // console.log('show - home');
        // gsap.set(group.current.scale, { duration: 0, x: 0, y: 0, z: 0 });
        gsap.to(group.current.scale, { duration: 1, x: 1, y: 1, z: 1, ease: 'power1.inOut' });
      }
    } else if (transition === 'hide' && section === 'home') {
      // console.log("hide - home");
      gsap.set(group.current.scale, { x: 1, y: 1, z: 1 });
      gsap.to(group.current.scale, { duration: 0.45, x: 0, y: 0, z: 0, ease: 'sine.in' });
    }
  }, [transition]);

  /*
   * visuals
   */

  return (
    <>
      <group ref={group} scale={[0, 0, 0]} visible={section === 'home' || section === 'intro'}>
        <mesh ref={mesh}>
          <planeGeometry args={[6, 6]} />
          <dotMaterial ref={material} transparent blending={THREE.AdditiveBlending} />
          {/* <meshBasicMaterial color={'red'} /> */}
        </mesh>
      </group>
    </>
  );
}
