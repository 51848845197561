import { useFrame } from '@react-three/fiber';
import style from './ScrollController.module.css';
import { useEffect, useRef } from 'react';
import { useScroll } from '../stores/useScroll';

let autoScrollSpeed = 0;
let autoScroll = false;

let pct = 0;
let endPct = 0;

export function ScrollController() {
  /*
   * properties
   */

  const container = useRef();
  const enabled = useScroll((state) => state.enabled);
  const setPct = useScroll((state) => state.setPct);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('ScrollController :: onMount');

    const intervalId = setInterval(loop, 30);

    return () => {
      // console.log('ScrollController :: onDestroy');
      clearInterval(intervalId);
    };
  }, []);

  const scrollHandler = (event) => {
    // console.log('scrollHandler');

    // detect if user has scrolled
    if (!autoScroll) {
      autoScrollSpeed = 0.1;
    }

    const element = container.current;
    endPct = element.scrollTop / (element.scrollHeight - element.clientHeight);
    // console.log('endPct', endPct);
    // const pct = element.scrollTop / (element.scrollHeight - element.clientHeight);
    // setPct(pct);

    autoScroll = false;
  };

  useEffect(() => {
    if (enabled) {
      container.current.scrollTop = 0;
    }
  }, [enabled]);

  const loop = () => {
    if (useScroll.getState().enabled) {
      autoScrollSpeed *= 1.2;
      autoScrollSpeed = Math.min(autoScrollSpeed, 100);

      // console.log(autoScrollSpeed);

      if (autoScrollSpeed > 1) {
        container.current.scrollTop -= autoScrollSpeed;
        autoScroll = true;

        if (container.current.scrollTop < 0) {
          container.current.scrollTop = 0;
        }
      }
    }

    // console.log('endPct', endPct);
    pct += (endPct - pct) * 0.3;

    if (pct > 0.99) {
      pct = 1;
    }

    // console.log('pct', pct);

    setPct(pct);
  };

  /*
   * visuals
   */

  return (
    <div
      ref={container}
      className={`${style.scrollContainer} ${enabled ? style.enabled : ''}`}
      onScroll={scrollHandler}
    >
      <div className={style.child} />
    </div>
  );
}
