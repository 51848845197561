import { create } from 'zustand';

export const useScrolling = create((set) => ({
  scrollPct: 0,
  started: false,
  finished: false,

  reset: () => set({ scrollPct: 0, started: false, finished: false }),

  start: () => set({ started: true }),
  finish: () => set({ finished: true }),

  setScroll: (scrollPct) => set({ scrollPct }),
}));
