import { useEffect, useRef, useState } from 'react';

import { IntroText } from '../../components/IntroText';
import { ScrollPiece } from '../../components/ScrollPiece';
import { OutroCopy } from '../../components/OutroCopy';
import { useFrame } from '@react-three/fiber';
import { useScrolling } from '../../../stores/useScrolling';
import { Divider } from '../../components/Divider';

export function Content() {
  // console.log('Text :: render');

  /*
   * properties
   */

  const [showIntro, setShowIntro] = useState(true);
  const [showPiece, setShowPiece] = useState(false);

  /*
   * hooks
   */

  useFrame(() => {
    // intro text
    if (useScrolling.getState().scrollPct > 0.2 && showIntro) {
      setShowIntro(false);
    }

    // console.log('scrollPct', useScrolling.getState().scrollPct);

    // scroll piece
    if (useScrolling.getState().scrollPct === 1 && !showPiece) {
      // console.log('SHOW PIECE');
      setShowPiece(true);
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <Divider />
      <group position={[0, 0, -30]}>
        <IntroText line1="SCROL" line2="LING" show={showIntro} />
      </group>
      {/* <group position={[0, 0, -4]} rotation={[0, 0, 0]}>
        <Divider show={showPiece} />
      </group> */}
      <group position={[0, 0, -3]} rotation={[0, 0, 0]}>
        <ScrollPiece show={showPiece} textureName="scrolling" />
        <OutroCopy show={showPiece} section="scrolling" />
      </group>
    </>
  );
}
