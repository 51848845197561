import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';

import { shaderMaterial } from '@react-three/drei';
import { extend, useFrame, useThree } from '@react-three/fiber';
import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';
import { useHold } from '../../../../stores/useHold';

const OuterMaterial = shaderMaterial(
  {
    uFrequency: new THREE.Vector2(1, 1),
    size: 0,
    uTime: 0,
    uSize: 0.1,
    uWaveTime: 0,
    uColorTime: 0,
    uOpacity: 1,
  },
  vertexShader,
  fragmentShader
);

extend({ OuterMaterial });

export function Outer() {
  /*
   * properties
   */

  const material = useRef();
  const mesh = useRef();

  // const props = useControls('outer material', {
  //   frequencyX: { value: 4, min: 0, max: 20, step: 0.01 },
  //   frequencyY: { value: 3, min: 0, max: 20, step: 0.01 },
  //   size: { value: 0.12, min: 0, max: 0.5, step: 0.001 },
  //   waveSpeed: { value: 3.5, min: 0, max: 10, step: 0.01 },
  //   colorSpeed: { value: 0, min: 0, max: 0.3, step: 0.001 },
  //   uOpacity: { value: 0.3, min: 0, max: 1, step: 0.01 },
  // });

  const props = {
    frequencyX: 4,
    frequencyY: 3,
    size: 0.12,
    waveSpeed: 3.5,
    colorSpeed: 0,
    uOpacity: 0.3,
  };

  /*
   * hooks
   */

  useFrame((state, delta) => {
    material.current.uniforms.uTime.value += delta;
    // material.current.uniforms.uColorTime.value += delta * props.colorSpeed;
    // material.current.uniforms.uWaveTime.value += delta * props.waveSpeed;
    // material.current.uniforms.uOpacity.value = props.uOpacity;

    let pct = useHold.getState().holdPct;
    // pct = Math.min(1, pct * 2);

    material.current.uniforms.uSize.value = 0.12 + pct * 0.51;
    material.current.uniforms.uWaveTime.value += delta * (3.5 + 10 * pct);
    material.current.uniforms.uColorTime.value += delta * (0.05 + 0.3 * pct);
    material.current.uniforms.uOpacity.value = (1 - pct) * 0.2;

    let scale = 1 - pct * 0.6;
    scale = Math.pow(scale, 0.9);
    mesh.current.scale.set(scale, scale, scale);
  });

  /*
   * visuals
   */

  return (
    <>
      <mesh ref={mesh}>
        <icosahedronGeometry args={[2.4, 11]} />

        <outerMaterial
          // depthTest={false}
          depthWrite={false}
          ref={material}
          side={THREE.DoubleSide}
          transparent
          uTime={0}
          uSize={props.size}
          uFrequency={[props.frequencyX, props.frequencyY]}
        />
      </mesh>
    </>
  );
}
