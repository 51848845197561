import style from './EndText.module.css';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { useSection } from '../../../stores/useSection';
import { useSounds } from '../../../stores/useSounds';

export function EndText() {
  /*
   * properties
   */

  const paragraph = useRef();
  const isReady = useRef(false);

  const playSound = useSounds((state) => state.playSound);

  const setSection = useSection((state) => state.setSection);

  /*
   * hooks
   */

  useEffect(() => {
    // show

    gsap.killTweensOf(paragraph.current);
    gsap.to(paragraph.current, {
      delay: 10,
      duration: 3,
      opacity: 1,
      ease: 'power1.out',
      onStart: end,
    });

    // console.log('onMount');
    window.addEventListener('click', globalClickHandler);

    return () => {
      //   console.log('onDestroy');
      window.removeEventListener('click', globalClickHandler);
    };
  }, []);

  const end = () => {
    isReady.current = true;
  };

  const globalClickHandler = () => {
    // console.log('globalClickHandler');
    // console.log(isReady.current);


    if (isReady.current) {
      // console.log('kaka');
      playSound('GeneralUIClick');
      // playSound('MainAmbienceLoop');

      setSection('home');
      window.removeEventListener('click', globalClickHandler);

      gsap.killTweensOf(paragraph.current);
      gsap.to(paragraph.current, {
        duration: 0.6,
        opacity: 0,
        ease: 'sine.in',
      });
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <p ref={paragraph} className={style.end}>
        TAP TO START DISCOVERING
      </p>
    </>
  );
}
