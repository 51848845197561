import { Html, Text, shaderMaterial, useTexture } from '@react-three/drei';
import { extend, useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';
import { useSection } from '../../../stores/useSection';
import * as THREE from 'three';

const LogoMaterial = shaderMaterial(
  {
    uTime: 0,
    uAmount: 0,
    uTexture: null,
  },
  vertexShader,
  fragmentShader
);

extend({ LogoMaterial });

export function Logo() {
  /*
   * properties
   */

  const texture = useTexture('/textures/logo-min.png');
  const material = useRef();
  // const setSection = useSection((state) => state.setSection);

  /*
   * hooks
   */

  useEffect(() => {
    // show
    const delay = 7.2;
    const duration = 4.3;
    gsap.to(material.current.uniforms.uAmount, {
      delay,
      duration,
      value: 1,
      ease: 'power1.out',
      // onComplete: end,
    });
  }, []);

  // const end = () => {
  //   setSection('home');
  // };

  useFrame((state, delta) => {
    material.current.uniforms.uTime.value += delta;
  });

  /*
   * visuals
   */

  return (
    <>
      <mesh position={[-0.175, 0.2, -1.5]}>
        <planeGeometry args={[6.3, 0.8]} />
        <logoMaterial uTexture={texture} ref={material} blending={THREE.AdditiveBlending} />
      </mesh>
    </>
  );
}
