import { useEffect } from 'react';
import { useTap } from '../../../stores/useTap';
import { useSection } from '../../../stores/useSection';
import { usePost } from '../../../stores/usePost';
import { useFrame } from '@react-three/fiber';
import { useSounds } from '../../../stores/useSounds';

let autoScrollSpeed = 0;
let autoScroll = false;

export function TapController() {
  /*
   * properties
   */
  const tap = useTap((state) => state.tap);
  const setTapPct = useTap((state) => state.setTapPct);
  const reset = useTap((state) => state.reset);
  const start = useTap((state) => state.start);
  const finish = useTap((state) => state.finish);
  const concealSection = useSection((state) => state.concealSection);
  const setPost = usePost((state) => state.setPost);
  const finishPost = usePost((state) => state.setPost);

  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('ScrollController :: onMount');

    const timeoutId = setTimeout(() => {
      // console.log('START');
      start();
      window.addEventListener('click', clickHandler);
    }, 3000);

    return () => {
      reset();
      clearTimeout(timeoutId);
      window.removeEventListener('click', clickHandler);
    };
  }, []);

  // useEffect(() => {
  //   console.log(tapPct);
  // }, [tapPct])

  const clickHandler = () => {
    if (!useTap.getState().finished) {
      if (!autoScroll) {
        autoScrollSpeed = 0.1;
      }

      playSound('Tapping0' + Math.ceil(Math.random() * 4));

      tap();
      const pct = Math.max(0, Math.min(1, useTap.getState().tapPct));
      // console.log(pct);
      setPost(pct);

      autoScroll = false;

      if (pct >= 1) {
        // console.log('TapController :: FINISH');
        finish();
        finishPost();
        concealSection();
      }
    }
  };

  useFrame((state, delta) => {
    let tapPct = useTap.getState().tapPct;

    if (!useTap.getState().finished) {
      // console.log(autoScrollSpeed);
      autoScrollSpeed *= 1.2;
      autoScrollSpeed = Math.min(autoScrollSpeed, 100);

      if (autoScrollSpeed > 1) {
        tapPct -= autoScrollSpeed * delta * 0.002;
        tapPct = Math.max(0, tapPct);
        autoScroll = true;

        setTapPct(tapPct);
        setPost(tapPct);
      }
    }
  });
}
