import { BlendFunction, Effect } from 'postprocessing';
import { Uniform } from 'three';
import fragmentShader from './noise.glsl?raw';

export default class NoiseEffect extends Effect {
  constructor({ strength = 1, blendFunction = BlendFunction.NORMAL }) {
    super('NoiseEffect', fragmentShader, {
      blendFunction,
      uniforms: new Map([
        ['uStrength', new Uniform(strength)],
        ['uTime', new Uniform(0)],
      ]),
    });
  }

  update(renderer, inputBuffer, deltaTime) {
    this.uniforms.get('uTime').value += deltaTime;
  }
}
