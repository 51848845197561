import { create } from 'zustand';

export const useSection = create((set, get) => ({
  section: 'intro',
  // section: 'home',

  // section: 'dragging',
  // section: 'scrolling',
  // section: 'growing',
  // section: 'tapping',
  // section: 'holding',

  // section: 'manifesto',

  // section: 'test',
  // section: 'ui',
  // section: 'show',

  newSection: '',
  transition: '',
  transitioning: false,
  concealed: false,

  setSection: (section) => set({ newSection: section, transition: 'hide', transitioning: true, concealed: false }),
  loadSection: () => set({ section: get().newSection, transition: 'loading' }),
  showSection: () => set({ transition: 'show' }),
  concealSection: () => set({ concealed: true }),
  endTransition: () => set({ transitioning: false }),
}));
