import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../stores/useSection';
import { useSize } from '../../stores/useSize';
import style from './Clues.module.css';
import { CluesButton } from './clues/CluesButton';
import { CluesMenu } from './clues/CluesMenu';
import { useClues } from '../../stores/useClues';

export function Clues() {
  /*
   * properties
   */

  const container = useRef();

  const shown = useClues((state) => state.shown);
  const show = useClues((state) => state.show);
  const hide = useClues((state) => state.hide);

  const over = useClues((state) => state.over);
  const out = useClues((state) => state.out);

  const transition = useSection((state) => state.transition);
  const isMobile = useSize((state) => state.isMobile);

  /*
   * hooks
   */

  useEffect(() => {
    if (transition === 'show') {
      const section = useSection.getState().section;

      if (section === 'home') {
        container.current.style.opacity = 1;
        container.current.style.pointerEvents = 'all';
      } else {
        container.current.style.opacity = 0;
        container.current.style.pointerEvents = 'none';
      }
    }
  }, [isMobile, transition]);

  /*
   * visuals
   */

  return (
    <>
      <aside ref={container} className={style.clues} onMouseEnter={over} onMouseLeave={out}>
        <CluesMenu show={shown} onClick={hide} />
        <CluesButton show={!shown} onHover={show} />
      </aside>
    </>
  );
}
