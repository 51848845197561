import { Howl, Howler } from 'howler';
import { useEffect, useRef } from 'react';
import { useSection } from '../stores/useSection';
// import { useSounds } from '../stores/useSounds';

export function Sound() {
  /*
   * properties
   */

  const section = useSection((state) => state.section);
  // const activate = useSounds((state) => state.activate);

  const activeSound = useRef();
  const timeoutId = useRef();

  const music = {
    home: 'home.mp3',
    dragging: 'dragging.mp3',
    growing: 'growing.mp3',
    holding: 'holding.mp3',
    scrolling: 'scrolling.mp3',
    tapping: 'tapping.mp3',
  };

  /*
   * hooks
   */

  useEffect(() => {
    document.addEventListener('visibilitychange', tabSwitch);

    return () => {
      document.removeEventListener('visibilitychange', tabSwitch);
    };
  });

  const tabSwitch = () => {
    if (document.visibilityState === 'visible') {
      // console.log('tab visible');
      // activate(true);
      Howler.mute(false);
    } else {
      // console.log('tab invisible');
      // activate(false);
      Howler.mute(true);
    }
  };

  useEffect(() => {
    const url = music[section];

    if (url) {
      if (activeSound.current) {
        // console.log('stopping old song');
        activeSound.current.fade(1, 0, 4000);
      }

      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(playMusic, 2500, url);
    }
  }, [section]);

  const playMusic = (url) => {
    // console.log('playing new song');

    var sound = new Howl({
      src: ['/sounds/music/' + url],
      loop: true,
      html5: true,
    });
    sound.play();
    sound.fade(0, 1, 3000);

    activeSound.current = sound;
  };
}
