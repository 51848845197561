import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../../stores/useSection';
import { Words } from '../../components/Words';
import gsap from 'gsap';

export function Copy() {
  /*
   * properties
   */

  const smallProps = { font: 'fonts/ReciaSerifDisplay-Bold.woff', static: true, letterSpacing: 0, fontSize: '.22' };
  const bigProps = { font: 'fonts/ReciaSerifDisplay-Bold.woff', static: true, letterSpacing: 0, fontSize: '.55' };

  const group = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    // console.log('resizeHandler')
    // const ratio = window.innerWidth / window.innerHeight;
    // const isMobile = useSize.getState().isMobile;
    // let scale;
    // console.log('isMobile', isMobile);

    // if (isMobile) {
    //   // mobile
    //   scale = 0.15;
    //   // scale = 0.25;
    //   setProps({ ...props, fontSize: '1.07' });
    // } else {
    //   // desktop

    //   scale = Math.min(0.2, 0.1 + ratio * 0.06);
    // }

    const scale = Math.min(1.45, Math.max(1, 600 / window.innerWidth));
    // console.log(scale);

    group.current.scale.set(scale, scale, scale);
  };

  /*
   * hooks
   */

  return (
    <>
      <group ref={group} position={[0, 0, 2.7]}>
        <Words
          props={{ delay: 0.0, hideDelay: 3.1, ...smallProps }}
          position={[-1.22, 0.85, 0]}
          text="5 KEY PRINCIPLES"
        />
        <Words props={{ delay: 0.4, hideDelay: 3.2, ...smallProps }} position={[0.9, 0.6, 0]} text="SHROUDED" />
        <Words props={{ delay: 0.8, hideDelay: 3.3, ...smallProps }} position={[0.6, 0.32, 0]} text="IN" />
        <Words props={{ delay: 0.9, hideDelay: 3.4, ...smallProps }} position={[1.72, 0.32, 0]} text="A NEW COSMOS" />

        <Words props={{ delay: 1.5, hideDelay: 3.8, ...smallProps }} position={[-1.95, -0.24, 0]} text="ONLY" />
        <Words props={{ delay: 1.7, hideDelay: 3.9, ...smallProps }} position={[-0.9, -0.24, 0]} text="CUROSITY" />
        <Words props={{ delay: 2.0, hideDelay: 4.0, ...smallProps }} position={[0.85, -0.52, 0]} text="LEADS TO" />
        <Words props={{ delay: 2.2, hideDelay: 4.1, ...smallProps }} position={[1.7, -0.52, 0]} text="THEIR" />
        <Words props={{ delay: 2.3, hideDelay: 4.2, ...smallProps }} position={[1.02, -0.8, 0]} text="UNRAVELLING" />
      </group>
      {/* <group position={[0, 0, .5]}> */}
      <Words props={{ delay: 4.2, hideDelay: 6.0, ...bigProps }} position={[-2.22, 0.27, 0]} text="TRY" />
      <Words props={{ delay: 4.4, hideDelay: 6.2, ...bigProps }} position={[0.77, 0.27, 0]} text="DIFFERENT THINGS" />
      <Words props={{ delay: 4.6, hideDelay: 6.4, ...bigProps }} position={[-0.85, -0.27, 0]} text="TO REVEAL" />
      <Words props={{ delay: 4.8, hideDelay: 6.6, ...bigProps }} position={[1.52, -0.27, 0]} text="THE" />
      {/* </group> */}
    </>
  );
}
