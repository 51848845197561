import { useEffect, useRef, useState } from 'react';
import style from './Pieces.module.css';
import gsap from 'gsap';
import { useSection } from '../../stores/useSection';
import { useSize } from '../../stores/useSize';
import { usePieces } from '../../stores/usePieces';
import { useSounds } from '../../stores/useSounds';
import { Reset } from './Reset';

export function Pieces() {
  /*
   * properies
   */

  const svg = useRef();
  const container = useRef();
  const text = useRef();

  const [amount, setAmount] = useState(0);
  const [isCentered, setIsCentered] = useState(false);

  const transition = useSection((state) => state.transition);
  const setSection = useSection((state) => state.setSection);
  const isMobile = useSize((state) => state.isMobile);
  const pieces = usePieces((state) => state.pieces);
  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  useEffect(() => {
    gsap.killTweensOf(text.current);
    gsap.set(text.current, { opacity: 0 });
    gsap.to(text.current, { delay: 1, duration: 1.2, opacity: 1, ease: 'power2.in' });
  }, []);

  useEffect(() => {
    setVisuals();
  }, [isMobile, transition]);

  const setVisuals = () => {
    if (transition === 'show') {
      const section = useSection.getState().section;
      const centered = !isMobile && section === 'home';
      setIsCentered(centered);

      const opacity = isMobile && section !== 'home' ? 0 : 1;
      // svg.current.style.opacity = opacity;
      container.current.style.opacity = opacity;

      // const isCenter = !isMobile && section === 'home';

      // const bottom = centered ? '50%' : '0';
      // container.current.style.bottom = bottom;

      // const transform = centered ? '50%' : '0';
      // container.current.style.transform = `translate(0, ${transform})`;
    }
  };

  useEffect(() => {
    // const order = ['holding', 'scrolling', 'dragging', 'tapping', 'growing'];
    const imagePieces = Array.from(svg.current.querySelectorAll("[id^='piece']")).reverse();

    let amount = 0;

    for (let i = 0; i < 5; i++) {
      const found = pieces[i].found;
      const piece = imagePieces[i];
      // console.log(section);
      // console.log(piece);

      if (found) {
        amount++;
        piece.style.opacity = 1;
      } else {
        piece.style.opacity = 0;
      }
    }

    setAmount(amount);
  }, [pieces]);

  const clickHandler = () => {
    setSection('manifesto');
    playSound('GeneralUIClick');
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={`${style.container} ${isCentered ? style.centered : null}`} aria-label="manifesto">
        <div className={style.pieces}>
          <button onClick={clickHandler}>
            <svg
              ref={svg}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 53.93 69.85"
              className={`${style.svg} ${amount === 5 ? style.allFound : ''}`}
            >
              <g id="edge">
                <path
                  className={style.edge}
                  d="m52.16,23.18l-.3.15c.33.64.56,1.28,1,1.85.72.94-.3,1.96,0,2.96.86,2.38.02,4.58.15,6.95.35,2.28.39,4.52-.34,6.73.49.98.87,4.55-.94,3.74-.06.17.05.19.12.2.84.09.98,1.19,1.17,1.83.75,1.67.32,3.37-.36,4.99-.12.57.72.93.49,1.62.4,1.45.09,2.57-.27,3.97.05,1.38-.8,2.6-.61,3.92.28.5,0,.97-.05,1.48-.26.93-.17,2.69-.89,3.24-.79.3-.99,1.3-1.75,1.67-1.45.57-3.16-.39-4.56-.78-.48-.43-.48.2-.88.36-.46.2-1.02.28-1.3.74-1.12,1.47-1.95-1.51-3.24-.87-.8.33-1.68-.08-2.53-.03-.6-.11-1.19.45-1.74.07-.53-.43-1.08.05-1.6.26-1.08.69-2.18-.18-3.32-.22-1.55-.21-3.13.09-4.59.41-.64.29-1.38.2-1.99.5-.38.28-.89.5-1.3.14-.59-.51-1.2-1.26-2.05-1.11-1.14-.1-2.05.07-2.99.63-.66-.04-.95,1.12-1.64.7-1.33-.55-2.42-1.46-3.62-2.17-1.05-.85-3.33-.05-3.41-1.95-.28-.59.48-1.35-.09-1.81-.74-.36-.97-1.21-1.58-1.71-.74-.45-.94-1.32-1.54-1.89-.19-.14-.41-.13-.52.08-.48.75-1.98.32-1.77-.65.79-2.64-2.43-4.61-2.16-7.3-.11-5.62-.96-11.21-.09-16.91-.26-2.02-.24-3.91-.03-5.94-.15-.64-.7-1.21-.14-1.84-.59-.56-.51-1.27-.18-1.94.11-1.12.18-2.24.38-3.37.05-2.72-.38-5.48.15-8.2-.22-3.09-.54-6.32,1.06-9.13.69-1.66.67-2.56,2.64-2.88.44-.04.88-.08,1.03.43.43-.03.86-.32,1.18.06,1.35.14,2.28-1.41,3.59-.78,1.58.09,3.44-.63,4.98.2.58-.02,1.02-.36,1.55-.03,1.19-1.39,3.17-.84,4.76-.78,1.98-.67,4,.2,5.93,0,.71-.35,1.23.3,1.87.22,1.1-.39,2.29-.03,3.44.07,1-.3,1.85-.05,2.81.06,1.59-.1,3.19.02,4.73-.19,1.34-.81,2.65.54,3.96.14.48-.17.74.43.81.81.18.79,2.69-.86,3.19-1.09.63-.23.57.65,1,.89.48.85.58,1.96,1.15,2.77,1.29,1.77.39,1.84-.28,3.42-.71,2.19.36,4.33.62,6.47-.05,2.94-.27,6.06.84,8.86Z"
                />
              </g>
              <g id="piece5" className={style.piece}>
                <path d="m52.68,59.94c-.12-1.56.85-2.99.69-4.48-1.71.14-3.37.09-5.22.08-.5-.42-1.17-.5-1.81-.44-1.28.07-2.6-.14-3.81.27-.4.29-.91.16-1.37.24-.48.37-1.53.25-2.02.36-.84.47-1.74-.1-2.64-.08-.32-.28-1.62.03-1.58-.32-.97.62-2.03-.42-3.05-.29-.08.04-1.12.59-1.17.58-2.32.09-4.72.38-6.97-.12-.88.41-1.94.1-2.84.07-.69.31-1.26.22-1.95.04-.46,0-3.07.04-3.46.04-.85.22-1.72.55-2.61.48-2.47-.23-5.02,1.13-7.43.35-.75-.13-1.66.47-2.25-.27l.05.06c-.16-.21-.37-.32-.59-.38.3.7,1.13,1.3.87,2.14-.64,1.19.49,2.69,1.6,1.56.19-.35.58-.09.75.15.8,1.23,1.8,2.4,2.89,3.36.46.99-.52,3.09,1.43,3.19,2.48-.21,4.1,2.99,6.38,2.65.34-.43.75-.61,1.28-.69.37-.27.66-.62,1.17-.55.83.16,1.85-.33,2.51.3.69.19,1.05,1.33,1.89.91,1.04-.7,2.42-.48,3.49-1.07,1.16.05,2.36-.32,3.51-.09,1.14.04,2.24.91,3.32.22.44-.13.9-.59,1.36-.38.34.19.69.38,1.08.2,1.11-.46,2.35.3,3.44-.11,1.3-.64,2.13,2.33,3.24.87.33-.62,1.18-.48,1.58-.98.25-.55.56-.08.91.09,1.43.07,2.84,1.24,4.24.58.77-.38.97-1.37,1.75-1.67.54-.19.42-.67.45-1.11.21-1.07.59-2.17.59-3.24-.47-.9.08-1.66.28-2.5Z" />
              </g>
              <g id="piece4" className={style.piece}>
                <path d="m53.17,54.21c0-.44,0-.89-.43-1.19-.26-.78.51-1.76.71-2.59.15-1.48-.35-2.82-.85-4.17-.1-.5-.82-.34-.85-.7,1.27.57,1.15-.71,1.32-1.61-.48,0-1.19.02-1.6-.21-2.25.5-4.65.21-6.97.12-.05.02-1.1-.54-1.17-.58-1.01-.13-2.08.91-3.05.29.04.35-1.26.04-1.58.32-.89-.02-1.8.55-2.64.08-.49-.1-1.54.01-2.02-.36-.46-.08-.97.05-1.37-.24-.02-.08-.31-.09-.49-.09-.11.1-.08.24-.17.35-.19-.12-.25-.24-.26-.4-.91-.42-1.91.09-2.89-.12-.63-.07-1.31.01-1.81.44-2.43,0-4.53.02-6.83-.11.08.31-1.52.4-2.01.13-.16.14-.77-.03-1.04-.15-1.51.18-4.18-.47-5.96-.98h.08c-1.09-.22-2.09.36-3.16-.17-.69.13-1.3.29-1.97-.05-.92.36-1.92.02-2.92-.13-.81-.04-1.64.37-2.44.39.36,2.1-.19,4.16.32,6.25-.08,2.25,0,4.43,1.09,6.42.67-.19,1.39.11,1.89.43.03,0,.33,0,.27-.03,1-.55,2.26.09,3.34-.13.79-.3,1.85-.49,2.75-.48.83-.39,2.36-.14,3.17-.2,1.95-.51,4.36-.63,6.35-.17.71-.32,1.59-.26,2.27.11-.05-.03-.11-.05-.16-.07,1.4-.02,3.03-.23,4.33.34,1.39.04,2.8.1,4.19.17.68-.42,1.61-.87,2.39-.41,1.81-.66,3.56-.89,5.43-.22.53-.26,1.55-.12,1.93-.11.47-.19,1.1-.18,1.61-.1,1.48-.5,2.87,0,4.38.05.7-.04,1.47.06,2.07.45,1.57.07,3.44-.27,4.75-.59Z" />
              </g>
              <g id="piece3" className={style.piece}>
                <path d="m43.48,42.56s1.07.56,1.11.58c1.39-.07,2.8-.13,4.19-.17,1.26-.55,2.84-.38,4.2-.34-.7-1.6.73-3.33.3-5.06-.15-1.66-.6-3.5-.11-5.11.62-1.55-.35-3.3-.35-4.92-1.49-.08-3.02.09-4.67,0-1.11-.94-2.59-.04-3.86-.55-.53.18-1.27.26-1.76.38-.4.29-.91.16-1.37.24-.48.37-1.53.25-2.02.36-.84.47-1.74-.1-2.64-.08-.32-.28-1.62.03-1.58-.32-.97.62-2.03-.42-3.05-.29-1.2.86-2.74.61-4.13.71-.02.21-.15.4-.42.56-.14-.16-.09-.37-.26-.53-1.08-.04-2.28,0-3.33-.29-.88.41-1.94.1-2.84.07-.69.31-1.26.22-1.95.04-.46,0-3.07.04-3.46.04-.85.22-1.72.55-2.61.48-2.47-.23-5.02,1.13-7.43.35-.75-.13-1.66.47-2.25-.27l.05.06c-.38-.5-.96-.45-1.51-.5-.24-.26-.56-.45-.86-.68-.51.57.13,1.13.21,1.7.05.78-.43,1.57-.11,2.31.25,3.28-.39,6.64-.24,10.01.88.16,1.58-.15,2.44,0h-.05c.17-.01.32.07.48.12.62.24,1.41.23,2.04.37.35-.22.8-.23,1.16-.04.5.21,1-.28,1.55-.2.4-.02.82.32,1.18.01.71-.44,1.68-.71,2.46-.47,1.24-.34,2.57-.35,3.8.06.75.1,1.6-.08,2.31.28h-.03c.35,0,.68.1.98.28.49.27,1.16-.23,1.7.19-.03,0-.05,0-.08,0,2.01.29,3.92,1,6.11.85.6-.39,1.37-.48,2.07-.45,1.51-.05,2.9-.54,4.38-.05.51-.08,1.14-.1,1.61.1.38,0,1.4-.14,1.93.11,1.88-.66,3.63-.43,5.43.22.4-.18.84-.25,1.28-.17Z" />
              </g>
              <g id="piece2" className={style.piece}>
                <path d="m52.86,25.18c-.45-.57-.67-1.21-1-1.85l.3-.15c-1.04-2.43-.82-5.13-.87-7.69-.83.26-2.17-.04-3.14.05-.5-.42-1.17-.5-1.81-.44-1.28.07-2.6-.14-3.81.27-.4.29-.91.16-1.37.24-.48.37-1.53.25-2.02.36-.84.47-1.74-.1-2.64-.08-.32-.28-1.62.03-1.58-.32-.97.62-2.03-.42-3.05-.29-.08.04-1.12.59-1.17.58-2.32.09-4.72.38-6.97-.12-.88.41-1.94.1-2.84.07-.69.31-1.26.22-1.95.04-.46,0-3.07.04-3.46.04-.85.22-1.72.55-2.61.48-2.47-.23-5.02,1.13-7.43.35-.75-.13-1.66.47-2.25-.27l.05.06c-.38-.5-.96-.45-1.51-.5-.18-.19-.4-.35-.62-.5-.26,3.54.42,7.13-.61,10.54.64.31.91.68,1.58,1.14.71-.2,1.48.06,2.02.42.03,0,.33,0,.27-.03,1-.55,2.26.09,3.34-.13.79-.3,1.85-.49,2.75-.48.83-.39,2.36-.14,3.17-.2,1.95-.51,4.36-.63,6.35-.17.71-.32,1.59-.26,2.27.11-.05-.03-.11-.05-.16-.07,1.4-.02,3.03-.23,4.33.34,1.39.04,2.8.1,4.19.17.68-.42,1.61-.87,2.39-.41,1.81-.66,3.56-.89,5.43-.22.53-.26,1.55-.12,1.93-.11.47-.19,1.1-.18,1.61-.1,1.48-.5,2.87,0,4.38.05.7-.04,1.47.06,2.07.45,1.54.07,3.42-.26,4.69-.57.04-.36-.02-.71-.25-1.04Z" />
              </g>
              <g id="piece1" className={style.piece}>
                <path d="m46.35,14.35c1.63,0,3.35.96,4.96.15-.54-3.3-1.58-5.69.39-8.66-.61-1.27-1.31-2.44-1.67-3.8-.26-.39-.62-1.44-1.18-1.27-.87.61-1.81.9-2.78,1.31-.56.26-.36-.63-.7-.86-.62-.4-1.53.18-2.3-.39-1.67-.42-3.34.48-5.02.19-1.18.22-2.35.01-3.48-.17-1.34.33-2.71,0-4-.09-.76.49-1.32-.06-2.06-.09-.77.09-1.55.43-2.28-.03-2.04-.39-4.16.33-6.19-.07-.03.03-.05.05-.09.08-.01-.02-.02-.04-.03-.06-1.02.17-1.92.08-2.58.95-.59-.43-1.22.26-1.83-.08-1.45-.7-3.23.03-4.7-.09-1.32-.64-2.24.92-3.6.78-.32-.38-.75-.09-1.18-.06-.15-.52-.6-.47-1.03-.43-1.98.34-1.94,1.19-2.64,2.88-1.86,3.07-.93,6.58-1.19,9.91.18.25.54.42.91.73.71-.2,1.48.06,2.02.42.03,0,.33,0,.27-.03,1-.55,2.26.09,3.34-.13.79-.3,1.85-.49,2.75-.48.83-.39,2.36-.14,3.17-.2,1.95-.51,4.36-.63,6.35-.17.71-.32,1.59-.26,2.27.11-.05-.03-.11-.05-.16-.07,1.4-.02,3.03-.23,4.33.34,1.39.04,2.8.1,4.19.17.68-.42,1.61-.87,2.39-.41,1.81-.66,3.56-.89,5.43-.22.53-.26,1.55-.12,1.93-.11.47-.19,1.1-.18,1.61-.1,1.48-.5,2.87,0,4.38.05Z" />
              </g>
            </svg>
            {/* <img
        src="/images/pieces-blur.png"
        className={`${style.blur} ${amount === 5 ? style.allFound : ''}`}
        alt="blur"
        aria-disabled
      /> */}
            <p ref={text} className={style.text}>
              {amount} / 5 DISCOVERED
            </p>
          </button>
          <Reset isCentered={isCentered} amount={amount} />
        </div>
      </div>
    </>
  );
}
