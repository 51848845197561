import { useState } from 'react';
import style from './Button.module.css';
import { RivButtonBackground } from './RivButtonBackground';
import { useSounds } from '../../../stores/useSounds';

export function ButtonClues({ small = false, inverted = false, onClick, onHover, ariaLabel }) {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const [over, setOver] = useState(false);

  /*
   * hooks
   */

  const clickHandler = () => {
    onClick && onClick();
    playSound('GeneralUIClick');
  };

  /*
  * visuals
  */

  return (
    <button
      aria-label={ariaLabel}
      className={`${style.button} ${small ? style.small : ''}`}
      onClick={clickHandler}
      onMouseEnter={() => {
        setOver(true);
        onHover && onHover();
      }}
      onMouseLeave={() => {
        setOver(false);
      }}
    >
      <div className={style.riv}>
        <RivButtonBackground over={over} clue={true} inverted={inverted} />
      </div>
    </button>
  );
}
