import { Html, Text, shaderMaterial } from '@react-three/drei';
import { extend, useFrame } from '@react-three/fiber';
import style from './IntroText.module.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import vertexShader from './shaders/text/vertex.glsl?raw';
import fragmentShader from './shaders/text/fragment.glsl?raw';
import { IntroTextContent } from './IntroTextContent';
import { useSection } from '../../stores/useSection';

const TextMaterial = shaderMaterial(
  {
    uTime: 0,
    uAmount: 0,
  },
  vertexShader,
  fragmentShader
);

extend({ TextMaterial });

export function IntroText({ line1, line2, show }) {
  /*
   * properties
   */

  const material1 = useRef();
  const material2 = useRef();
  const title = useRef();
  const section = useSection((state) => state.section);
  const transition = useSection((state) => state.transition);
  const props = { font: 'fonts/ReciaSerifDisplay-Bold.woff', fontSize: '1.7' };
  const [showContent, setShowContent] = useState(false);

  /*
   * hooks
   */

  const titlePosition = useMemo(() => {
    // console.log(section);
    if (section === 'dragging') {
      return [-2.85, 2.05, 0];
    } else if (section === 'scrolling') {
      return [-2.1, 2.05, 0];
    } else if (section === 'growing') {
      return [-3.4, 2, 0];
    } else if (section === 'tapping') {
      return [-2.15, 2.05, 0];
    } else if (section === 'holding') {
      return [-1.8, 2.05, 0];
    }

    return [0, 0, 0];
  }, [section]);

  const copyPosition = useMemo(() => {
    if (section === 'dragging') {
      return [-2.8, -0.7, 0];
    } else if (section === 'scrolling') {
      return [-2.1, -0.7, 0];
    } else if (section === 'growing') {
      return [-3.3, -0.8, 0];
    } else if (section === 'tapping') {
      return [-2.1, -0.55, 0];
    } else if (section === 'holding') {
      return [-2.1, -0.55, 0];
    }

    return [0, 0, 0];
  }, [section]);

  useEffect(() => {
    // show
    const delay = 0.5;
    const duration = 2.2;

    gsap.to(material1.current.uniforms.uAmount, {
      onStart: showSection,
      delay,
      duration,
      value: 1,
      ease: 'power1.inOut',
    });
    gsap.to(material2.current.uniforms.uAmount, { delay: delay + 0.2, duration, value: 1, ease: 'power1.inOut' });
  }, []);

  const showSection = () => {
    let delay = 0.35;
    gsap.to(title.current, { delay, duration: 1.5, opacity: 1, ease: 'sine.out' });
    gsap.from(title.current, { delay, duration: 1.5, y: -20, ease: 'power4.out' });

    setShowContent(true);
  };

  useEffect(() => {
    if (!show) {
      hide();
    }
  }, [show]);

  useEffect(() => {
    if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const hide = () => {
    // console.log('hide IntroText');
    const duration = 0.4;
    gsap.to(material1.current.uniforms.uAmount, { duration, value: 0, ease: 'sine.inOut' });
    gsap.to(material2.current.uniforms.uAmount, { duration, value: 0, ease: 'sine.inOut' });

    gsap.to(title.current, { duration: 0.4, opacity: 0, ease: 'sine.out' });

    setShowContent(false);
  };

  useFrame((state, delta) => {
    material1.current.uniforms.uTime.value += delta;
    material2.current.uniforms.uTime.value += delta;
  });

  /*
   * visuals
   */

  return (
    <>
      <Text {...props} position={[-1.75, 0.75, 0]}>
        {line1}
        <textMaterial ref={material1} depthTest={false} />
      </Text>
      <Text {...props} position={[2.2, -0.8, 0]}>
        {line2}
        <textMaterial ref={material2} depthTest={false} />
      </Text>
      <Html position={titlePosition} wrapperClass={style.wrapper}>
        <p ref={title} className={style.title}>
          engaged
        </p>
      </Html>
      <Html position={copyPosition} wrapperClass={style.wrapper}>
        {/* <section ref={section} className={style.section}>
          {children}
        </section> */}

        <IntroTextContent section={section} shown={showContent} />
      </Html>
    </>
  );
}
