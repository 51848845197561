import style from './Icon.module.css';

export function Icon({ svg, dark, size = 'normal' }) {
  return (
    <img
      className={`${style.icon} ${style[size]} ${dark ? style.dark : ''}`}
      aria-hidden="true"
      alt={svg}
      src={`/icons/${svg}.svg`}
    />
  );
}
