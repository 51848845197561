import { useGesture } from '@use-gesture/react';
import style from './Interaction.module.css';
import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../stores/useSection';
import { useInteraction } from '../../stores/useInteraction';
import { useSounds } from '../../stores/useSounds';

export function Interaction() {
  /*
   * properties
   */

  const container = useRef();
  const [isActive, setIsActive] = useState(false);

  const setSection = useSection((state) => state.setSection);
  const section = useSection((state) => state.section);
  const transition = useSection((state) => state.transition);
  const transitioning = useSection((state) => state.transitioning);

  const setInteracting = useInteraction((state) => state.setInteracting);

  const playSound = useSounds((state) => state.playSound);

  let holdTimeoutId;
  let interactionTimeoutId;

  let tapTime = 0;
  let tapAmount = 0;

  // let holdStart = 0;

  /*
   * hooks
   */

  useGesture(
    {
      onPinch: (state) => {
        // console.log('onPinch');
        // console.log(state.distance);

        startInteracting();

        if (state.distance[0] > 0 || state.distance[1] > 0) {
          // console.log('pinch');
          openSection('growing');
        }
      },
      onDrag: (state) => {
        // console.log('onDrag');
        // console.log(state.distance);

        startInteracting();

        if (state.distance[0] > 150) {
          // console.log('drag');
          openSection('dragging');
        }
      },
      onScroll: (state) => {
        // console.log('onScroll');
        // console.log(state.distance[1]);

        startInteracting();

        // console.log(state.distance[0], state.distance[1]);

        if (state.distance[0] > 400 || state.distance[1] > 400) {
          // console.log('scroll');
          openSection('scrolling');
        }
      },
    },
    {
      target: container.current,
    }
  );

  useEffect(() => {
    container.current.addEventListener('mousedown', down);
    container.current.addEventListener('touchstart', down);

    container.current.addEventListener('mouseup', up);
    container.current.addEventListener('touchend', up);

    // const intervalId = setInterval(loop, 30);

    window.addEventListener('keydown', keyHandler);

    // console.log('Interaction :: onMount');

    return () => {
      // clearInterval(intervalId);
      destroy();

      // console.log('Interaction :: onDestroy');
    };
  }, []);

  const destroy = () => {
    cancelHold();

    if (container.current) {
      container.current.removeEventListener('mousedown', down);
      container.current.removeEventListener('touchstart', down);

      container.current.removeEventListener('mouseup', up);
      container.current.removeEventListener('touchend', up);
    }

    window.removeEventListener('keydown', keyHandler);
  };

  const down = () => {
    // console.log('down');
    // holdStart = Date.now();
    // console.log(holdStart);

    cancelHold();

    startInteracting();

    const now = Date.now();

    if (now - tapTime < 600) {
      tapAmount++;
      if (tapAmount >= 2) {
        openSection('tapping');
      }
    } else {
      tapAmount = 0;
      startHold();
    }

    // console.log(tapAmount)

    tapTime = now;
  };

  const up = () => {
    // console.log('up');
    // const time = Date.now() - holdStart;
    // console.log(time);

    // openSection('tapping');

    cancelHold();
  };

  const keyHandler = (e) => {
    const num = parseInt(e.key);
    if (num >= 1) {
      // console.log('num');
      openSection('growing');
    }
  };

  useEffect(() => {
    if (transitioning || section !== 'home') {
      // console.log(true);
      cancelHold();
      setIsActive(false);
    } else {
      // console.log(false);
      setIsActive(true);
    }
  }, [transitioning, transition, section]);

  const openSection = (newSection) => {
    if (!useSection.getState().transitioning && useSection.getState().section === 'home') {
      // console.log('really openSection', section);

      playSound('ClickToContinue');

      cancelHold();
      setIsActive(false);
      setSection(newSection);
    }
  };

  // const loop = () => {
  //   console.log(isActive);
  // };

  const startHold = () => {
    // console.log('startHold');

    holdTimeoutId = setTimeout(holdHandler, 1200);
  };

  const cancelHold = () => {
    if (holdTimeoutId) {
      // console.log('cancelHold');
      // console.log('holdTimeoutId', holdTimeoutId);

      clearTimeout(holdTimeoutId);
      holdTimeoutId = null;
    }
  };

  const holdHandler = () => {
    // console.log('holdHandler');
    holdTimeoutId = null;
    openSection('holding');
  };

  const startInteracting = () => {
    // console.log('startInteracting');
    setInteracting(true);

    clearTimeout(interactionTimeoutId);
    interactionTimeoutId = setTimeout(endInteracting, 500);
  };

  const endInteracting = () => {
    setInteracting(false);
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={`${style.interaction} ${isActive ? style.active : ''}`}>
        <div className={style.inner} />
      </div>
    </>
  );
}
