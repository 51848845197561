import { useEffect, useState } from 'react';
import style from './Timer.module.css';
import { usePieces } from '../../stores/usePieces';
import { useTimer } from '../../stores/useTimer';
import { useSection } from '../../stores/useSection';

export function Timer() {
  /*
   * properies
   */

  const time = useTimer((state) => state.time);
  const pieces = usePieces((state) => state.pieces);
  const section = useSection((state) => state.section);

  let isActive = true;
  //   let startTime = 0;
  //   let isTiming = false;

  const [shown, setShown] = useState(false);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('Timer :: onMount');

    // startTimer();

    document.addEventListener('visibilitychange', tabSwitch);
    // window.addEventListener('unload', stopTimer);
    // window.addEventListener('beforeunload', stopTimer);

    const intervalId = setInterval(saveTime, 1000);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', tabSwitch);
    };
  }, []);

    useEffect(() => {
      // console.log('Timer :: change time');
      // console.log('time: ' + time);

    //   var minutes = Math.floor(time / 60000);
    //   var seconds = ((time % 60000) / 1000).toFixed(0);
    //   setSec(seconds);
    //   setMin(minutes);
    }, [time]);

  useEffect(() => {
    if (usePieces.getState().foundAllPieces() && section === 'manifesto') {
    // if (section === 'manifesto') {
      //   stopTimer();
      setShown(true);
      var minutes = Math.floor(time / 60000);
      var seconds = ((time % 60000) / 1000).toFixed(0);
      setSec(seconds);
      setMin(minutes);
    } else {
      setShown(false);
    }
  }, [pieces, section]);

  const saveTime = () => {
    if (!usePieces.getState().foundAllPieces()) {
      useTimer.getState().addTime(1000);
    }
  };

  const tabSwitch = () => {
    if (document.visibilityState === 'visible') {
      // console.log('Timer :: tab visible');
      isActive = true;
    } else {
      // console.log('Timer :: tab invisible');
      isActive = false;
    }
  };

  //   const startTimer = () => {
  //     console.log('Timer :: startTimer');
  //     if (!usePieces.getState().foundAllPieces()) {
  //       isTiming = true;
  //       console.log('Timer :: really startTimer');
  //       startTime = new Date().getTime();
  //       console.log('startTime: ' + startTime);
  //     }
  //   };

  //   const stopTimer = () => {
  //     console.log('Timer :: stopTimer');
  //     if (isTiming) {
  //       isTiming = false;
  //       console.log('Timer :: really stopTimer');
  //       const timing = new Date().getTime() - startTime;
  //       console.log('timing: ' + timing);
  //       useTimer.getState().addTime(timing);
  //     }
  //   };

  /*
   * visuals
   */

  return (
    <>
      <div className={`${style.timer} ${shown ? style.shown : ''}`}>
        <span className={style.light}>You discovered in</span> <br />
        {min} mins {sec} seconds
      </div>
    </>
  );
}
