import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const usePieces = create(
  persist(
    (set, get) => ({
      pieces: [
        { section: 'holding', found: false },
        { section: 'scrolling', found: false },
        { section: 'dragging', found: false },
        { section: 'tapping', found: false },
        { section: 'growing', found: false },
      ],

      foundPiece: (section) =>
        set({
          pieces: get().pieces.map((item) => {
            if (item.section === section) {
              item.found = true;
            }
            return item;
          }),
        }),

      foundAllPieces: () => {
        return get().pieces.every((piece) => piece.found);
      },

      reset: (section) =>
        set({
          pieces: get().pieces.map((item) => {
            item.found = false;
            return item;
          }),
        }),
    }),
    {
      // name: 'HJCHEIHEI', // alle pieces
      // name: 'KJHKJHIUHIH',
      name: "kjkjkj"
    }
  )
);
