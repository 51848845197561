import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useSection } from '../../stores/useSection';
import { useFrame } from '@react-three/fiber';
import { usePost } from '../../stores/usePost';

export function SectionContainer({ children }) {
  /*
   * properties
   */

  let inactiveTime = 0;

  const container = useRef();
  const setSection = useSection((state) => state.setSection);
  const showSection = useSection((state) => state.showSection);
  const transition = useSection((state) => state.transition);
  const concealed = useSection((state) => state.concealed);

  /*
   * hooks
   */

  useFrame((state, delta) => {
    const pct = usePost.getState().pct;

    if (pct < 0.01) {
      inactiveTime += delta;
    } else {
      inactiveTime = 0;
    }

    // console.log(pct, inactiveTime);

    if (inactiveTime >= 7.5) {
      // console.log('*** HIDE ***')
      setSection('home');
    }
  });

  useEffect(() => {
    // console.log('SectionContainer :: mount');
    showSection();

    return () => {
      // console.log('SectionContainer :: unMount');
    };
  }, []);

  useEffect(() => {
    if (transition === 'show') {
      inactiveTime = 0;

      gsap.set(container.current.scale, { x: 0, y: 0, z: 0 });
      gsap.set(container.current.rotation, { x: 0, y: 0, z: 5 });

      gsap.to(container.current.scale, { x: 1, y: 1, z: 1, duration: 1, ease: 'sine.out' });
      gsap.to(container.current.rotation, { x: 0, y: 0, z: 0, duration: 0.9, ease: 'sine.out' });
    } else if (transition === 'hide') {
      gsap.to(container.current.scale, { x: 0, y: 0, z: 1, duration: 0.45, ease: 'sine.in' });
    }
  }, [transition]);

  useEffect(() => {
    // console.log('changing conceal');
    // console.log(concealed);
    const section = useSection.getState().section;
    if (concealed) {
      if (section === 'dragging') {
        gsap.to(container.current.position, { y: 2, duration: 2.5, ease: 'sine.inOut' });
      } else if (section !== 'scrolling') {
        gsap.to(container.current.position, { z: -5, duration: 2.5, ease: 'sine.inOut' });
      }
    }
  }, [concealed]);

  /*
   * visuals
   */

  return (
    <>
      <group ref={container} scale={[0, 0, 0]}>
        {children}
      </group>
    </>
  );
}
