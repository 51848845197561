import { Instance } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';

const height = 10;
const rotations = 12;
const pi = 3.141593;

export function Block({ index, total }) {
  // console.log('Block :: render');

  /*
   * properties
   */

  const instance = useRef();

  /*
   * hooks
   */

  const setPosition = (pct, rotationExtra) => {
    const box = instance.current;

    const angle = pct * pi * 2 * rotations;

    let heightPct;
    let radiusExtraPct = 0;

    if (pct < 0.5) {
      heightPct = pct * 2;
    } else {
      heightPct = 1 - (pct - 0.5) * 2;
      radiusExtraPct = Math.sin(heightPct * pi);
    }

    const radius = 1 + radiusExtraPct * 2.5;

    //POSITION
    box.position.x = Math.cos(angle) * radius;
    box.position.y = heightPct * height - height * 0.5;
    box.position.z = Math.sin(angle) * radius;

    //ROTATION
    box.rotation.y = -angle;
    box.rotation.z = pct * pi * 5 + rotationExtra;

    //SCALE
    box.scale.z = 0.75 + radiusExtraPct * 2;
    box.scale.x = 0.5 + radiusExtraPct * 0.5;
  };

  useFrame((state) => {
    const pct = (index / total + state.clock.elapsedTime * 0.01) % 1;
    const rotationExtra = state.clock.elapsedTime * 1;

    setPosition(pct, rotationExtra);
  });

  return <Instance ref={instance} />;
}
