import { useState } from 'react';
import style from './Button.module.css';
import { RivButtonBackground } from './RivButtonBackground';

export function TransparentButtonIcon({ text, small = false, strikethrough = false, onClick, onHover, ariaLabel }) {
  const [over, setOver] = useState(false);

  const numeral = ['I', 'II', 'III', 'IV', 'V'];

  return (
    <button
      aria-label={ariaLabel}
      className={`${style.button} ${small ? style.small : ''}`}
      onClick={onClick}
      onMouseEnter={() => {
        setOver(true);
        onHover && onHover();
      }}
      onMouseLeave={() => {
        setOver(false);
      }}
      disabled={strikethrough}
    >
      <div className={`${style.text} ${style.transparent} ${strikethrough ? style.strikethrough : ''}`}>
        {numeral[text]}
      </div>
    </button>
  );
}
