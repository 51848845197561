import { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useDrag } from '../../../stores/useDrag';
import { useSection } from '../../../stores/useSection';
import { usePost } from '../../../stores/usePost';
import { useSounds } from '../../../stores/useSounds';

let autoScrollSpeed = 0;
let autoScroll = false;

export function DragController() {
  // console.log('DragController :: render');

  /*
   * properties
   */

  const reset = useDrag((state) => state.reset);
  const start = useDrag((state) => state.start);
  const finish = useDrag((state) => state.finish);
  const setDrag = useDrag((state) => state.setDrag);
  const setSlowDrag = useDrag((state) => state.setSlowDrag);
  const concealSection = useSection((state) => state.concealSection);
  const setPost = usePost((state) => state.setPost);
  const finishPost = usePost((state) => state.setPost);

  let volume = 0;
  const getSound = useSounds((state) => state.getSound);
  const sound = getSound('DraggingLoop');

  let isDragging = false;
  let startPos = 0;
  let lastX = 0;
  let xDelta = 0;
  let startDragPct = 0;

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('DragController :: onMount');

    isDragging = false;
    startPos = lastX = 0;
    startDragPct = 0;

    const timeoutId = setTimeout(start, 2500);

    sound.play();
    sound.volume(0);

    window.addEventListener('mousedown', startMouseDrag);
    window.addEventListener('mouseup', stopDrag);
    window.addEventListener('mousemove', handleMouseMove);

    window.addEventListener('touchstart', startTouchDrag);
    window.addEventListener('touchend', stopDrag);
    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      // console.log('DragController :: onDestroy');

      sound.stop();

      window.removeEventListener('mousedown', startMouseDrag);
      window.removeEventListener('mouseup', stopDrag);
      window.removeEventListener('mousemove', handleMouseMove);

      window.removeEventListener('touchstart', startTouchDrag);
      window.removeEventListener('touchend', stopDrag);
      window.removeEventListener('touchmove', handleTouchMove);

      reset();
      clearTimeout(timeoutId);
    };
  }, []);

  const startMouseDrag = (event) => {
    if (useDrag.getState().started) {
      isDragging = true;
      startPos = lastX = event.clientX;
      startDragPct = useDrag.getState().dragPct;
    }
  };

  const startTouchDrag = (event) => {
    if (useDrag.getState().started) {
      isDragging = true;
      var touch = event.touches[0];
      startPos = lastX = touch.clientX;
      startDragPct = useDrag.getState().dragPct;
    }
  };

  const stopDrag = () => {
    xDelta = 0;
    isDragging = false;
  };

  const handleMouseMove = (event) => {
    doDrag(event.clientX);
  };

  const handleTouchMove = (event) => {
    var touch = event.touches[0];
    if (touch) {
      doDrag(touch.clientX);
    }
  };

  const doDrag = (x) => {
    if (isDragging && !useDrag.getState().finished) {
      if (!autoScroll) {
        autoScrollSpeed = 0.1;
      }

      xDelta = lastX - x;
      // console.log(xDelta);
      lastX = x;

      const distance = x - startPos;
      // console.log(x);
      let pct = distance / 1500;
      pct = Math.max(0, Math.min(1, startDragPct + pct));

      setDrag(pct);
      setPost(pct);

      if (pct >= 1) {
        xDelta = 0;
        // sound.fade(1, 0, 300);
        finish();
        finishPost();
        concealSection();
      }

      autoScroll = false;
    }
  };

  useFrame((state, delta) => {
    let dragPct = useDrag.getState().dragPct;
    let slowDragPct = useDrag.getState().slowDragPct;

    if (dragPct != slowDragPct) {
      if (Math.abs(dragPct - slowDragPct) <= 0.0025) {
        slowDragPct = dragPct;
      } else {
        slowDragPct += (dragPct - slowDragPct) * (delta * 7);
      }

      setSlowDrag(slowDragPct);
    }

    if (Math.abs(xDelta) > 0) {
      volume = Math.max(0.2, volume * 1.1);
    } else {
      volume *= 0.95;
    }
    volume = Math.max(0, Math.min(1, volume));
    // console.log(volume);
    sound.volume(volume);

    if (!useDrag.getState().finished) {
      autoScrollSpeed *= 1.07;
      autoScrollSpeed = Math.min(autoScrollSpeed, 100);

      if (autoScrollSpeed > 1) {
        dragPct -= autoScrollSpeed * delta * 0.001;
        dragPct = Math.max(0, dragPct);
        autoScroll = true;

        setDrag(dragPct);
        setPost(dragPct);
      }
    }
  });
}
