import style from './UI.module.css';

import { RoundButton } from '../../components/RoundButton';
import { ButtonClues } from '../../components/buttons/ButtonClues';
import { ButtonClose } from '../../components/buttons/ButtonClose';
import { ButtonIcon } from '../../components/buttons/ButtonIcon';
import { TransparentButtonIcon } from '../../components/buttons/TransparentButtonIcon';

export function UI() {
  const clickHandler = () => {
    console.log('clickHandler');
  };

  const overHandler = () => {
    console.log('overHandler');
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.row}>
          <ButtonClues small={false} onClick={clickHandler} onHover={overHandler} />
          <ButtonClues small={true} />
          <ButtonClues inverted={true} />

          <ButtonClose small={false} />
          <ButtonClose small={true} />
          <ButtonClose inverted={true} />
        </div>
        <div className={style.row}>
          <ButtonIcon text="1" />
          <ButtonIcon text="2" small={true} />
          <ButtonIcon text="3" strikethrough={true} />
          <ButtonIcon text="4" strikethrough={true} small={true} />
          <TransparentButtonIcon text="0" />
          <TransparentButtonIcon text="1" strikethrough={true} />
        </div>
      </div>
    </>
  );
}
