import { useEffect, useRef, useState } from 'react';
import style from './CluesButton.module.css';
import { RoundButton } from '../../components/RoundButton';
import gsap from 'gsap';
import { ButtonClues } from '../../components/buttons/ButtonClues';

export function CluesButton({ show, onHover }) {
  /*
   * properies
   */

  const container = useRef();
  const [active, setActive] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    if (show) {
      // setTimeout(() => {
      //   setActive(true);
      // }, 500);
      setActive(true);
    } else {
      container.current.style.pointerEvents = 'none';
      gsap.killTweensOf(container.current);
      gsap.to(container.current, { delay: 0.3, opacity: 0, onComplete: hideHandler, duration: 0.2, ease: 'power1.in' });
    }
  }, [show]);

  const hideHandler = () => {
    setActive(false);
  };

  /*
   * visuals
   */

  return (
    <>
      {active && (
        <div ref={container} className={style.search} onMouseEnter={onHover}>
          {/* <ButtonClues onHover={onHover} ariaLabel="clues" /> */}
          <ButtonClues onClick={onHover} ariaLabel="clues" />
          <p className={style.text}>CLUES</p>
        </div>
      )}
    </>
  );
}
