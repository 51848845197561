import { shaderMaterial, useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { useFrame, extend } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import portalVertexShader from './shaders/vertex.glsl?raw';
import portalFragmentShader from './shaders/fragment.glsl?raw';
import gsap from 'gsap';
import { usePositions } from './usePositions';
import { useScrolling } from '../../../stores/useScrolling';

const PointMaterial = shaderMaterial(
  {
    uSize: 0,
    uColor: new THREE.Color(1.0, 1.0, 1.0),
    uTexture: null,
    uResolutionFactor: 150,
    uOpacity: 1,
  },
  portalVertexShader,
  portalFragmentShader
);

extend({ PointMaterial });

export function Ring({ index }) {
  // console.log('RENDER Ring');

  /*
   * properties
   */
  const tunnelLength = 36;
  const pointMaterial = useRef();
  const mesh = useRef();

  const texture = useTexture('/textures/blurredPoint-min.png');

  const amount = 500;

  let tween1;
  let tween2;

  const [showPiece, setShowPiece] = useState(false);

  /*
   * hooks
   */

  const startTween = () => {
    const delay = 0.1 + Math.random() * 0.4;
    const duration = 2;
    const rnd = Math.random() * 1;

    tween1 = gsap.to(mesh.current.rotation, {
      z: rnd * 1.5,
      delay,
      duration,
      ease: 'sine.inOut',
    });

    tween2 = gsap.to(pointMaterial.current.uniforms.uSize, {
      value: rnd * 10,
      delay,
      duration,
      ease: 'sine.inOut',
      onComplete: startTween,
    });
  };

  useEffect(() => {
    startTween();
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      tween1.kill();
      tween1 = null;

      tween2.kill();
      tween2 = null;

      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    //NOG VERANDEREN WANNEER CAMERA OOK AFHANKELIJK VAN BREEDTE WORDT;
    pointMaterial.current.uniforms.uResolutionFactor.value = window.innerHeight * 0.15;
  };

  const points = useMemo(() => {
    const radius = 1.535;

    const p = new Array(amount * 3);
    for (let i = 0; i < amount; i++) {
      const angle = Math.random() * Math.PI * 2;
      p[i * 3 + 0] = Math.cos(angle) * radius; //x
      p[i * 3 + 1] = Math.sin(angle) * radius; //y
      p[i * 3 + 2] = 0;
    }

    return new Float32Array(p);
  }, [amount]);

  const sizes = useMemo(() => {
    const s = new Array(amount);
    for (let i = 0; i < amount; i++) {
      s[i] = 3 * Math.random();
    }

    return new Float32Array(s);
  }, [amount]);

  useFrame((state) => {
    const positions = usePositions.getState().positions;
    const position = positions[index];

    if (position) {
      //set position
      mesh.current.position.copy(position);

      //set pulse
      const pulsePct = usePositions.getState().pulsePct;
      const pulsePosition = -tunnelLength * pulsePct;
      const dist = position.z - pulsePosition;
      const alpha = Math.min(1, Math.abs(dist) / 6.5);
      pointMaterial.current.uniforms.uOpacity.value = Math.pow(alpha, 3);
    }

    if (useScrolling.getState().scrollPct === 1 && !showPiece) {
      // console.log('HIDE RING');
      setShowPiece(true);

      // console.log(pointMaterial.current.uniforms);
      // console.log(pointMaterial.current.uniforms.uColor.value);

      gsap.to(pointMaterial.current.uniforms.uColor.value, {
        r: 0.01,
        g: 0.01,
        b: 0.01,
        duration: 1,
        ease: 'sine.inOut',
      });
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <points ref={mesh}>
        <bufferGeometry>
          <bufferAttribute attach={'attributes-position'} args={[points, 3, false]} />
          <bufferAttribute attach={'attributes-aSize'} args={[sizes, 1, false]} />
        </bufferGeometry>
        <pointMaterial
          ref={pointMaterial}
          uColor="#555555"
          uSize="1"
          uTexture={texture}
          transparent={true}
          blending={THREE.AdditiveBlending}
          depthWrite={false}
        />
      </points>
    </>
  );
}
