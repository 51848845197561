import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useSize } from '../../stores/useSize';

export function Divider({ show }) {
  /*
   * properties
   */

  const mesh = useRef();
  const material = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    if (show) {
      gsap.killTweensOf(mesh.current);
      gsap.to(material.current, { duration: 1, opacity: 0.8 });
    }
  }, [show]);

  /*
   * visuals
   */

  return (
    <>
      {show && (
        <mesh ref={mesh}>
          <planeGeometry args={[30, 30]} />
          <meshBasicMaterial ref={material} color="#222222" transparent={true} opacity={0} />
        </mesh>
      )}
    </>
  );
}
