import './style.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { StrictMode, Suspense } from 'react';

import { Leva } from 'leva';

// import { Router } from 'react-router-dom';
// import { createBrowserHistory } from 'history';

import { Canvas } from '@react-three/fiber';
import { Preload } from '@react-three/drei';

import { App3D } from './app3D/App3D';
import { App2D } from './app2D/App2D';
import { Sound } from './sound/Sound';
import { ScrollController } from './controllers/ScrollController';

// const history = createBrowserHistory({ window });

const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(
  <StrictMode>
    {/* <BrowserRouter history={history}> */}
    {/* Debug GUI */}
    {/* {import.meta.env.DEV && <Leva collapsed={true} />} */}

    <Sound />

    {/* ThreeJS WebGL */}
    <Canvas shadows={false} flat={true}>
      <Suspense>
        <App3D />
      </Suspense>
      <Preload all />
    </Canvas>
    <ScrollController />
    <div className="all">
      <App2D />
    </div>
    {/* </BrowserRouter> */}
  </StrictMode>
);

// function BrowserRouter({ children, history }) {
//   let [state, dispatch] = React.useReducer((_, action) => action, {
//     action: history.action,
//     location: history.location,
//   });

//   React.useLayoutEffect(() => history.listen(dispatch), [history]);

//   return <Router children={children} action={state.action} location={state.location} navigator={history} />;
// }
