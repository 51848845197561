export function Lighting() {
  /*
   * properties
   */

  // const hemiProps = useControls('hemisphere light material', {
  //   intensity: { value: 10, min: 0, max: 10, step: 0.01 },
  //   position: { value: { x: 0, y: 1, z: -1 }, min: -4, max: 4, step: 0.01 },
  //   color1: { value: '#ffffff' },
  //   color2: { value: '#000000' },
  // });

  const hemiProps = {
    intensity: 10,
    position: { x: 0, y: 1, z: -1 },
    color1: '#ffffff',
    color2: '#000000',
  };

  /*
   * visuals
   */

  return (
    <>
      <ambientLight intensity={0.3} />
      <hemisphereLight
        intensity={hemiProps.intensity}
        position={[hemiProps.position.x, hemiProps.position.y, hemiProps.position.z]}
        args={[hemiProps.color1, hemiProps.color2]}
      />
    </>
  );
}
