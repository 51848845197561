import { useEffect, useMemo, useRef } from 'react';
import { BranchItem } from './BranchItem';
import { useFrame } from '@react-three/fiber';

export function Branch({ children, rotX = 0, rotY = 0, rotZ = 0 }) {
  /*
   * properties
   */

  const container = useRef();
  const total = 6;
  const timeExtra = Math.random() * 6;

  /*
   * hooks
   */

  // useEffect(() => {
  //   // console.log('Branches :: onMount');
  // }, []);

  useFrame((state, delta) => {
    const time = state.clock.elapsedTime;

    container.current.rotation.set(rotX + time * 0.2, rotY + Math.cos(time) * 0.21, rotZ + Math.sin(time) * 0.155);
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={container}>
        {Array.from({ length: total }, (_, i) => (
          <BranchItem key={i} pct={(i + 1) / total} branchTimeExtra={timeExtra} />
        ))}
        <group scale={[0.5, 0.5, 0.5]} position={[1.875, 0, 0]}>
          {children}
        </group>
      </group>
    </>
  );
}
