import { useEffect, useRef, useState } from 'react';

import { IntroText } from '../../components/IntroText';
import { ScrollPiece } from '../../components/ScrollPiece';
import { useFrame } from '@react-three/fiber';
import { useTap } from '../../../stores/useTap';
import { OutroCopy } from '../../components/OutroCopy';
import { Divider } from '../../components/Divider';

export function Content() {
  // console.log('Text :: render');

  /*
   * properties
   */

  const [showIntro, setShowIntro] = useState(true);
  const [showPiece, setShowPiece] = useState(false);

  /*
   * hooks
   */

  useFrame(() => {
    // console.log(useTap.getState().tapPct);
    // intro text
    if (useTap.getState().petalsTapped > 2 && showIntro) {
      setShowIntro(false);
    }

    // scroll piece
    // if (useTap.getState().petalsTapped === useTap.getState().petalsToTap && !showPiece) {
    if (useTap.getState().tapPct === 1 && !showPiece) {
      setShowPiece(true);
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group position={[0, 0, -2]}>
        <IntroText line1="TAPP" line2="ING" show={showIntro} />
      </group>
      <group position={[0, 0, 5]} rotation={[0, 0, 0]}>
        <Divider show={showPiece} />
      </group>
      <group position={[0, 0, 5 + 5]} rotation={[0, 0, 0]}>
        <ScrollPiece show={showPiece} textureName="tapping" />
        <OutroCopy show={showPiece} section="tapping" />
      </group>
    </>
  );
}
