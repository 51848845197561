import { useSection } from '../stores/useSection';

import { Home } from './sections/home/Home';
import { Dragging } from './sections/dragging/Dragging';
import { Tapping } from './sections/tapping/Tapping';
import { Test } from './sections/test/Test';
import { Scrolling } from './sections/scrolling/Scrolling';
import { Holding } from './sections/holding/Holding';
import { Show } from './sections/show/Show';
import { Growing } from './sections/growing/Growing';
import { Intro } from './sections/intro/Intro';
import { Manifesto } from './sections/manifesto/Manifesto';
import { Dot } from './sections/home/Dot';
import { useEffect } from 'react';
import { useSounds } from '../stores/useSounds';

export function Sections() {
  /*
   * properties
   */

  const section = useSection((state) => state.section);
  // const playSound = useSounds((state) => state.playSound);

  /*
   * properties
   */

  // useEffect(() => {
  //   playSound('ClickToContinue');
  // }, [section]);

  /*
   * visuals
   */

  return (
    <>
      <Dot />

      {section === 'intro' && <Intro />}
      {section === 'home' && <Home />}
      {section === 'show' && <Show />}
      {section === 'dragging' && <Dragging />}
      {section === 'scrolling' && <Scrolling />}
      {section === 'tapping' && <Tapping />}
      {section === 'holding' && <Holding />}
      {section === 'growing' && <Growing />}
      {section === 'manifesto' && <Manifesto />}
      {section === 'test' && <Test />}
    </>
  );
}
