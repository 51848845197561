import { useEffect, useRef } from 'react';
import { useCameras } from '../../../stores/useCameras';
import gsap from 'gsap';
import { useDrag } from '../../../stores/useDrag';
import { useFrame } from '@react-three/fiber';

export function CameraController() {
  /*
   * properties
   */

  const timeline = useRef();
  const pct = useRef(0);
  const endPct = useRef(0);
  const camera = useCameras((state) => state.camera);
  const slowDragPct = useDrag((state) => state.slowDragPct);
  // const dragPct = useDrag((state) => state.dragPct);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('Dragging CameraController :: onMount');

    const tl = gsap.timeline({ paused: true });

    if (camera) {
      gsap.set(camera.position, { duration: 0, x: 0, y: 0, z: 10 }, 0);
      gsap.set(camera.rotation, { duration: 0, x: 0, y: 0, z: 0 }, 0);

      tl.to(camera.position, { duration: 1, y: -7.5, ease: 'sine.out' }, 0);
      tl.to(camera.position, { duration: 1, z: 0, ease: 'power1.out' }, 0);
      tl.to(camera.rotation, { duration: 1, x: 1.571, y: 0, z: 3.142, ease: 'sine.inOut' }, 0);

      timeline.current = tl;
    }

    return () => {
      // console.log('Dragging CameraController :: onDestroy');
      tl.kill();
    };
  }, [camera]);

  useEffect(() => {
    if (camera) {
      // timeline.current.progress(slowDragPct);
      endPct.current = slowDragPct;
    }
  }, [slowDragPct]);

  useFrame(() => {
    // console.log(pct.current)
    pct.current += (endPct.current - pct.current) * 0.1;
    timeline.current.progress(pct.current);
  });
}
