import { useDrag } from '../../../stores/useDrag';
import { OutroText } from '../../components/OutroText';

export function Text({ finished }) {
  // console.log('Dragging :: render');

  /*
   * visuals
   */

  return (
    <>
      <OutroText
        show={finished}
        section="scrolling"
        // text="take people to LINE another place LINE in raucous experiences which aim LINE to stimulate, inspire & thrill."
      />
    </>
  );
}
