import { useEffect, useMemo, useRef, useState } from 'react';
import { useSize } from '../../stores/useSize';
import { useSection } from '../../stores/useSection';
import { usePieces } from '../../stores/usePieces';

import style from './OutroText.module.css';
import gsap from 'gsap';

export function OutroText({ section, show, delay = 5, isManifesto = false, num = 1 }) {
  /*
   * properties
   */
  // const body = useRef();
  const title = useRef();
  const [piecesAmount, setPiecesAmount] = useState();

  const isMobile = useSize((state) => state.isMobile);
  const transition = useSection((state) => state.transition);
  const foundPiece = usePieces((state) => state.foundPiece);
  // const foundAllPieces = usePieces((state) => state.foundAllPieces);

  const nums = ['I', 'II', 'III', 'IV', 'V'];

  // const text = {
  //   intro: `${piecesAmount} extracts SPACE BREAK out of SPACE 5 BREAK SPACE discovered`,
  //   introFinal: 'you have SPACE BREAK SPACE discovered BREAK the off SPACE brand BREAK SPACE manifesto',
  //   dragging:
  //     'twist and bend LINE each & every pixel LINE into perfectly formed visual festivals eyeballs dream LINE to soak in.',
  //   growing:
  //     'transform projects LINE into living LINE breathing entities which thrive LINE and spread across the globe.',
  //   holding:
  //     'blow up LINE the status quo of behavioural norms LINE to devise eye-opening interactions fingers LINE forget time in.',
  //   scrolling:
  //     'take people to LINE another place LINE in raucous experiences which aim LINE to stimulate, inspire & thrill.',
  //   tapping:
  //     'blossom creativity LINE and nurture environments where one can LINE find themselves through our LINE ventures.',
  // };

  /*
   * hooks
   */

  useEffect(() => {
    // window.addEventListener('resize', resizeHandler);
    // return () => {
    //   window.removeEventListener('resize', resizeHandler);
    // };
  }, []);

  useEffect(() => {
    // show
    if (show) {
      // console.log('OutroText :: show');

      // resizeHandler();

      // set and get pieces data
      foundPiece(useSection.getState().section);
      const pieces = usePieces.getState().pieces;

      let amount = 0;
      for (const piece of pieces) {
        if (piece.found) {
          amount++;
        }
      }
      setPiecesAmount(amount);

      // show animations
      // const delay = 5;

      // for (const child of body.current.children) {
      //   gsap.killTweensOf(child);
      //   gsap.set(child, { y: -20 });
      //   gsap.to(child, { delay: delay + Math.random() * 1, duration: 1, y: 0, opacity: 1, ease: 'power1.out' });
      // }

      gsap.killTweensOf(title.current);
      gsap.to(title.current, { delay: delay + 1, duration: 1, opacity: 1, ease: 'power1.out' });
    } else {
      // console.log('hide');
      hide();
    }
  }, [show]);

  useEffect(() => {
    // hide
    if (transition === 'hide') {
      hide();
    }
  }, [transition]);

  const hide = () => {
    // for (const child of body.current.children) {
    //   gsap.killTweensOf(child);
    //   gsap.to(child, { duration: 0.4, y: 0, opacity: 0, ease: 'power1.in' });
    // }

    gsap.killTweensOf(title.current);
    gsap.to(title.current, { duration: 0.4, opacity: 0, ease: 'power1.in' });
  };

  //resize
  // const resizeHandler = () => {
  //   // const radius = isManifesto ? window.innerWidth * 0.2 : Math.max(20, window.innerWidth * 0.02);
  //   const radius = Math.max(20, window.innerWidth * 0.02);

  //   let y = 0;
  //   let line = 0;
  //   for (const child of body.current.children) {
  //     if (y === 0 || y !== child.getBoundingClientRect().y) {
  //       y = child.getBoundingClientRect().y;
  //       line++;
  //     }
  //     gsap.set(child, { x: Math.sin(line * 21.5) * radius });
  //   }
  // };

  /*
   * visuals
   */

  return (
    <>
      <div ref={title} className={style.titleContainer}>
        {isMobile && (
          <h1 className={style.titleMobile}>
            {isManifesto && show && num > 0 && (
              <>
                <span>{nums[num - 1]}</span> EXTRACT PART {num}
              </>
            )}
            {!isManifesto && show && (
              <>
                <span>{nums[piecesAmount - 1]}</span> EXTRACT PART {piecesAmount} DISCOVERED
              </>
            )}
          </h1>
        )}
        {!isMobile && (
          <h1 className={style.titleDesktop}>
            {isManifesto && show && num > 0 && (
              <>
                <div>
                  <img alt="line" className={style.titleLineLeft} src="./images/title-line.svg" />
                  {nums[num - 1]}
                  <img alt="line" className={style.titleLineRight} src="./images/title-line.svg" />
                </div>
                EXTRACT {num}
              </>
            )}

            {!isManifesto && show && (
              <>
                <div>
                  <img alt="line" className={style.titleLineLeft} src="./images/title-line.svg" />
                  {nums[piecesAmount - 1]}
                  <img alt="line" className={style.titleLineRight} src="./images/title-line.svg" />
                </div>
                EXTRACT {piecesAmount} 0F 5 FOUND
              </>
            )}
          </h1>
        )}
      </div>
      {/* <div className={style.container}>
        <p ref={body} className={style.body}>
          {(foundAllPieces() && section === 'intro' ? text['introFinal'] : text[section])
            .split(' ')
            .map((value, index) => {
              if (value === 'LINE') {
                return (
                  <span key={index} className={style.line}>
                    &nbsp;
                  </span>
                );
              } else if (value === 'SPACE') {
                return (
                  <span key={index} className={style.space}>
                    &nbsp;
                  </span>
                );
              } else if (value === 'BREAK') {
                return <br key={index} />;
              } else {
                return <span key={index}>{value}</span>;
              }
            })}
        </p>
      </div> */}
    </>
  );
}
