import { useEffect, useReducer, useRef } from 'react';
import { useGrow } from '../../../stores/useGrow';
import { usePinch } from '@use-gesture/react';
import { useFrame } from '@react-three/fiber';
import { useSection } from '../../../stores/useSection';
import { usePost } from '../../../stores/usePost';
import { useSounds } from '../../../stores/useSounds';

let activeNum = 1;
let keyLoop = 0;

// let pinchStart;
let pinchPct = 0;

let shrinkSpeed = 0;
let isMobile = false;

const konamiCode = [
  'arrowup',
  'arrowup',
  'arrowdown',
  'arrowdown',
  'arrowleft',
  'arrowright',
  'arrowleft',
  'arrowright',
  'b',
  'a',
];
let konamiCodePos = 0;

export function GrowController() {
  /*
   * properties
   */
  const addKey = useGrow((state) => state.addKey);
  const addKonami = useGrow((state) => state.addKonami);
  const reset = useGrow((state) => state.reset);
  const start = useGrow((state) => state.start);
  const finish = useGrow((state) => state.finish);
  const setGrowPct = useGrow((state) => state.setGrowPct);
  const concealSection = useSection((state) => state.concealSection);
  const setPost = usePost((state) => state.setPost);
  const finishPost = usePost((state) => state.setPost);

  const getSound = useSounds((state) => state.getSound);
  const sound = getSound('GrowingLoop');
  const oldPct = useRef(0);
  const volume = useRef(0);

  usePinch(
    (state) => {
      if (!useGrow.getState().finished) {
        if (state.first) {
          // console.log('RESTART');
          // pinchPctBase.current += pinchPct.current;
          // pinchStart = state.movement[0];
          // console.log(pinchStart);
        } else {
          // console.log(pinchStart, state.movement[0]);
          // const distance = pinchStart - state.movement[0];
          // console.log(state.distance, state.direction)

          // console.log(state.distance)

          pinchPct += state.distance[0] * state.direction[0] * 0.02;
          // console.log(distance, pinchPct)
          // console.log(state.movement[0])
          pinchPct = Math.max(0, Math.min(pinchPct, 1));
          // console.log(pinchPct);

          setGrowPct(pinchPct);
          setPost(pinchPct);

          shrinkSpeed = 0.1;
          isMobile = true;

          if (pinchPct === 1) {
            finish();
            finishPost();
            concealSection();
          }

          // console.log(distance);
        }
      }
    },
    {
      target: document.querySelector('#growOverlay'),
    }
  );

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('ScrollController :: onMount');
    // console.log(document.querySelector('#growOverlay'));

    activeNum = 1;
    keyLoop = 0;
    pinchPct = 0;
    // pinchPct.current = 0;
    // pinchPctBase.current = 0;

    sound.play();
    sound.volume(0);

    const timeoutId = setTimeout(() => {
      // console.log('START');
      start();
      window.addEventListener('keydown', keyHandler);
    }, 3000);

    return () => {
      sound.stop();

      reset();
      clearTimeout(timeoutId);
      window.removeEventListener('keydown', keyHandler);
    };
  }, []);

  const keyHandler = (e) => {
    // console.log(e.key);
    if (!useGrow.getState().finished) {
      checkNumKey(e);
      checkKonamiCode(e);
    }
  };

  const checkKonamiCode = (e) => {
    const key = e.key.toLowerCase();

    if (key == konamiCode[konamiCodePos]) {
      console.log(key);
      konamiCodePos++;
      addKonami();
      setPost(useGrow.getState().growPct);

      shrinkSpeed = 0.1;
      isMobile = false;

      if (konamiCodePos === konamiCode.length) {
        finish();
        concealSection();
        finishPost();
      }
    }
  };

  const checkNumKey = (e) => {
    const num = parseInt(e.key);
    if (num == activeNum) {
      activeNum++;
      addKey();
      setPost(useGrow.getState().growPct);

      shrinkSpeed = 0.1;
      isMobile = false;

      if (num === 9) {
        if (keyLoop < 2) {
          keyLoop++;
          activeNum = 1;
        } else {
          finish();
          concealSection();
          finishPost();
        }
      }
    }
  };

  useFrame((state, delta) => {
    // console.log(useGrow.getState().growPct);
    const growPct = useGrow.getState().growPct;

    if (growPct > oldPct.current) {
      volume.current = 1;
    } else {
      volume.current *= 0.97;
    }
    oldPct.current = growPct;

    // console.log(volume.current);
    sound.volume(volume.current);

    if (useGrow.getState().started && !useGrow.getState().finished) {
      // if (pinchPct.current > 0 || pinchPctBase.current > 0) {
      // if (pinchPct > 0) {
      if (isMobile) {
        shrinkSpeed *= 1.1;
      } else {
        shrinkSpeed *= 1.02;
      }
      // shrinkSpeed *= 1.2;
      shrinkSpeed = Math.min(shrinkSpeed, 100);

      // console.log(shrinkSpeed);

      if (shrinkSpeed > 1) {
        const pct = Math.max(0, useGrow.getState().growPct - delta * 0.002 * shrinkSpeed);

        // console.log(pct)

        setGrowPct(pct);
        setPost(pct);
      }
      // }
    }
  });
}
