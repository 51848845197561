import { useEffect, useMemo, useRef, useState } from 'react';

export function Stem() {
  /*
   * properties
   */

  // const materialProps = useControls('stem material', {
  //   roughness: { value: 0.6, min: 0, max: 1, step: 0.01 },
  //   metalness: { value: 0.9, min: 0, max: 1, step: 0.01 },
  //   color: '#b7b7b7',
  // });

  const materialProps = {
    roughness: 0.6,
    metalness: 0.9,
    color: '#b7b7b7',
  };

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <mesh position={[0, -2.5, 0]}>
        <cylinderGeometry args={[0.02, 0.02, 5, 8, 1]} />
        <meshStandardMaterial
          color={materialProps.color}
          metalness={materialProps.metalness}
          roughness={materialProps.roughness}
        />
      </mesh>
    </>
  );
}
