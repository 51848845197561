import { useEffect, useMemo, useRef } from 'react';
import gsap from 'gsap';

import { Lighting } from './Lighting';
import { useSection } from '../../../stores/useSection';
import { CameraController } from './CameraController';
import { Branches } from './Branches';
import { GrowController } from './GrowController';
import { Content } from './Content';
import { SectionContainer } from '../../components/SectionContainer';

export function Growing() {
  /*
   * visuals
   */

  return (
    <>
      <Lighting />
      <CameraController />
      <GrowController />

      <SectionContainer>
        <Content />
        <Branches />
      </SectionContainer>
    </>
  );
}
