import { Bloom, EffectComposer, Vignette } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';
import { useEffect, useRef } from 'react';
import Pinch from './postprocessing/Pinch.jsx';
import Noise from './postprocessing/Noise.jsx';
import { useFrame } from '@react-three/fiber';
import gsap from 'gsap';
import { useSection } from '../../stores/useSection.js';
import { usePost } from '../../stores/usePost.js';

export function PostProcessing() {
  /*
   * properties
   */

  const previousSection = useRef('');

  const transition = useSection((state) => state.transition);
  const loadSection = useSection((state) => state.loadSection);
  const endTransition = useSection((state) => state.endTransition);

  const pinchRef = useRef();
  const noiseRef = useRef();

  let noisePct = 0;

  // const config = useControls('post processing', {
  //   vignette: folder({
  //     vignetteEnabled: { value: true, label: 'enabled' },
  //     offset: { value: 0, min: 0, max: 2, label: 'offset' },
  //     darkness: { value: 1.1, min: 0, max: 2, label: 'darkness' },
  //   }),

  //   bloom: folder({
  //     bloomEnabled: { value: true, label: 'enabled' },
  //     intensity: { value: 1.8, min: 0, max: 40, step: 0.1, label: 'intensity' },
  //     luminanceThreshold: { value: 0.8, min: 0, max: 1, label: 'threshold' },
  //     luminanceSmoothing: { value: 0, min: 0, max: 0.2, label: 'smoothing' },
  //     mipmapBlur: { value: true, label: 'mipmapBlur' },
  //   }),

  //   noise: folder({
  //     noiseEnabled: { value: true, label: 'enabled' },
  //     premultiply: { value: false },
  //     noiseOpacity: { value: 0.2, min: 0, max: 10, step: 0.001, label: 'opacity' },
  //   }),
  // });

  const config = {
    vignetteEnabled: true,
    offset: 0,
    darkness: 1.1,

    bloomEnabled: true,
    intensity: 1.8,
    luminanceThreshold: 0.8,
    luminanceSmoothing: .4,
    mipmapBlur: true,

    noiseEnabled: true,
    premultiply: false,
    // noiseOpacity: 0.2,
    noiseOpacity: 0.07,
  };

  /*
   * hooks
   */

  useFrame((state, delta) => {
    pinchRef.current.uniforms.get('uTime').value += delta;
  });

  useEffect(() => {
    const uStrength = pinchRef.current.uniforms.get('uStrength');

    const section = useSection.getState().section;

    if (transition === 'hide') {
      // Pinch: hide current section
      // console.log('Postprocessing :: hide');

      gsap.killTweensOf(uStrength);
      gsap.killTweensOf(uStrength.value);

      gsap.set(uStrength, { value: 0 });

      if (section === 'intro') {
        gsap.to(uStrength, { value: 0, duration: 0.55, ease: 'sine.in', onComplete: loadSection });
      } else {
        gsap.to(uStrength, { value: 0.7, duration: 0.55, ease: 'sine.in', onComplete: loadSection });
      }
    } else if (transition === 'show') {
      // Pinch: show new section
      // console.log('Postprocessing :: show');

      gsap.killTweensOf(uStrength);
      gsap.killTweensOf(uStrength.value);

      if (previousSection.current !== 'intro') {
        gsap.set(uStrength, { value: 0.7 });
      }

      gsap.to(uStrength, { value: 0, duration: 2, ease: 'sine.out', onComplete: endTransition });

      previousSection.current = section;
    }
  }, [transition]);

  useFrame((state) => {
    const uStrength = noiseRef.current.uniforms.get('uStrength');

    const pct = Math.min(1, usePost.getState().pct);
    let endPct = 0;
    if (!isNaN(pct) && pct > 0 && pct < 1) {
      endPct = pct;
    }

    if (noisePct < endPct) {
      noisePct = endPct;
    } else {
      noisePct *= 0.99;
    }

    // console.log(noisePct);

    uStrength.value = 0.5 + noisePct * 0.5;
    // console.log(uStrength.value);
  });

  /*
   * visuals
   */

  return (
    <>
      <EffectComposer stencilBuffer={true}>
        <Pinch ref={pinchRef} />

        {config.vignetteEnabled && (
          <Vignette offset={config.offset} darkness={config.darkness} blendFunction={BlendFunction.NORMAL} />
        )}

        {/* {config.noiseEnabled && (
          <Noise
            ref={noiseRef}
            premultiply={config.premultiply} // enables or disables noise premultiplication
            opacity={config.noiseOpacity}
            // opacity = {pct}
            blendFunction={BlendFunction.COLOR_DODGE} // blend mode
          />
        )} */}

        <Noise ref={noiseRef} strength={0.5} blendFunction={BlendFunction.COLOR_DODGE} />

        {config.bloomEnabled && (
          <Bloom
            intensity={config.intensity}
            luminanceThreshold={config.luminanceThreshold} // luminance threshold. Raise this value to mask out darker elements in the scene.
            luminanceSmoothing={config.luminanceSmoothing} // smoothness of the luminance threshold. Range is [0, 1]
            mipmapBlur={config.mipmapBlur} // Enables or disables mipmap blur.
          />
        )}
      </EffectComposer>
    </>
  );
}
