import { useEffect, useRef } from 'react';
import { useCameras } from '../../../stores/useCameras';
import gsap from 'gsap';
import { useDrag } from '../../../stores/useDrag';

export function CameraController() {
  /*
   * properties
   */
  const camera = useCameras((state) => state.camera);
  const slowDragPct = useDrag((state) => state.slowDragPct);
  const timeline = useRef();

  /*
   * hooks
   */
  useEffect(() => {
    if (camera) {
      gsap.set(camera.position, { duration: 0, x: 0, y: 0, z: 10 }, 0);
      gsap.set(camera.rotation, { duration: 0, x: 0, y: 0, z: 0 }, 0);

      const tl = gsap.timeline({ paused: true });

      tl.to(camera.position, { duration: 1, y: -7.5, ease: 'sine.out' }, 0);
      tl.to(camera.position, { duration: 1, z: 0, ease: 'power1.out' }, 0);
      tl.to(camera.rotation, { duration: 1, x: 1.571, y: 0, z: 3, ease: 'sine.inOut' }, 0);
      
      timeline.current = tl;
    }
  }, [camera]);

  useEffect(() => {
    if (camera) {
      timeline.current.progress(slowDragPct);
    }
  }, [slowDragPct]);
}
